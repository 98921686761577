import React, { useContext } from 'react';
import DOMPurify from 'dompurify';

import AppContext from '../../context/app/AppContext';


/*
 * This Error page displays 404 errors.
 */
const ErrorPage = () => {
    
    
    // Reference Context api's.
    const appContext                            = useContext(AppContext);
    
    // Destructure Context api's.
    const { staticsData }                       = appContext;
    
    
    /*
     * Component view.
     */
    
    return (
        <div className="flex-wrapper-col flex-justify-center flex-align-center height-100pc">
            <div>
                <div className="error-pg-code">404</div>
                <div className="error-pg-txt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(staticsData.copy.error404) }} />
            </div>
        </div>
    );
    
    
};

export default ErrorPage;

/*** Scripts end... */
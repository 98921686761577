import React, { useContext, useRef, useEffect } from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import useWindowDimensions from '../hooks/getWindowDimensions';

import ChevronTailB from '../../assets/img/chevron-tail-b.png';
import ChevronMidB from '../../assets/img/chevron-mid-b.png';
import ChevronPointB from '../../assets/img/chevron-point-b.png';


/*
 * This ExamHeader component displays exam section titles.
 */
const ExamHeader = () => {
    
    
    // Reference Context api's.
    const authenticationContext         = useContext(AuthenticationContext);
    const appContext                    = useContext(AppContext);
    
    // Destructure Context api's.
    const { userAuthenticated }         = authenticationContext;
    const { staticsData,
            toggleCheckboxModal,
            setExamHeaderHeight }       = appContext;
            
    // Get window dimensions.
    const { winHeight }                 = useWindowDimensions();
            
    // Define some DOM reference hooks.
    const contentRef                    = useRef(null);
            
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const getTitle = ID => {
        
        const section = staticsData.examSectionType.find(item => item.ID === ID);
        
        if ( typeof section !== 'undefined' ) return section.label;
        else return '';
        
    };
    
    const getDescription = (ID, descID) => {
        
        const section = staticsData.examSectionType.find(item => item.ID === ID);
        
        if ( typeof section !== 'undefined' ) {
            
            if ( descID === 1 ) return section.description1;
            else return section.description2;
            
        }
        else return '';
        
    };
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickSectionHeader = () => {
        
        if ( !userAuthenticated ) toggleCheckboxModal(true);
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Store the height of the exam header bar, used to set the height of the reset button.
        if ( contentRef !== null ) setExamHeaderHeight(contentRef.current.clientHeight);
        
    // eslint-disable-next-line 
    }, [contentRef, winHeight]);


    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="exams-header">
            <div ref={contentRef} 
                 className="content">

                {/* Tail chevron - this is covered by the reset btn, but will get printed by the snapshot */}
                <div className="chevron-img"><img src={ChevronTailB} alt="" /></div>
                
                {/* Section Title - Applied Knowledge */}
                <div className={ 'exam-section-title title-1' + ( userAuthenticated ? ' disabled' : '' ) }
                     onClick={onClickSectionHeader}>
                    <div className="content">
                        <div>
                            <h3>{getTitle(1)}</h3>
                            <p className="dim-txt">{getDescription(1, 1)}</p>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronMidB} alt="" /></div>
                
                {/* Section Title - Applied Skills */}
                <div className={ 'exam-section-title title-2' + ( userAuthenticated ? ' disabled' : '' ) }
                     onClick={onClickSectionHeader}> 
                    <div className="content">
                        <div>
                            <h3>{getTitle(2)}</h3>
                            <p className="dim-txt">{getDescription(2, 1)}</p>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronMidB} alt="" /></div>
                
                {/* Section Title - EPSM (Ethics Module) */}
                <div className={ 'exam-section-title title-3' + ( userAuthenticated ? ' disabled' : '' ) }
                     onClick={onClickSectionHeader}>
                    <div className="content">
                        <div>
                            <p className="dim-txt">{getTitle(3)}</p>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronMidB} alt="" /></div>
                
                {/* Section Title - Strategic Professional */}
                <div className={ 'exam-section-title title-4' + ( userAuthenticated ? ' disabled' : '' ) }
                     onClick={onClickSectionHeader}>
                    <div className="content">
                        <div>
                            <h3>{getTitle(4)}</h3>
                            <p className="dim-txt">
                                {getDescription(4, 1)}<span className="gap-exam-txt"></span>{getDescription(4, 2)}
                            </p>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronPointB} alt="" /></div>
                
            </div>
        </div>
    );
    
    
};

export default ExamHeader;


/*** Scripts end... */

import React from 'react';

import AppState from './app/AppState';
import ModalState from './modal/ModalState';
import AuthenticationState from './authentication/AuthenticationState';


const AllContexts = ({ children }) => (
    <AuthenticationState>
        <ModalState>
            <AppState>
                {children}
            </AppState>
        </ModalState>
    </AuthenticationState>
);

export default AllContexts;


/*** Scripts end... */

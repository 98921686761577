import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ProtectedRoute from '../hooks/ProtectedRoute';
import Home from '../pages/Home';
import ErrorPage from '../pages/ErrorPage';
import StudyPlanner from '../pages/StudyPlanner';
import Modal from '../modals/Modal';
import AppSpinner from './AppSpinner';
import Intro from './Intro';


/*
 * This AppWrapper component wraps the routing component & 
 * allows the app to load data & the authentication module using the Context API.
 */
const AppWrapper = () => {
    
    
    // Reference Context API's. 
    const authenticationContext                 = useContext(AuthenticationContext);
    const appContext                            = useContext(AppContext);
    
    // Destructure Context API's. 
    const { publicClientApplication,
            examsData }                         = authenticationContext;
    const { setIsRedirect }                     = appContext;
    
    // Component state.
    const [introEnd, setIntroEnd]               = useState(false);
    
    // Check session storage to see if we have been redirected from login/logout.
    const redirectVal   = sessionStorage.getItem("is_redirect") ? true : false;
    
    
    
     /*
      * Component hooks.
      */
    
    useEffect(() => {
        
        setIsRedirect(redirectVal);
        
        // If redirect is true then clear 'is_redirect' key from session storage.
        // As we won't be showing the animation update 'setIntroEnd' to true.
        if ( redirectVal ) {
            
            sessionStorage.removeItem("is_redirect");
            setIntroEnd(true);
            
        }
        
        // Else delay page render until intro animation has played.
        else {
            
            setTimeout(() => {
                
                setIntroEnd(true)
                
            }, 3500);
            
        }

    // eslint-disable-next-line
    }, []);

     
    /*
     * Component view.
     */
    
    return (
        <>
            { publicClientApplication !== null && examsData !== null ?  
                <>
                    { redirectVal ?
                        <>
                            <Router>
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/home" component={Home} />
                                    <Route path="/error" component={ErrorPage} />
                                    <ProtectedRoute path="/study-planner" component={StudyPlanner} />
                                    <Redirect to="/" />
                                </Switch>
                            </Router>
                            <Modal />
                        </>
                    :
                        <>
                        { introEnd ?
                            <>
                                <Router>
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route path="/home" component={Home} />
                                        <Route path="/error" component={ErrorPage} />
                                        <ProtectedRoute path="/study-planner" component={StudyPlanner} />
                                        <Redirect to="/" />
                                    </Switch>
                                </Router>
                                <Modal />
                            </>
                        :
                            <Intro />
                        }
                        </>
                    }
                </>
            :
                <AppSpinner />
            }
        </>
    );
    
    
};

export default AppWrapper;


/*** Scripts end... */

import React from 'react';

import { ImgLogo, ImgQuad, ImgQuadx3, IconPointer, IconTick } from '../hooks/ImageAssets';


/*
 * This PreRender component temporarily draws some invisible text & images to force
 * them to load. So that when the real content appears the fonts don't jump.
 */
const PreRender = () => {
    
    
    /*
     * Component view.
     */
    
    return (
        <div className="pre-render-fonts">
            <div className="roboto-300">Font</div>
            <div className="roboto-400">Font</div>
            <div className="roboto-500">Font</div>
            <div className="roboto-700">Font</div>
            <div className="roboto-slab-300">Font</div>
            <div className="roboto-slab-400">Font</div>
            {ImgLogo()}
            {ImgQuad()}
            {ImgQuadx3()}
            {IconTick()}
            {IconPointer()}
        </div>
    )
    
    
}

export default PreRender


/*** Scripts end... */

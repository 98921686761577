import React, { useContext, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import TmplInsModal from '../modals/TmplInsModal';
import ModalContentLogin from '../modals/ModalContentLogin';
import { ImgLogoTxt,
         IconStudyPlanning,
         IconExamPlanning } from '../hooks/ImageAssets';
import useWindowDimensions from '../hooks/getWindowDimensions';

import ChevronMidA from '../../assets/img/chevron-mid-a.png';


/*
 * This AppHeader component only shows on desktop & contains 
 * the ACCA logo & snapshot button.
 */
const AppHeader = () => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Reference hooks.
    const history                           = useHistory();
    const location                          = useLocation();
    
    // Get window dimensions.
    const { winWidth }                      = useWindowDimensions();
    
    // Destructure Context api's.
    const { userAuthenticated,
            userName,
            signInUser,
            signOutUser,
            clearStudyDaysData }            = authenticationContext;
    const { isIntro,
            screenshotEnable,
            modalFtrTxt }                   = appContext;
    const { showModal,
            hideModal }                     = modalContext;
    
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const onClickShowInfoModal = () => {
        
        console.log('location', location);
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        
        let htmlBody        = null;
        let cusFtrTxt       = '';
        
        if ( location.pathname === '/study-planner' ) {
            
            htmlBody = () => {
                return (
                    <div className="modal-body ">
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-btm-0">Add day-to-day tasks and milestones</div>
                        :
                            <div className="list-item pad-btm-0">Add day-to-day tasks and target milestones</div>
                        }
                        
                        <div className="modal-cookie-txt pad-top-2 dim-txt">(Check out the exam curriculum for suggested topics)</div>
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-top-10 pad-btm-0">Configure Final Steps and Exam Date*</div>
                        :
                            <div className="list-item pad-top-10 pad-btm-0">Configure your Final Steps and Exam Date*</div>
                        }
                        
                        <div className="modal-cookie-txt pad-top-2 dim-txt">*Exact dates for scheduled exam sessions are usually released 6 months prior to the session window</div>
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-top-10">Check off tasks as you go and reflect!</div>
                        :
                            <div className="list-item pad-top-10">Check off tasks as you go<br />and reflect on your progress</div>
                        }
                        
                        <div className="cta-btns flex-wrapper-center">
                            <button className="btn-pill btn-red" onClick={hideModal}>Got it</button>
                        </div>
                        
                    </div>
                );
            };
            cusFtrTxt = 'In future releases we\'ll add the facility to auto-fill <br />tasks and get reminders on your device';
            
        }
        else {
            
            htmlBody = () => {
                return (
                    <div className="modal-body">
                        
                        <h2>Tap an exam and set it as exempt,<br /> passed, taking or retaking</h2>
                        <h2>Plan out the exams you want to take<br /> in the next 12 months</h2>
                        <h2>Then review the calendar for booking links<br /> and preferred exam sessions</h2>
                        
                        <div className="cta-btns flex-wrapper-center">
                            <button className="btn-pill btn-red" onClick={hideModal}>Got it</button>
                        </div>
    
                    </div>
                );
            };
            cusFtrTxt = modalFtrTxt;
            
        }

        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} txtFooter={cusFtrTxt}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickGoToHome = () => {
        
        // Clear 'examStudyDaysData' so we fetch new data when returning.
        clearStudyDaysData();
        
        // Navigate to the home page.
        history.push('/');
        
    };
    
    // When a user click the Study Planning button open a modal notifying them they
    // need to login or a modal prompting them to click one of the exam 'Plan' buttons.
    const onClickStudyPlanning = () => {
        
        const txtTitleA     = 'Your ACCA Study planner'; 
        let htmlBody        = null;
        
        // Notify user they need to click a 'Plan' button.
        if ( userAuthenticated ) {
            
            // Instructional - define modal content.
            htmlBody        = () => {
                return (
                    <div className="modal-body ">
                        
                        <h2 className="list-header">To start planning for an exam:</h2>
                        
                        <div className="list-item">First, select your exam session<br />and add your study period.</div>
                        <div className="list-item">Then tap that "Plan!" tab, or the exam's study period to access your day-to-day Study Planner.</div>
                        <div className="list-item">And plan for exam success!</div>
                        
                        <div className="cta-btns flex-wrapper-center">
                            <button className="btn-pill btn-red" onClick={hideModal}>Got it</button>
                        </div>

                    </div>
                );
            };
 
        }
        
        // Else prompt user to login then click a 'Plan' button.
        else {
            
            // Instructional - define modal content.
            htmlBody        = () => {
                return (
                    <div className="modal-body ">
                        <ModalContentLogin />
                    </div>
                );
            };
            
        }
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
    
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, [screenshotEnable]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="app-header">
            
            <div className="mn-toolbar"
                 data-html2canvas-ignore>
                <div>
                    { userAuthenticated ?
                        <>
                            You are logged in { userName !== '' ? userName : null }&nbsp;&nbsp;      
                            <button className="txt-lnk txt-lnk-highlight"
                                    onClick={signOutUser}>Log out</button>
                        </>
                    :
                        <>
                            <button className="txt-lnk txt-lnk-highlight"
                                    onClick={signInUser}>Log in</button> 
                            &nbsp;to access additional study planning features
                        </>
                    }      
                </div>
            </div>
            
            <div className="mn-navbar flex-wrapper-col">
                <div className="col-lft bkgrnd-col"></div>
                <div className="col-mid flex-wrapper-col">
                    
                    {/* Logo */}
                    <div className="logo flex-wrapper-row flex-justify-center">
                        <div className="logo-wrapper"
                            onClick={onClickShowInfoModal}>
                            {ImgLogoTxt()}
                        </div>
                    </div>
                    
                    {/* chevron */}
                    <div className="chevron-img"><img src={ChevronMidA} alt="" /></div>
                    
                    {/* Main navigation buttons */}
                    <div className="cta flex-wrapper-row flex-justify-center flex-align-end "> 
                        <div className={ 'dsk-mn-nav' + ( !isIntro || userAuthenticated ? ' show' : '' ) }
                            data-html2canvas-ignore>
                            <div className="mn-nav-btns flex-wrapper-col">
                                <button className={ location.pathname === '/home' || location.pathname === '/' ? 'selected' : null }
                                        onClick={onClickGoToHome}>
                                    <IconExamPlanning />
                                    <span>Exam planning</span>
                                </button>
                                <button className={ location.pathname === '/study-planner' ? 'selected' : null }
                                        onClick={onClickStudyPlanning}>
                                    <IconStudyPlanning />
                                    <span>Study planning</span>
                                </button>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div className="col-rgt bkgrnd-col"></div>
            </div>
            
        </div>
    );
    
    
};

export default AppHeader;


/*** Scripts end... */

import React, { useContext } from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ExamsHeader from './ExamsHeader';
import ExamStick from './ExamStick';
import ExamsFooter from './ExamsFooter';


/*
 * This LayerExams component displays the exams titles and exam sticks.
 */
const LayerExams = () => {
    
    
    // Reference Context api's.
    const authenticationContext         = useContext(AuthenticationContext);
    
    // Destructure Context api's.
    const { examsData }                 = authenticationContext;
    
    
    /*
     * Component methods.
     */
    
    const drawExamSticks = ( sectionType, spFilter ) => {
        
        // Bail if we have no exam data.
        if ( examsData === null ) return null;
        
        // Filter out Section exams & then sort them.
        let examsArr = examsData
                            .filter(item => item.sectionType === sectionType)
                            .sort((a, b) => a.sectionOrder - b.sectionOrder);
                            
        // If 'sectionType' is 4 'Strategic Professional', then filter
        // exams again into 'SBL', 'SBR' & the others.
        const spFilterArr = spFilter === 1 ? ['SBL', 'SBR'] : ['AFM', 'APM', 'ATX', 'AAA'];
        if ( sectionType === 4 ) examsArr = examsArr.filter(item => spFilterArr.includes(item.shortName));
        
        return (
            <>
                {examsArr.map((item, idx) => (
                    <ExamStick key={idx}
                               examUNID={item.UNID} 
                               sectionType={sectionType} /> 
                ))}
            </>
        );
        
    };

    
    
     
    /*
     * Component view.
     */
    
    return (
        <div className="exams">
            <div className="content flex-wrapper-row">
                       
                {/* Exams layer header: START */}
                <ExamsHeader />
                {/* Exams layer header: STOP */}
                
                {/* Exams layer body: START */}
                <div className="exams-body flex-wrapper-col flex-space-between">
                    
                    {/* Applied Knowledge exams: START */}
                    <div className='exams-group flex-wrapper-col flex-space-between'>
                        {drawExamSticks(1, null)}
                    </div> 
                    
                    {/* Applied Skills exams: START */}
                    <div className='exams-group flex-wrapper-col flex-space-between'>
                        {drawExamSticks(2, null)}
                    </div>
                    
                    {/* Ethics Module exams: START */}
                    <div className='exams-group flex-wrapper-col flex-space-between'>
                        {drawExamSticks(3, null)}
                    </div>
                    
                    {/* Strategic Professional 1 exams: START */}
                    <div className='exams-group flex-wrapper-col flex-space-between'>
                        {drawExamSticks(4, 1)}
                    </div>
                    
                    {/* Strategic Professional 2 exams: START */}
                    <div className='exams-group flex-wrapper-col flex-space-between'>
                        {drawExamSticks(4, 2)}
                    </div>
                    
                </div>
                {/* Exams layer body: STOP */}
                
                {/* Exams layer footer (only on desktop): START */}
                <ExamsFooter />
                {/* Exams layer footer (only on desktop): STOP */}
            
            </div>
        </div>
    );
    
    
};

export default LayerExams;


/*** Scripts end... */

export const SET_AUTH_APPLICATION               = 'SET_AUTH_APPLICATION';
export const SET_BEARER_TOKEN                   = 'SET_BEARER_TOKEN';
export const SET_USER_AUTHENTICATED             = 'SET_USER_AUTHENTICATED';
export const SET_RESPONSE_STATUS                = 'SET_RESPONSE_STATUS';
export const SET_FETCH_ERROR                    = 'SET_FETCH_ERROR';
export const SET_CUR_FETCHING                   = 'SET_CUR_FETCHING';
export const SET_PUBLIC_DATA                    = 'SET_PUBLIC_DATA';
export const SET_PRIVATE_DATA                   = 'SET_PRIVATE_DATA';
export const SET_OVERRIDE_DATA                  = 'SET_OVERRIDE_DATA';
export const SET_STUDY_DAYS_DATA                = 'SET_STUDY_DAYS_DATA';
export const CLEAR_STUDY_DAYS_DATA              = 'CLEAR_STUDY_DAYS_DATA';
export const UPDATE_STUDY_TASKS_DATA            = 'UPDATE_STUDY_TASKS_DATA';
export const UPDATE_USER_EXAMS                  = 'UPDATE_USER_EXAMS';
export const UPDATE_COMPLETED_EXAMS             = 'UPDATE_COMPLETED_EXAMS';
export const RESET_EXAM_PLANNER                 = 'RESET_EXAM_PLANNER';


/*** Scripts end... */
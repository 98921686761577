import React, { useContext, useState, useEffect} from 'react';

import AppContext from '../../context/app/AppContext';
import { defineMonths,
         defineYear } from '../hooks/utilityFunctions';


/*
 * This LayerCalendar component displays the 12 months as rows starting from 
 * the users local current month.
 */
const LayerCalendar = () => {
    
    
    // Reference Context api's
    const appContext                    = useContext(AppContext);
    
    // Destructure Context api's.
    const { displayType,
            screenshotStatus }          = appContext;
    
    
    // Component state.
    const [monthsArr, setMonthsArr]     = useState([]);
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Define the current set of 12 months.
        setMonthsArr(defineMonths(true));
        
    // eslint-disable-next-line 
    }, []);


    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="calendar">
            
            { displayType === 'mob' ? 
                <div className="mob-content">
                    <div className="spacer-1"></div>
                    <div className="mob-stick">
                        <div className="spacer-2"></div>
                        
                        { screenshotStatus && (
                            <div className="mob-rows">
                                {monthsArr.map((month, idx) => (
                                    <div key={idx} className="row flex-wrapper-col flex-space-between">
                                        <div>
                                            {month} <span className="mgn-left-5 dim-txt">{defineYear(month)}</span>
                                        </div>
                                        <div>
                                            {month} <span className="mgn-left-5 dim-txt">{defineYear(month)}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) }
                        
                        { !screenshotStatus && (
                            <div className="mob-rows">
                                {monthsArr.map((month, idx) => (
                                    <div key={idx} className="row"><div></div></div>
                                ))}
                            </div>
                        ) }
                        
                        <div className="spacer-3"></div>
                    </div>
                </div>
            : 
                <>
                {monthsArr.map((month, idx) => (
                    <div key={idx} className="row flex-wrapper-col flex-space-between">
                        <div>
                            {month} <span className="mgn-left-5 dim-txt">{defineYear(month)}</span>
                        </div>
                        <div>
                            {month} <span className="mgn-left-5 dim-txt">{defineYear(month)}</span>
                        </div>
                    </div>
                ))}
                </>
            }
            
            
            
        </div>
    );
    
    
};

export default LayerCalendar;


/*** Scripts end... */

import React, { useReducer, useEffect } from 'react';

import AppContext from './AppContext';
import AppReducer from './AppReducer';
import { SET_IS_REDIRECT,
         SET_DISPLAY_TYPE,
         SET_STATICS_DATA,
         SET_IS_INTRO,
         SET_IS_INITIAL_LOAD,
         SET_SCROLL_TO_PLANNER,
         SET_SCREENSHOT_STATUS,
         SET_SCREENSHOT_ENABLE,
         SET_SCREENSHOT_TGT,
         SET_MODAL_FOOTER_TXT,
         SHOW_CHKBOX_MODAL,
         SHOW_PLAN_BTN_MODAL,
         SET_EXAM_HEADER_HEIGHT,
         SET_DOC_CLICKED,
         SET_HAS_TRANSITION } from './AppTypes';
import useWindowDimensions from '../../components/hooks/getWindowDimensions';

import staticsJSON from '../../assets/data/statics.json';


const AppState = props => {
    
    
    // Define initial state.
    const initialState = {
        isRedirect              : false,
        displayType             : null,
        staticsData             : null, 
        isIntro                 : true,
        isInitialLoad           : true,
        scrollToPlanner         : false,
        screenshotStatus        : false,
        screenshotEnable        : false,
        screenshotTgt           : null,
        modalFtrTxt             : '',
        showChkboxModal         : false,
        showPlanBtnModal        : true,
        examHeaderHeight        : 0,
        docClicked              : false,
        docClickedTgt           : null,
        hasTransition           : false
    };
    
    // Initialize Reducer hook.
    const [state, dispatch]     = useReducer(AppReducer, initialState);
    
    // Get window dimensions.
    const { winWidth, 
            winHeight }         = useWindowDimensions();
    
    
    /*
     * Component methods.
     */
    
    // Populate 'staticsData' with JSON data. 
    const populateStaticsData = data => {
        
        dispatch({
            type        : SET_STATICS_DATA,
            payload     : data
        });
        
    };
    
    // Create the default modal footer content.
    const defineModalFooterTxt = () => {
        
        dispatch({
            type        : SET_MODAL_FOOTER_TXT,
            payload     : 'This tool is designed for general planning purposes, for detailed information on progression rules and exam availability please refer to the ACCA website.'
        });
        
    };
    
    // Handle clicks on document (i.e. close a dropdown).
    const docClickHandler = event => {
        
        toggleDocClicks(false);
        
        // First call will close the selector.
        dispatch({
            type        : SET_DOC_CLICKED,
            payload     : { status: true, clickTgt: event.target }
        });
        
        // Second call resets the context state.
        dispatch({
            type        : SET_DOC_CLICKED,
            payload     : { status: false, clickTgt: event.target }
        });
            
    };
    
    
    
    /*
     * API methods.
     */
    
    const setIsRedirect = status => {
        
        dispatch({
            type        : SET_IS_REDIRECT,
            payload     : status
        });
        
    };

    // When intro animation & modals have finished set isIntro to false to show planner content.
    const setIsIntro = status => {
        
        dispatch({
            type        : SET_IS_INTRO,
            payload     : status
        });
        
    };
    
    // On an initial load the home page should fade in and modls should apear, otherwise not.
    const setIsInitialLoad = status => {
        
        dispatch({
            type        : SET_IS_INITIAL_LOAD,
            payload     : status
        });
        
    };
    
    // Scrolls horizontally to exam planner in mobile view..
    const setScrollToPlanner = status => {
        
        dispatch({
            type        : SET_SCROLL_TO_PLANNER,
            payload     : status
        });
        
    };
    
    // If 'screenshotStatus' changes turn on and off elements to grab.
    const setScreenshotStatus = status => {
        
        dispatch({
            type        : SET_SCREENSHOT_STATUS,
            payload     : status
        });
        
    };
    
    // Enable the screenshot button when the user has selected an event.
    const setScreenshotEnable = status => {
        
        dispatch({
            type        : SET_SCREENSHOT_ENABLE,
            payload     : status
        });
        
    };
    
    // Set the target to be captured.
    const setScreenshotTgt = element => {
        
        dispatch({
            type        : SET_SCREENSHOT_TGT,
            payload     : element
        });
        
    };
    
    // Take a screenshot of the planner.
    const onClickTakeScreenshot = () => {
        
        setScreenshotStatus(true);

    };
    
    // Toggle the intro checkbox modal (modal is created on the home page).
    const toggleCheckboxModal = status => {
        
        dispatch({
            type        : SHOW_CHKBOX_MODAL,
            payload     : status
        });
        
    };
    
    // Note the height of the exam header bar which is required as a style by the reset btn.
    const setExamHeaderHeight = val => {
        
        dispatch({
            type        : SET_EXAM_HEADER_HEIGHT,
            payload     : val
        });
        
    };
    
    // Enable/Disable document event listener for clicks.
    const toggleDocClicks = enable => {
        
        if ( enable ) window.addEventListener( 'click', docClickHandler, false );
        else window.removeEventListener( 'click', docClickHandler, false );
        
    };
    
    //
    const disablePlanBtnModal = () => {
        
        dispatch({
            type        : SHOW_PLAN_BTN_MODAL,
            payload     : false
        });
        
    };
    
    // Prevent exam sticks animating when entering Exam Planner by
    // stopping CSS transition.
    const setHasTransition = status => {
        
        dispatch({
            type        : SET_HAS_TRANSITION,
            payload     : status
        });
        
    };

    

    
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Load local statics data.
        populateStaticsData(staticsJSON);

        // Define modal footer text.
        defineModalFooterTxt();

    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        
        // If screen is wider than 667 use PC layout,
        // else use table/mobile layout.
        dispatch({
            type        : SET_DISPLAY_TYPE,
            payload     : winWidth > 667 ? 'dsk' : 'mob'
        });
        
    // eslint-disable-next-line
    }, [winWidth, winHeight]);
    
    
    /*
     * Return context state & methods.
     */
    
    return <AppContext.Provider value={{
        isRedirect                  : state.isRedirect,
        displayType                 : state.displayType,
        staticsData                 : state.staticsData,
        isIntro                     : state.isIntro,
        isInitialLoad               : state.isInitialLoad,
        scrollToPlanner             : state.scrollToPlanner,
        screenshotStatus            : state.screenshotStatus,
        screenshotEnable            : state.screenshotEnable,
        screenshotTgt               : state.screenshotTgt,
        modalFtrTxt                 : state.modalFtrTxt,
        showChkboxModal             : state.showChkboxModal,
        showPlanBtnModal            : state.showPlanBtnModal,
        examHeaderHeight            : state.examHeaderHeight,
        selectedMood                : state.selectedMood,
        docClicked                  : state.docClicked,
        docClickedTgt               : state.docClickedTgt,
        hasTransition               : state.hasTransition,
        setIsRedirect,
        setIsIntro,
        setIsInitialLoad,
        setScrollToPlanner,
        setScreenshotStatus,
        setScreenshotEnable,
        setScreenshotTgt,
        onClickTakeScreenshot,
        toggleCheckboxModal,
        setExamHeaderHeight,
        toggleDocClicks,
        disablePlanBtnModal,
        setHasTransition
    }}>
        {props.children}
    </AppContext.Provider>
    
};

export default AppState;


/*** Scripts end... */

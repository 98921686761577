import React, { useContext, useState, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import { IconTick } from '../hooks/ImageAssets';


/*
 * This ModalContentTagExam component displays the HTML for the Tag Exam modal.
 * The user can set the exam status to one of Exempt, Passed, Taking or Retaking.
 */
const ModalContentTagExam = ({ exmStatusArr, examData, onClickAddExamStatusDone }) => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    const appContext                            = useContext(AppContext);
    
    // Destructure Context api's.
    const { userExamData, 
            curFetching }                       = authenticationContext;
    const { staticsData  }                      = appContext;
    
    // Component state.
    const [curStatusID, setCurStatusID]         = useState(0);
    const [btnDisabled, setBtnDisabled]         = useState(true);
    
    // Local variables.
    const btnStatusArr                          = ['1', '2', '3', '4'];
    
    
    /*
     * Component methods.
     */
    
    // Actions to toggle button & selected Status for current exam state.
    const onClickSelect = (statusID) => {
        
        // Toggle status off.
        if ( statusID === curStatusID ) setCurStatusID(0);
        
        // Toggle status on.
        else setCurStatusID(statusID);

    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Check whether this exam is currently selected by the user.
        const userExam = userExamData.find(item => item.examUNID === examData.UNID);
        
        // Set the initial selected Status if we have one.
        if ( typeof userExam !== 'undefined' ) setCurStatusID(userExam.statusType);
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        setBtnDisabled(curFetching === '' ? false : true);
        
    // eslint-disable-next-line 
    }, [curFetching]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            { examData.shortName === 'EPSM' ? <h6>Set this module:</h6> : <h6>Set this exam:</h6> }
                        
            <div className="modal-btns-grid">
                
                {btnStatusArr.map((item, idx) => {
                    
                    // Query 'statics.examStatusType' array for matching tag.
                    const statusObj     = staticsData.examStatusType.find(tag => tag.ID === parseInt(item));
                    const exists        = exmStatusArr.includes(item) ? true : false;
                 
                    return (
                        <button key={idx} 
                                className={ 'btn-pill border-4 btn-toggle' + ( statusObj.ID === curStatusID ? ' selected' : '' ) + ( exists ? '' : ' btn-ghost' )}
                                style={{ backgroundColor: 'rgb(' + statusObj.color + ')' }}
                                onClick={() => onClickSelect(statusObj.ID)}
                                disabled={btnDisabled}>
                            {statusObj.label}
                            {IconTick()}
                        </button>
                    );

                })}

            </div>
            
            <div className="modal-btns-wrapper cntr pad-top-30">
                <button className={'btn-pill border-4 btn-gry shadow-lgt pad-left-60 pad-right-60' + ( curFetching === 'userexam' ? ' fetching' : '' )} 
                        onClick={() => onClickAddExamStatusDone(curStatusID)}
                        disabled={btnDisabled}>
                    <span className="spinner"><span></span></span>Done
                </button>
            </div>
        </>
    );
    
    
};

export default ModalContentTagExam;


/*** Scripts end... */

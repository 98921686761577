import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ModalContext from '../../context/modal/ModalContext';
import TmplInsModal from '../modals/TmplInsModal';
import ModalContentPromptLogin from '../modals/ModalContentPromptLogin';


/*
 * This ErrorBox component dispalys any error messages returned by the server.
 */
const ErrorBox = () => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    const modalContext                          = useContext(ModalContext);
    
    // Destructure Context api's.
    const { fetchError,
            clearFetchError,
            signOutUser }                       = authenticationContext;
    const { isVisible,
            showModal,
            hideModal }                         = modalContext;
    
    // Reference hooks.
    const history                               = useHistory();
    
    // Component state.
    const [errorMsg, setErrorMsg]               = useState('');
    
    
    /*
     * Component methods.
     */
    
    const setErrorServer = () => {
        
        console.log('errorCode:', fetchError.code);
        console.log('errorDescription:', fetchError.description);
        
        setErrorMsg(fetchError.description);
        
        // Hide the error msg after a set period of time.
        setTimeout(clearError, 5000);
        
    };
    
    const setErrorHTTP = () => {
        
        let description = '';
        
        switch (fetchError.code) {
            case 400:
                description = 'Unable to complete your request.'; 
                break;
            case 401:
                signOutUser(401);
                break;
            case 403:
                signOutUser(403);
                break;
            case 404:
                history.push('/error');
                break;
            case 415:
                description = 'Invalid request format.'; 
                break;
            case 429:
                description = 'Too many requests, try again later.'; 
                break;
            case 500:
                description = 'There was an internal server error. Try again later.'; 
                break;
            default:
                description = 'Server error, please try again later.';
        }
        
        if ( fetchError.code !== 404 ) {

            // Set error message & hide it after a short delay.
            setErrorMsg(description);
            setTimeout(clearError, 5000);
            
        }
        
        
    };
    
    const clearError = () => {
        
        setErrorMsg('');
        clearFetchError();
        
    };
    
    const promptToLogin = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => <ModalContentPromptLogin />;
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        if ( fetchError !== null ) {
            
            // If we have an open modal then hide it following API call response.
            if ( typeof fetchError.req !== 'undefined' && 
                 isVisible ) {
                     
                if ( fetchError.req.endPoint === 'userexam' ||
                     fetchError.req.endPoint === 'userexam/session' ) hideModal();
                    
            }
            
            // Handle error type.
            if ( fetchError.type === 'server' ) setErrorServer();
            else if ( fetchError.type === 'http' ) setErrorHTTP();
            else if ( fetchError.type === 'loggedout' ) promptToLogin();
 
        }
        else setErrorMsg('');
        
    // eslint-disable-next-line 
    }, [fetchError]);


    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className={'error-box' + ( errorMsg !== '' ? ' show' : '' )}>
            <div className="error-msg">
                {errorMsg}
            </div>
        </div>
    );
    
    
};

export default ErrorBox;


/*** Scripts end... */

import { SET_AUTH_APPLICATION,
         SET_BEARER_TOKEN,
         SET_USER_AUTHENTICATED,
         SET_RESPONSE_STATUS,
         SET_FETCH_ERROR,
         SET_CUR_FETCHING,
         SET_PUBLIC_DATA,
         SET_PRIVATE_DATA,
         SET_OVERRIDE_DATA,
         SET_STUDY_DAYS_DATA,
         CLEAR_STUDY_DAYS_DATA,
         UPDATE_STUDY_TASKS_DATA,
         UPDATE_USER_EXAMS,
         UPDATE_COMPLETED_EXAMS,
         RESET_EXAM_PLANNER } from './AuthenticationTypes';


const AuthenticationReducer =  (state, action) => {

switch (action.type) {
   case SET_AUTH_APPLICATION:
       return {
           ...state,
           publicClientApplication  : action.payload
       };
    case SET_BEARER_TOKEN:
       return {
           ...state,
           bearerToken              : action.payload
       };
    case SET_USER_AUTHENTICATED:
       return {
           ...state,
           userAuthenticated        : action.payload
       };
    case SET_RESPONSE_STATUS:
       return {
           ...state,
           responseStatus           : action.payload
       };
    case SET_FETCH_ERROR:
        return {
            ...state,
            fetchError              : action.payload
        };
    case SET_CUR_FETCHING:
        return {
            ...state,
            curFetching             : action.payload
        };
    case SET_PUBLIC_DATA:
        return {
            ...state,
            examsData               : action.payload.exam,
            examsSessionData        : action.payload.examSession,
            userExamData            : []
        };
    case SET_PRIVATE_DATA:
        return {
            ...state,
            examsData               : action.payload.exam,
            examsSessionData        : action.payload.examSession,
            userName                : action.payload.user.firstName,
            userData                : action.payload.user,
            userExamData            : action.payload.userExam
        };
    case SET_OVERRIDE_DATA:
        return {
            ...state,
            publicClientApplication : 'override',
            userAuthenticated       : true,
            userName                : 'Override User'
        };
    case SET_STUDY_DAYS_DATA:
        return {
            ...state,
            examStudyDaysData       : action.payload,
            studyTasksData          : action.payload.studyDaysData.userExamTask
        };
    case CLEAR_STUDY_DAYS_DATA:
        return {
            ...state,
            examStudyDaysData       : null,
            studyTasksData          : null
        };
    case UPDATE_STUDY_TASKS_DATA:
        return {
            ...state,
            studyTasksData          : action.payload
        };
    case UPDATE_USER_EXAMS:
        return {
            ...state,
            userExamData            : action.payload
        };
    case UPDATE_COMPLETED_EXAMS:
        return {
            ...state,
            examsCompleted          : action.payload
        };
    case RESET_EXAM_PLANNER:
        return {
            ...state,
            userExamData            : [],
            examsCompleted          : []
        };
   default:
        return state;
};

};

export default AuthenticationReducer;


/*** Scripts end... */

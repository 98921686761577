import React, { useContext, useEffect} from 'react';

import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import TmplInsModal from '../modals/TmplInsModal';
import { openExtLink } from '../hooks/utilityFunctions';
import { ImgLogo,
         IconExamPlanning } from '../hooks/ImageAssets';


/*
 * This MobileIntro component only appears on mobile & displays the 
 * intro animation & steps to take.
 */
const MobileIntro = () => {
    
    
    // Reference Context api's
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { setScrollToPlanner,
            modalFtrTxt }                   = appContext;
    const { showModal,
            hideModal }                     = modalContext;
            
            
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const onClickShowInfoModal = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    
                    <h2>Tap an exam and set it as exempt,<br /> passed, taking or retaking</h2>
                    <h2>Plan out the exams you want to take<br /> in the next 12 months</h2>
                    <h2>Then review the calendar for booking links<br /> and preferred exam sessions</h2>
                    
                    <div className="cta-btns flex-wrapper-center">
                        <button className="btn-pill btn-red" onClick={hideModal}>Got it</button>
                    </div>

                </div>
            );
        };
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} txtFooter={modalFtrTxt}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="mobile-intro">
            
            <div className="content">
                
                {/* Logo */}
                <div className="mobile-logo-wrapper flex-wrapper-col flex-space-between flex-align-center">
                    <div className="mobile-logo flex-wrapper-col flex-align-center"
                         onClick={onClickShowInfoModal}>
                        {ImgLogo()}
                        <span>Exam planning</span>
                    </div>
                    <div className="icon-exam-planning">
                        <IconExamPlanning />
                    </div>
                </div>
                
                {/* Chevron - start button */}
                <div className="start-chevron chevron ch-rgt"
                     onClick={() => setScrollToPlanner(true)}>
                    <h2 className="start-txt">Plan exams and build<br />a day-to-day study plan...</h2>
                </div>

                {/* Copyright text */}
                <div className="mobile-copyright-txt">
                    <div className="dim-txt">
                        © ACCA 2022
                        <span className="mgn-left-10 mgn-right-10">|</span>
                        <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/student.html', true)}>ACCA Student resources</span>
                        <span className="mgn-left-10 mgn-right-10">|</span>
                        <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html', true)}>Contact <i className="tny-mob-hide">us</i></span>
                    </div>
                </div>
            </div>
            
        </div>
    );
    
    
};

export default MobileIntro;


/*** Scripts end... */

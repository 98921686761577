import React, { useContext, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import { openExtLink,
         getSessionDate } from '../hooks/utilityFunctions';
import useWindowDimensions from '../hooks/getWindowDimensions';
import AppHeader from '../layout/AppHeader';
import AppFooter from '../layout/AppFooter';
import StudyPlanItem from '../layout/StudyPlanItem';
import TmplSysModal from '../modals/TmplSysModal';
import ModalContentExamPeriod from '../modals/ModalContentExamPeriod';
import ModalContentCancelPlan from '../modals/ModalContentCancelPlan';
import { ImgLogo,
         IconStudyPlanning } from '../hooks/ImageAssets';


/*
 * This Login component is not used (may require login in a future release),
 * but currently redirects user to the Home component.
 */
const StudyPlanner = () => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Reference hooks.
    const history                           = useHistory();
    
    // Get window dimensions.
    const { winWidth }                      = useWindowDimensions();
    
    // Destructure Context api's.
    const { userAuthenticated,
            examStudyDaysData,
            clearStudyDaysData,
            examsData,
            userExamData,
            examsSessionData,
            studyTasksData,
            updateUserExam,
            responseStatus,
            resetResponseStatus,
            getStudyDayData,
            postDataNoPayload }             = authenticationContext;
    const { setIsInitialLoad, 
            staticsData }                   = appContext;
    const { showModal,
            hideModal }                     = modalContext;
            
    // Define some DOM reference hooks.
    const trackRef                          = useRef(null);
    const scrubberRef                       = useRef(null);
    const listRef                           = useRef(null);
    const scrollerRef                       = useRef(null);
    
    // Component state.
    const [scrubberPos, setScrubberPos]     = useState(0);
    const [isScrolling, setIsScrolling]     = useState(true);
    const [studyDaysArr, setStudyDaysArr]   = useState([]);
    const [tasksArr, setTasksArr]           = useState([]);
    
    
    
    /*
     * Component methods.
     */
    
    const getActivityType = val => {
        
        switch (val) {
            case 1:
                return 'none';
            case 2:
                return 'planning';
            case 3:
                return 'learning';
            case 4:
                return 'revision';
            case 5:
                return 'final';
            case 6:
                return 'exam';
            case 7:
                return 'reflection';
            default:
                return 'none';
        }
        
    };
    
    const getBreadcrumbTxt = () => {
        
        const examData      = examsData.find(item => item.UNID === examStudyDaysData.examUNID);
        const sectionData   = staticsData.examSectionType.find(item => item.ID === examData.sectionType);
        const sectionName   = sectionData.label;
        const examNameMob   = examData.shortName;
        const examNameDsk   = examData.name;
        const dateMob       = getSessionDate(examStudyDaysData.sessionUNID, examsSessionData, 'short') + ' Exam';
        const dateDsk       = getSessionDate(examStudyDaysData.sessionUNID, examsSessionData, 'long') + ' Exam';

        
        return (
            <>
                <span className="is-mobile is-inline">{`${examNameMob} / ${dateMob}`}</span>
                <span className="is-desktop is-inline">{`${sectionName} / ${examNameDsk} / ${dateDsk}`}</span>
            </>
        );
        
    };
    
    const getMatchingTasks = dayUNID => {
        
        return tasksArr.filter(item => item.userExamStudyDayUNID === dayUNID);
        
    };
    
    const updateExamSession = (sessionUNID, studyPeriodLength) => {
        
        // Get matching userExam which will be updated.
        const userExam = userExamData.find(item => item.examUNID === examStudyDaysData.examUNID);
        
        // If user chose to cancel plan, reset userExam in 'userExamData' array, 
        // removing current session.
        if ( sessionUNID === null ) {
            
            if ( typeof userExam !== 'undefined' &&
                userExam.statusType !== 0 ) {
                
                userExam.examSessionUNID        = null;
                userExam.studyPeriodLength      = 0;
                userExam.studyPlanningLength    = 0;
                userExam.studyExamLength        = 0;
                
                updateUserExam(userExam);
                
            }
            
            hideModal();
            
        }
        
        // Else update exam in 'userExamData' array, adding selected Session UNID.
        else {
            
            if ( typeof userExam !== 'undefined' &&
                 userExam.statusType !== 0 ) {
                
                userExam.examSessionUNID    = sessionUNID;
                userExam.studyPeriodLength  = studyPeriodLength;
                
                updateUserExam(userExam);
                
            }
            
            getStudyDayData('userexam/session', examStudyDaysData.examUNID, userExam.examSessionUNID); 
            
            hideModal();
            
        }
  
    };
    
    const getModalProps = req => {
        
        const examData      = examsData.find(item => item.UNID === examStudyDaysData.examUNID);
        const section       = staticsData.examSectionType.find(item => item.ID === examData.sectionType);
        
        if ( req === 'titleA' ) return section.label;
        else if ( req === 'titleB' ) return examData.name + '<br />' + getSessionDate(examStudyDaysData.sessionUNID, examsSessionData, 'long') + ' Exam';
        else if ( req === 'titleB2' ) return examData.name + '<span class="modal-title-para">Are you sure you want to stop planning for the ' + getSessionDate(examStudyDaysData.sessionUNID, examsSessionData, 'long') + ' exam?</span>';
        else if ( req === 'txtFooter' ) return '<span class="txt-bld-red">NOTE</span> Reducing your study period may result in some of your existing tasks being hidden from view. Nothing is deleted, you can extend the period again to reveal any previous entries.';
        else if ( req === 'examData' ) return examData;
        else return '';
        
    };
    
    const cancelThisPlan = () => {

        // If user is 'authenticated' update the server, then local data.
        if ( userAuthenticated ) {
            
            const requestObj    = {
                userExamSession: {
                    examUNID            : examStudyDaysData.examUNID,
                    examSessionUNID     : null,
                    studyPeriodLength   : 0,
                    studyPlanningLength : 0,
                    studyExamLength     : 0,
                    studyTypes          : [],
                    starterTasks        : [
                        staticsData.copy.starterTask1,
                        staticsData.copy.starterTask2,
                        staticsData.copy.starterTask3,
                        staticsData.copy.starterTask4,
                        staticsData.copy.starterTask5,
                        staticsData.copy.starterTask6,
                        staticsData.copy.starterTask7
                    ]
                }
            };
            
            postDataNoPayload('userexam/session', { examUNID: examStudyDaysData.examUNID, sessionUNID: null, studyPeriodLength: 0 }, requestObj);
            
        }
        
        // Else just update local data.
        else {
            
            // Get matching userExam which will be updated.
            const userExam = userExamData.find(item => item.examUNID === examStudyDaysData.examUNID);
            
            // Reset userExam in 'userExamData' array, removing current session.
            if ( typeof userExam !== 'undefined' &&
                userExam.statusType !== 0 ) {
                
                userExam.examSessionUNID        = null;
                userExam.studyPeriodLength      = 0;
                userExam.studyPlanningLength    = 0;
                userExam.studyExamLength        = 0;
                
                updateUserExam(userExam);
                
            }
            
            hideModal();
            
        }

    };
    
    
    
    
    /*
     * Component event handlers.
     */
    
    const onScrollTaskList = event => {
        
        if ( isScrolling ) {
            
            const scrollTop     = Math.round(event.currentTarget.scrollTop);
            const scrollHeight  = Math.round(event.currentTarget.scrollHeight);
            const clientHeight     = Math.round(event.currentTarget.clientHeight);
            const newScrubPos   = ( scrollTop / ( scrollHeight - clientHeight ) * 100 );
            
            setScrubberPos(newScrubPos);
            
        }
  
    };
    
    // Open the Study period picker modal, skipping the previous two.
    // This is triggered by clicking an events timeline.
    const onClickExamTimelineModal = () => {

        // System - define modal content.
        const htmlBody = () => {
            return (
                <div className="modal-body">
                    <ModalContentExamPeriod sessionUNID={examStudyDaysData.sessionUNID}
                                            examData={getModalProps('examData')}
                                            ctaBtnTxtA=''
                                            ctaBtnTxtB='Done'
                                            ctaBtnFncA={null} 
                                            ctaBtnFncB={onClickExamEventUpdatePeriod} />
                </div>
            );
        };
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type        : 0,
                size        : 'small',
                content     : <TmplSysModal txtTitleA={getModalProps('titleA')} 
                                            txtTitleB={getModalProps('titleB')} 
                                            txtFooter={getModalProps('txtFooter')}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };
    
    const onClickCancelPlanModal = () => {
        
        // System - define modal content.
       const htmlBody = () => {
           return (
               <div className="modal-body">
                   <ModalContentCancelPlan ctaBtnTxtA='No, don&#39;t cancel'
                                           ctaBtnTxtB='Yes, cancel plan'
                                           ctaBtnFncA={hideModal} 
                                           ctaBtnFncB={cancelThisPlan} />
               </div>
           );
       };
       
       // System - initialize modal.
       const props = {
           status  : true,
           props   : {
               type                : 0,
               size                : 'small',
               closeOnClickModal   : false,
               content             : <TmplSysModal txtTitleA={getModalProps('titleA')} 
                                                   txtTitleB={getModalProps('titleB2')} 
                                                   txtFooter={getModalProps('txtFooter')}>{htmlBody()}</TmplSysModal>
           }
       };
       
       // System - show modal.
       showModal(props); 
        
    };
    
    const onClickExamEventUpdatePeriod = (sessionUNID, studyPeriodLength, studyTypesArr) => {
        
        const examData      = examsData.find(item => item.UNID === examStudyDaysData.examUNID);
        const requestObj    = {
            userExamSession: {
                examUNID: examStudyDaysData.examUNID,
                examSessionUNID: sessionUNID,
                studyPeriodLength: studyPeriodLength,
                studyPlanningLength: 0,
                studyExamLength: examData.type === 1 ? 4 : 2,
                studyTypes: studyTypesArr,
                starterTasks        : [
                    staticsData.copy.starterTask1,
                    staticsData.copy.starterTask2,
                    staticsData.copy.starterTask3,
                    staticsData.copy.starterTask4,
                    staticsData.copy.starterTask5,
                    staticsData.copy.starterTask6,
                    staticsData.copy.starterTask7
                ]
            }
        };
         
        postDataNoPayload('userexam/session', { examUNID: examStudyDaysData.examUNID, sessionUNID, studyPeriodLength }, requestObj);
        
    };
    
    const onClickGoToHome = () => {
        
        // Clear 'examStudyDaysData' so we fetch new data when returning.
        clearStudyDaysData();
        
        // Navigate to the home page.
        history.push('/');
        
    };

    const onClickBookExam = () => {
        
        const exam = examsData.find(item => item.UNID === examStudyDaysData.examUNID);
        
        openExtLink(exam.bookURL, true)
        
    };
    
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Setting 'isInitialLoad' to false stops home page fading in and removes modal.
        setIsInitialLoad(false);
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        // Calculate the new list position offset.
        if ( !isScrolling && listRef.current ) {
            
            scrollerRef.current.scrollTop = ( scrubberPos / 100 ) * ( listRef.current.clientHeight - scrollerRef.current.clientHeight );
            
        }
        
    // eslint-disable-next-line 
    }, [scrubberPos]);
    
    useEffect(() => {
        
        setStudyDaysArr(examStudyDaysData.studyDaysData.userExamStudyDay);
        
    // eslint-disable-next-line 
    }, [examStudyDaysData.studyDaysData.userExamStudyDay]);
    
    useEffect(() => {
        
        setTasksArr(studyTasksData);
        
    // eslint-disable-next-line 
    }, [studyTasksData]);
    
    // Listen for 'responseStatus' changes and if they are TRUE 
    // continue updating local model.
    useEffect(() => {
        
        if ( responseStatus !== null && 
             responseStatus.status === true && 
             responseStatus.props.examUNID === examStudyDaysData.examUNID ) {
            
            if ( responseStatus.endPoint === 'userexam/session' ) updateExamSession(responseStatus.props.sessionUNID, responseStatus.props.studyPeriodLength);
            
            resetResponseStatus();
            
        }
        
    // eslint-disable-next-line 
    }, [responseStatus]);
    
    //
    useEffect(() => {
        
        // Get matching userExam, then if 'examSessionUNID' is null navigate back to the Exam Planner.
        const userExam = userExamData.find(item => item.examUNID === examStudyDaysData.examUNID);
        
        if ( userExam.examSessionUNID === null ) onClickGoToHome();
        
    // eslint-disable-next-line 
    }, [userExamData]);
    

     
     
    /*
     * Component view.
     */
    
    return (
        <div id="app-study-planner"
                className="app-study-planner flex-wrapper-row">
            
            <AppHeader />
            
            <div className="body-content flex-wrapper-row">
                
                {/* MOBILE ONLY header showing logo & section icon */}
                <div className="mob-header">
                    <div className="flex-wrapper-col flex-space-between flex-align-center">
                        <div className="mobile-logo flex-wrapper-col flex-align-center">
                            <ImgLogo />
                            <span>Study planning</span>
                        </div>
                        <div className="icon-study-planning">
                            <IconStudyPlanning />
                        </div>
                    </div>
                </div>
                
                {/* Page breadcrumb */}
                <div className="study-breadcrumb flex-wrapper-col flex-space-between flex-align-center">
                    <div>
                        <div className="txt-breadcrumb">
                            {getBreadcrumbTxt()}
                        </div>
                        <div className="btns-grp">
                            <button className="txt-lnk-highlight txt-lnk-underline" 
                                    onClick={onClickBookExam}>Book exam</button>
                            <span className="dim-txt mgn-left-5 mgn-right-5">|</span>
                            <button className="txt-lnk-highlight txt-lnk-underline" 
                                    onClick={onClickExamTimelineModal}>Adjust study period</button>
                            <span className="dim-txt mgn-left-5 mgn-right-5">|</span>
                            <button className="txt-lnk-highlight txt-lnk-underline" 
                                    onClick={onClickCancelPlanModal}>Cancel this plan</button>
                        </div>
                    </div>
                    <button className="btn-sml btn-red"
                            onClick={onClickGoToHome}>
                        Done
                    </button>
                </div>
                
                {/* Daily planner */}
                <div className="study-planner">
                    <div ref={scrollerRef} 
                            className="scroller-vrt"
                            onScroll={event => onScrollTaskList(event)}>
                        <ul ref={listRef} 
                            className="item-daily-list">
                        
                            {/* Daily item */}
                            {studyDaysArr.map((item, idx) => (
                                <StudyPlanItem key={idx}
                                               itemData={item}
                                               examUNID={examStudyDaysData.examUNID}
                                               sessionUNID={examStudyDaysData.sessionUNID}
                                               tasks={getMatchingTasks(item.UNID)} />
                            ))}

                        </ul> 
                    </div>
                </div>
                
                {/* Daily scrubber */}
                { studyDaysArr.length > 0 && (
                    <div className="study-scrubber">
                        <div ref={trackRef}
                             className="scrubber-btn-track">
            
                            <div ref={scrubberRef}
                                 className={ 'scrubber flex-wrapper-col' + ( ( studyDaysArr.length < 106 && winWidth > 667  ) ? ' gap' : '' ) }>
                                    
                                {/* Cell item */}
                                {studyDaysArr.map((item, idx) => (
                                    <span key={idx}
                                          className={ 'item-mid stage-' + getActivityType(item.studyType) }></span>
                                ))}
                                
                            </div>
                            
                            {/* Scrubber button */}
                            <input className="btn-scrubber"
                                   type="range"
                                   min="0"
                                   max="100"
                                   value={scrubberPos}
                                   onMouseDown={() => setIsScrolling(false)}
                                   onMouseUp={() => setIsScrolling(true)}
                                   onTouchStart={() => setIsScrolling(false)}
                                   onTouchEnd={() => setIsScrolling(true)}
                                   onChange={event => setScrubberPos(event.target.value)}></input>
                        
                        </div>
                    </div>
                ) }
            
            </div>
            
            <AppFooter />
            
        </div>
    );
    
    
};

export default StudyPlanner;


/*** Scripts end... */

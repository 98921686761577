/*
 * Config object to be passed to Msal on creation.
 * redirectUri: 'http://localhost:3000' / process.env.REACT_APP_REDIRECT_URI
 * scopes: process.env.REACT_APP_B2C_SCOPES
 */
export const msalConfig = {
    auth: {
        clientId                    : process.env.REACT_APP_CLIENT_ID,
        authority                   : process.env.REACT_APP_AUTHORITY,
        knownAuthorities            : [process.env.REACT_APP_KNOWN_AUTHORITY],
        redirectUri                 : process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri       : process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation               : 'sessionStorage',
        storeAuthStateInCookie      : true
    },
    system: {
        tokenRenewalOffsetSeconds   : 60
    }
};


/*
 * Scopes you add here will be prompted for consent during login.
 */
export const loginConfig = {
    prompt: 'login',
    scopes: [process.env.REACT_APP_B2C_SCOPES]
};


/*
 * Public & Private API URL address.
 */
export const apiUrlPublic   = process.env.REACT_APP_API_DOMAIN + '/api/public/';
export const apiUrlPrivate  = process.env.REACT_APP_API_DOMAIN + '/api/private/';
import React, { useContext, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import { openExtLink } from '../hooks/utilityFunctions';


/*
 * This MobileAction component only appears on mobile & displays the 
 * snapshot button & help text.
 */
const MobileAction = () => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    
    // Destructure Context api's.
    const { userAuthenticated,
            userName,
            signInUser,
            signOutUser }                   = authenticationContext;
    const { staticsData,
            onClickTakeScreenshot }         = appContext;
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="mobile-action"> 
            <div className="content flex-wrapper-row flex-space-between">
                
                {/* Top content area: START */}
                <div>
                    
                    {/* Log in text & button */}
                    <div className="mob-login dim-txt">
                        { userAuthenticated ?
                            <>
                                Logged in as<br /> 
                                {userName}       
                                <button className="txt-lnk txt-lnk-highlight"
                                        onClick={signOutUser}>Log out</button>
                            </>
                        :
                            <>
                                <button className="txt-lnk txt-lnk-highlight mgn-left-0"
                                        onClick={signInUser}>Log in</button>
                                &nbsp;to access additional<br />study planning features
                            </>
                        } 
                    </div>
                    
                </div>
                {/* Top content area: STOP */}
                
                {/* Bottom content area: START */}
                <div>
                    
                    {/* Snapshot text & button */}
                    <div className="mob-snapshot dim-txt">
                        When you're ready <button className="txt-lnk" onClick={onClickTakeScreenshot}>take a snapshot</button> of your 12 month plan for reference.
                    </div>
                    
                    {/* Help text */}
                    <div className="mobile-help-txt">
                    
                        <div className="help-txt flex-wrapper-col flex-justify-center">
                            <p className="dim-txt">{staticsData.copy.guidance1}</p>
                        </div>

                        <div className="help-txt flex-wrapper-col flex-justify-center">
                            <p className="dim-txt">{staticsData.copy.guidance2}</p>
                        </div>
                        
                    </div>

                </div>
                {/* Bottom content area: STOP */}
                
            </div> 
            
            {/* Copyright text */}
            <div className="mobile-copyright-txt">
                <div className="dim-txt">
                    © ACCA 2022
                    <span className="mgn-left-10 mgn-right-10">|</span>
                    <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/student.html', true)}>ACCA Student resources</span>
                    <span className="mgn-left-10 mgn-right-10">|</span>
                    <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html', true)}>Contact <i className="tny-mob-hide">us</i></span>
                </div>
            </div>
            
        </div>
    );
    
    
};

export default MobileAction;


/*** Scripts end... */

import React, { useContext, useState, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ModalContext from '../../context/modal/ModalContext';
import { IconTick } from '../hooks/ImageAssets';


/*
 * This ModalContentExamSelect component displays the HTML for the 1st Exam Session modal.
 * The user can choose to select an exam or cancel current selection.
 */
const ModalContentExamSelect = ({ sessionUNID, noTime, ctaBtnTxtA, ctaBtnTxtB, ctaBtnFncA, ctaBtnFncB }) => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    const modalContext                          = useContext(ModalContext);
    
    // Destructure Context api's.
    const { curFetching }                       = authenticationContext;
    const { hideModal }                         = modalContext;
    
    // Component state.
    const [selectedBtn, setSelectedBtn]         = useState(0);
    const [btnDisabled, setBtnDisabled]         = useState(true);
    
    
    /*
     * Component methods.
     */
    
    const onClickBtnDone = () => {
        
        if ( selectedBtn === 1 ) ctaBtnFncA(sessionUNID);
        else if ( selectedBtn === 2 ) ctaBtnFncB();
        else hideModal();
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // If we already have a selected exam session show a tick on the select exam button.
        //const userExam = userExamData.find(item => item.examUNID === examUNID);
        
        //if ( userExam.examSessionUNID !== null ) setSelectedBtn(1);
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        setBtnDisabled(curFetching === '' ? false : true);
        
    // eslint-disable-next-line 
    }, [curFetching]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            { noTime && <div className="txt-no-time">This exam session does not allow for the minimum recommended study period</div> }
        
            <div className="flex-wrapper-col flex-wrap-ok flex-justify-center mgn-top-15">
                <button className={ 'btn-pill btn-toggle btn-red btn-session' + ( selectedBtn === 1 ? ' selected' : '' )}
                        onClick={() => setSelectedBtn(1)}
                        disabled={btnDisabled}>
                    { noTime ? 'Select exam' : ctaBtnTxtA }
                    {IconTick()}
                </button>
                <button className={ 'btn-pill btn-toggle btn-gry-drk btn-session' + ( selectedBtn === 2 ? ' selected' : '' )}
                        onClick={() => setSelectedBtn(2)}
                        disabled={btnDisabled}>
                    {ctaBtnTxtB}
                    {IconTick()}
                </button>
            </div> 
            <div className="modal-btns-wrapper cntr pad-top-30">
                <button className={'btn-pill border-4 btn-gry shadow-lgt pad-left-60 pad-right-60' + ( curFetching === 'userexam/session' ? ' fetching' : '' )} 
                        onClick={onClickBtnDone}
                        disabled={btnDisabled || selectedBtn === 0}>
                    <span className="spinner"><span></span></span>Continue
                </button>
            </div>
        </>
    );
    
    
};

export default ModalContentExamSelect;


/*** Scripts end... */

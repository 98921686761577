import React, { useContext, useState, useRef, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';


/*
 * This ModalChooseMood component displays the HTML for the Choose Mood/Reflection modal.
 */
const ModalChooseMood = ({ moodArr, itemData }) => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { putDataNoPayload }              = authenticationContext;
    const { staticsData }                   = appContext;
    const { hideModal }                     = modalContext;
    
    // Component state.
    const [curMood, setCurMood]             = useState(0);
    const [tipTxtArr, setTipTxtArr]         = useState([]);
    const [moodScroll, setMoodScroll]       = useState(false);
    
    // Define some DOM reference hooks.
    const scrollerRef                       = useRef(null);
    const contentRef                        = useRef(null);
    
    
    /*
     * Component methods.
     */
    
    const drawMoodBtns = btnArr => {
        
        return (moodArr.map((item, idx) => {
            
            if ( btnArr.includes(item.id) ) {
                
                return (
                    <button key={idx}
                            className={ 'btn-choose-mood' + ( item.id === curMood ? ' selected' : '' ) }
                            onClick={() => onClickUpdateMood(item.id)}>
                        {item.icon}<span>{item.id}</span>
                    </button>
                );
                
            }
            else return null;
            
        }))
        
    };
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickUpdateMood = id => {
        
        // If id matches curMood then unselect it by setting id to 0.
        if ( id === curMood ) setCurMood(0);
        else setCurMood(id);

    };
    
    const onClickDone = () => {
        
        // Update day's current mood.
        //updateMood(, curMood);
        
        // Update any changes to the Mood Type, locally & async on the server.
        if ( curMood !== itemData.moodType ) {
            
            // Update 'moodType' locally.
            itemData.moodType = curMood;
            
            // Update 'moodType' on the server.
            // This will be ASYNC so post & forget, but if an error does come back handle that.
            putDataNoPayload('userexam/session/day/reflection', { itemUNID: itemData.UNID }, { userExamSessionDay: { UNID: itemData.UNID, moodType: curMood } });
            
        }
        
        // Close modal.
        hideModal();
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Update curMood with passed in value for this item.
        setCurMood(itemData.moodType);
        
        // Populate 'tipTxtArr'.
        if ( staticsData.studyMoodType !== null ) setTipTxtArr(staticsData.studyMoodType);
        
        // If we are on a small mobile device & modal body needs to scroll
        // then show the scroll shadow just above teh CTA button.
        const scrollerHeight    = scrollerRef.current.clientHeight;
        const contentHeight     = contentRef.current.clientHeight;
        
        if ( contentHeight > scrollerHeight ) setMoodScroll(true);
        else setMoodScroll(false);
        
    // eslint-disable-next-line 
    }, [staticsData]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            <div ref={scrollerRef}
                 className="mood-scroller">    
                <div ref={contentRef}>
            
                    {/* Prompt message */}
                    <h2>Add a reflection: How do you feel about this?</h2>
                        
                    {/* Mood pickers */}    
                    <div className="mood-wrapper flex-wrapper-col flex-space-between">
                        {drawMoodBtns([9, 8, 7])}
                    </div>
                    <div className="mood-wrapper flex-wrapper-col flex-space-between">
                        {drawMoodBtns([6, 5, 4])}
                    </div>
                    <div className="mood-wrapper flex-wrapper-col flex-space-between">
                        {drawMoodBtns([3, 2, 1])}
                    </div>
                    
                    {/* Accompanying mood tip */}
                    <div className="txt-study-plane-tip">
                        { ( tipTxtArr.length > 0 ) && <><b>Tip: </b>{tipTxtArr[curMood].tip}</> }
                    </div>
            
                </div>
            </div>
            
            {/* Done button, updates item data & closes modal */}
            <div className={ 'flex-wrapper-col flex-justify-center' + ( moodScroll ? ' scroll-shadow' : '' ) }>
                <button className="btn-pill btn-red border-4 btn-gry shadow-lgt mgn-top-15 pad-left-50 pad-right-50"
                        onClick={onClickDone}>Done</button>
            </div>
        </>
    );
    
    
};

export default ModalChooseMood;


/*** Scripts end... */

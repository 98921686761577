import React from 'react';

import { ImgQuadx3 } from '../hooks/ImageAssets';


/*
 * This Intro component appears when the site first loads in the browser &
 * shows the ACCA brand animation, then redirects to the Home page.
 */
const Intro = () => {
    
    
    /*
     * Component view.
     */
    
    return (
        <div className="app-intro">
            
            {/* Intro animation: START */}
            <div className="app-intro-brand-wrapper flex-wrapper-center">
                <div className="app-intro-brand">
                    <div className="quad">
                        <span>Com<b>pass</b></span>
                        {ImgQuadx3()}
                    </div>
                    <div className="strapline">
                        Your ACCA Study planner
                    </div>
                </div>
            </div>
            {/* Intro animation: STOP */}
            
        </div>
    );
    
    
};

export default Intro;


/*** Scripts end... */

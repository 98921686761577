import React, { useContext, useEffect} from 'react';

import AppContext from '../../context/app/AppContext';

import ChevronTailC from '../../assets/img/chevron-tail-c.png';
import ChevronMidC from '../../assets/img/chevron-mid-c.png';
import ChevronPointC from '../../assets/img/chevron-point-c.png';


/*
 * This ExamsFooter component displays help text below the planner on desktop only.
 */
const ExamsFooter = () => {
    
    
    // Reference Context api's
    const appContext                    = useContext(AppContext);
    
    // Destructure Context api's.
    const { staticsData,
            onClickTakeScreenshot }     = appContext;
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="exams-footer">
            <div className="content flex-wrapper-col">
                
                <div className="chevron-img"><img src={ChevronTailC} alt="" /></div>
                
                {/* Section Title - Applied Knowledge */}
                <div className="exams-footer-chevron info-1">
                    <div className="content">
                        <div className="help-txt flex-wrapper-col">
                            <div className="info-icon">!</div>
                            <div className="info-txt dim-txt">{staticsData.copy.guidance1}</div>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronMidC} alt="" /></div>
                
                {/* Section Title - Applied Skills */}
                <div className="exams-footer-chevron info-2"> 
                    <div className="content">
                        <div className="help-txt flex-wrapper-col">
                            <div className="info-icon">!</div>
                            <div className="info-txt dim-txt">
                                {staticsData.copy.guidance2}
                                <br />
                                When you're ready <button className="txt-lnk txt-lnk-highlight" onClick={onClickTakeScreenshot}>take a snapshot</button> for your reference.
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="chevron-img"><img src={ChevronPointC} alt="" /></div>
                
            </div>
        </div>
    );
    
    
};

export default ExamsFooter;


/*** Scripts end... */

import React, { useContext, useState, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import { getSince1970,
         getSessionWeek } from '../hooks/utilityFunctions';


/*
 * This ModalContentExamPeriod component displays the study period selector for the selected exam.
 */
const ModalContentExamPeriod = ({ sessionUNID, examData, ctaBtnTxtA, ctaBtnTxtB, ctaBtnFncA, ctaBtnFncB }) => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    const appContext                            = useContext(AppContext);
    const modalContext                          = useContext(ModalContext);
    
    // Destructure Context api's.
    const { userExamData,
            examsSessionData,
            curFetching }                       = authenticationContext;
    const { staticsData  }                      = appContext;
    const { hideModal }                         = modalContext;
    
    // Component state.
    const [selStudyWeeks, setSelStudyWeeks]     = useState(null);
    const [btnIncStatus, setBtnIncStatus]       = useState(false);
    const [btnDecStatus, setBtnDecStatus]       = useState(false);
    const [planningPeriod, setPlanningPeriod]   = useState(0);
    const [learningPeriod, setLearningPeriod]   = useState(0);
    const [revisionPeriod, setRevisionPeriod]   = useState(0);
    const [studyTypesArr, setStudyTypesArr]     = useState([]);
    const [btnDisabled, setBtnDisabled]         = useState(true);
    const [btnClicked, setBtnClicked]           = useState(0);
    
    // Local variables.
    const activityTypeArr = [2, 3, 4];
    
    
    /*
     * Component methods.
     */

    const updateStudyPeriods = () => {
        
        // Get matching Study Data.
        const studyData     = getSessionStudyData();
        
        // Calculate the Revision period.
        let studyObj        = null;
        
        // If selected number of weeks is greater than 'maxWeeks' use the
        // 'revisionWeeks' value from the highest 'weeksUntil'.
        if ( selStudyWeeks > studyData.maxWeeks ) studyObj = studyData.studyPeriod.find(item => item.weeksUntil === studyData.maxWeeks);
        
        // Else match the 'selStudyWeeks' with it's counterpart in the 'studyArr'.
        else studyObj = studyData.studyPeriod.find(item => item.weeksUntil === selStudyWeeks);
        
        // Set the time periods for Planning, Revision & Learning.
        const tmpArr            = [];
        const numWeeksPlanning  = ( studyObj.weeksUntil - studyObj.revisionWeeks ) > 1 ? 1 : 0;
        const numWeeksLearning  = calculateLearningWeeks(selStudyWeeks, numWeeksPlanning, studyObj.revisionWeeks);
        const numWeeksRevision  = studyObj.revisionWeeks;
        const numWeeksExam      = examData.type === 1 ? 4 : 2;

        for ( let i = 0; i < numWeeksPlanning; i++ ) { tmpArr.push(2); } 
        for ( let i = 0; i < numWeeksLearning; i++ ) { tmpArr.push(3); } 
        for ( let i = 0; i < numWeeksRevision; i++ ) { tmpArr.push(4); } 
        for ( let i = 0; i < numWeeksExam; i++ ) { tmpArr.push(6); } 
        
        setPlanningPeriod(numWeeksPlanning);
        setLearningPeriod(numWeeksLearning);
        setRevisionPeriod(numWeeksRevision);
        setStudyTypesArr(tmpArr);
        
    };
    
    const getUserExamData = () => {
        
        return userExamData.find(item => item.examUNID === examData.UNID);
        
    };
    
    const getSessionStudyData = () => {
        
        // Use the sessionUNID to find the matching study period data & return it.
        const userExam          = userExamData.find(item => item.examUNID === examData.UNID);
        const statusKey         = userExam.statusType === 3 ? 'take' : 'retake';
        const studyData         = staticsData.sessionStudyPeriods.find(item => item.ID === examData.type);
        
        return studyData[statusKey];
        
    };
    
    const getWeeksUntil = () => {
        
        // Get the Current day number & Session day number (should be first day of the month).
        const curDayNum         = getSince1970('dy');
        const sessionDayNum     = getSessionWeek(sessionUNID, examsSessionData, 'dy');
        
        return Math.round((sessionDayNum - curDayNum) / 7);
        
    };
    
    const calculateLearningWeeks = (selStudyWeeks, planningWeeks, revisionWeeks) => {
        
        return selStudyWeeks - ( revisionWeeks + planningWeeks );
        
    };
    
    
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickBtnA = sessionUNID => {
        
        setBtnClicked(1);
        ctaBtnFncA(sessionUNID);
        
    };
    
    const onClickBtnB = (sessionUNID, selStudyWeeks, studyTypesArr) => {
        
        const userExam = getUserExamData();
        
        // If nothing was changed just close the modal.
        if ( userExam.examSessionUNID === sessionUNID && 
             userExam.studyPeriodLength === selStudyWeeks ) hideModal();
        
        // Else submit the change to the server.
        else {
            
            setBtnClicked(2);
            ctaBtnFncB(sessionUNID, selStudyWeeks, studyTypesArr);
            
        }

    };
    
   
    
    
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        const examUser      = getUserExamData();
        const studyData     = getSessionStudyData();
        const numWeeks      = examUser.studyPeriodLength !== 0 ? examUser.studyPeriodLength : studyData.defaultWeeks;
        
        // If we are amending the study period (user clicked on timeline, as oppossed to event btn), then there is 
        // already a study periods chosen, so use this as the default, crop it if it is greater tha weeks until.
        // Else use the default value from statics.    
        setSelStudyWeeks(numWeeks > getWeeksUntil() ? getWeeksUntil() : numWeeks);
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        if ( selStudyWeeks === null ) return;

        const studyData     = getSessionStudyData();
        const minAllowed    = studyData.minWeeks;
        const maxAllowed    = studyData.exceedMax ? getWeeksUntil() : studyData.maxWeeks;
        
        // Enable/disable DECREMENT button.
        if ( selStudyWeeks <= minAllowed ) setBtnDecStatus(false);
        else setBtnDecStatus(true);
        
        // Enable/disable INCREMENT button.
        if ( selStudyWeeks >= maxAllowed ) setBtnIncStatus(false);
        else setBtnIncStatus(true);
        
        // Calculate the number of weeks devoted to Planning, Revision & Learning. 
        updateStudyPeriods();
        
    // eslint-disable-next-line 
    }, [selStudyWeeks]);
    
    useEffect(() => {
        
        if ( curFetching === '' ) setBtnClicked(0);
        
        setBtnDisabled(curFetching === '' ? false : true);
        
    // eslint-disable-next-line 
    }, [curFetching]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            <h6>Select a study period for this exam:</h6>
            
            {/* Period selector: START */}
            <div className="period-selector flex-wrapper-col flex-space-between">
                <button className={ 'btn-pill border-4 btn-red shadow-lgt btn-minus' + ( !btnDecStatus ? ' disabled' : '' ) }
                        onClick={() => setSelStudyWeeks(selStudyWeeks - 1)}
                        disabled={btnDisabled}><span>-</span></button>
                <div className="duration">{selStudyWeeks} weeks</div>
                <button className={ 'btn-pill border-4 btn-red shadow-lgt btn-plus' + ( !btnIncStatus ? ' disabled' : '' ) }
                        onClick={() => setSelStudyWeeks(selStudyWeeks + 1)}
                        disabled={btnDisabled}><span>+</span></button>
            </div>
            {/* Period selector: STOP */}
            
            {/* Period chart: START */}
            <div className="period-chart">
            
                {staticsData.studyActivityType
                    .filter(item => activityTypeArr.includes(item.ID))
                    .map((item, idx) => (
                        <div key={idx} 
                             className="flex-wrapper-col flex-space-between"
                             style={{ backgroundColor: 'rgb(' + item.color + ')' }}>
                            <span>{item.label}</span>
                            {/* Planning */}
                            { item.ID === 2 && <span>{ planningPeriod > 1 ? planningPeriod + ' weeks' : planningPeriod + ' week'  }</span> }
                            {/* Learning */}
                            { item.ID === 3 && <span>{ learningPeriod > 1 ? learningPeriod + ' weeks' : learningPeriod + ' week'  }</span> }
                            {/* Revision/Mock Exam */}
                            { item.ID === 4 && <span>{ revisionPeriod > 1 ? revisionPeriod + ' weeks' : revisionPeriod + ' week'  }</span> }
                        </div>
                    ))
                }
                
            </div>
            {/* Period chart: STOP */}
            
            {/* Period CTA: START */}
            <div className={ 'period-cta flex-wrapper-col' + ( ctaBtnFncA !== null ? ' flex-space-between' : ' flex-justify-center' )}>
                { ctaBtnFncA !== null &&
                    <button className="txt-lnk txt-lnk-underline reverse" 
                            onClick={() => onClickBtnA(sessionUNID)}
                            disabled={btnDisabled}>
                        {ctaBtnTxtA}
                    </button>
                }
                <button className={'btn-pill border-4 btn-red shadow-lgt' + ( curFetching === 'userexam/session' && btnClicked === 2 ? ' fetching pad-left-20 pad-right-10' : '' )} 
                        onClick={() => onClickBtnB(sessionUNID, selStudyWeeks, studyTypesArr)}
                        disabled={btnDisabled}>
                    <span className="spinner"><span></span></span>{ctaBtnTxtB}
                </button>
            </div>
            {/* Period CTA: STOP */}
            
        </>
    );
    
    
};

export default ModalContentExamPeriod;


/*** Scripts end... */

/*
 * A collection of useful functions globally accessible to the app.
 */


// Open an external link in the browser.
export const openExtLink = (url, newTab) => {
        
    if ( typeof newTab === 'undefined' ) newTab = false;
    
    if ( newTab ) window.open(url);
    else window.location.assign(url);
    
};

// Define the current & next 11 months based on user location.
export const defineMonths = (isStr) => {
    
    const date          = new Date();
    const curMonthIdx   = date.getMonth();
    
    let monthsArr       = [];
    
    if ( isStr )    monthsArr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    else            monthsArr = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    
    // Rearrange monthsArr dependent on current month.
    const passedArr     = monthsArr.filter((item, idx) => idx < curMonthIdx);
    const ongoingArr    = monthsArr.filter((item, idx) => idx >= curMonthIdx);
    
    return [...ongoingArr, ...passedArr];
    
};

// Define the current & next 11 months based on user location with matching year.
export const defineMonthsYears = (isStr) => {
    
    const date          = new Date();
    const curMonthIdx   = date.getMonth();
    const curYearNum    = date.getFullYear();
    
    let monthsArr       = [
        { month: '01', year: ''},
        { month: '02', year: ''},
        { month: '03', year: ''},
        { month: '04', year: ''},
        { month: '05', year: ''},
        { month: '06', year: ''},
        { month: '07', year: ''},
        { month: '08', year: ''},
        { month: '09', year: ''},
        { month: '10', year: ''},
        { month: '11', year: ''},
        { month: '12', year: ''}
    ];
    
    // Rearrange monthsArr dependent on current month.
    const passedArr     = monthsArr.filter((item, idx) => idx < curMonthIdx);
    const ongoingArr    = monthsArr.filter((item, idx) => idx >= curMonthIdx);
    
    // Inject the current year + 1 as this month will now appear next year.
    passedArr.forEach(item => {
        
        item.year = (curYearNum + 1).toString();
        
    });
    
    // Inject the current year as this month hasn't passed yet so is this year.
    ongoingArr.forEach(item => {
        
        item.year = (curYearNum).toString();
        
    });
    
    return [...ongoingArr, ...passedArr];
    
};

// Return month name based on month number.
export const getMonthLongName = (ukDate) => {
    
    const dateArr   = ukDate.split('/');
    const usDate    = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2]
    const tmpDate   = new Date(usDate);
    
    return tmpDate.toLocaleString('default', { month: 'long' });
    
};

// Get current year.
export const defineYear = (rowMonth) => {
    
    const date          = new Date();
    const curMonthIdx   = date.getMonth();
    const monthsArr     = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const rowMonthIdx   = monthsArr.indexOf(rowMonth);
    
    let curYearNum    = date.getFullYear();
    
    // If the 'rowMonth' has already passed in the current year
    // then we will be displaying that month for the next year
    // so add 1 to the current year.
    if ( rowMonthIdx < curMonthIdx ) curYearNum++;
    
    return curYearNum;
    
};

// Get matching date suffix, i.e. 'st' for 1st, 'nd' for 2nd, etc.
export const getDateSuffix = (val) => {
    
    switch (val) {
        case 1:
        case 21:
        case 31:
            return 'st';
        case 2:
        case 22:
            return 'nd';
        case 3:
        case 23:
            return 'rd';
        default:
            return 'th';
    }
    
};

export const getSince1970 = (units) => {
        
    // Return the current number of months/weeks since computer date staretd on 01/01/1970.
    const curDate           = new Date();
    const misSince1970      = curDate.getTime();
    
    if ( units === 'mn' ) return Math.round(misSince1970 / 2629800000);
    else if ( units === 'wk' ) return Math.round(misSince1970 / 604800000);
    else return Math.round(misSince1970 / 86400000);
    
};

export const getSessionDate = (sessionUNID, examsSessionData, type) => {
    
    const monthArr      = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthShoArr  = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const session       = examsSessionData.find(item => item.UNID === sessionUNID);
    const dateObj       = new Date(session.date);

    if ( type === 'short' ) return monthShoArr[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
    else return monthArr[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
    
};

export const getSessionWeek = (sessionUNID, examsSessionData, units) => {
    
    // Return the exam week number since computer date started on 01/01/1970.
    const session           = examsSessionData.find(item => item.UNID === sessionUNID);
    
    // 'session' may be undefined because we can't find an examSession with a UNID that matches 
    // the usergiven userExam examSessionUNID. This could happen if the session took place
    // in a different year to the one currently displayed on the planner.
    if ( typeof session === 'undefined' ) return null;
    
    const dateObj           = new Date(session.date);
    const misSince1970      = dateObj.getTime();

    if ( units === 'mn' ) return Math.round(misSince1970 / 2629800000);
    else if ( units === 'wk' ) return Math.round(misSince1970 / 604800000);
    else return Math.round(misSince1970 / 86400000);
    
};


/*** Scripts end... */

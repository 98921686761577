import axios from 'axios';

import { apiUrlPublic,
         apiUrlPrivate } from '../../authConfig';


/*
 * Global methods to call public & private server API's.
 */


// GET: '/api/public' data.
export const getDataPublic = async endPoint => {
    
    try {
        
        const apiUrl        = apiUrlPublic + endPoint;
        const apiResponse   = await axios.get(apiUrl);
        
        // HTTP SUCCESS.
        if ( apiResponse && apiResponse.status === 200 ) {
            
            // Server SUCCESS.
            if ( apiResponse.data.errorCode === 0 ) return { status: 'SUCCESS', data: apiResponse.data.payload };
            
            // Server ERROR.
            else return { status: 'ERROR', data: { type: 'server', code: apiResponse.data.errorCode, description: apiResponse.data.errorDescription } };
            
        }
        
        // HTTP ERROR.
        else return { status: 'ERROR', data: { type: 'http', code: apiResponse.status, description: apiResponse.statusText } }
 
    }
    catch ( error ) {
        
        // HTTP ERROR.
        return { status: 'ERROR', data: { type: 'http', code: error.response.status, description: error.response.statusText } }
        
    } 
    
};

// GET: '/api/private' data.
export const getDataPrivate = async (endPoint, bearerToken) => {
    
    try {
        
        const apiUrl        = apiUrlPrivate + endPoint;
        const apiResponse   = await axios.get(apiUrl, { headers: { 'Authorization': bearerToken } });
        
        // HTTP SUCCESS.
        if ( apiResponse && apiResponse.status === 200 ) {
            
            // Server SUCCESS.
            if ( apiResponse.data.errorCode === 0 ) return { status: 'SUCCESS', data: apiResponse.data.payload };
            
            // Server ERROR.
            else return { status: 'ERROR', data: { type: 'server', code: apiResponse.data.errorCode, description: apiResponse.data.errorDescription } };
            
        }
        
        // HTTP ERROR.
        else return { status: 'ERROR', data: { type: 'http', code: apiResponse.status, description: apiResponse.statusText } }
  
    }
    catch (error) {
        
        // HTTP ERROR.
        return { status: 'ERROR', data: { type: 'http', code: error.response.status, description: error.response.statusText } }
        
    }
    
};

// POST: '/api/private' data.
// This API returns a payload, check payload for success status.
export const postDataPrivate = async (endPoint, requestObj, bearerToken) => {
    
    try {
        
        const apiUrl        = apiUrlPrivate + endPoint;
        const apiResponse   = await axios.post(apiUrl, requestObj, { headers: { 'Authorization': bearerToken } });
        
        // HTTP SUCCESS.
        if ( apiResponse && apiResponse.status === 200 ) {
                
            // Server SUCCESS.
            if ( apiResponse.data.errorCode === 0 ) return { status: 'SUCCESS', data: apiResponse.data.payload };
            
            // Server ERROR.
            else return { status: 'ERROR', data: { type: 'server', code: apiResponse.data.errorCode, description: apiResponse.data.errorDescription } };
 
        }
        
        // HTTP ERROR.
        else return { status: 'ERROR', data: { type: 'http', code: apiResponse.status, description: apiResponse.statusText } }
 
    }
    catch (error) {
        
        // HTTP ERROR.
        return { status: 'ERROR', data: { type: 'http', code: error.response.status, description: error.response.statusText } }
        
    }
    
};

// POST: '/api/private' data.
// This API will not return a payload, if response status is 200 then it is a success.
export const postDataNoPayloadPrivate = async (endPoint, requestObj, bearerToken) => {
    
    try {
        
        const apiUrl        = apiUrlPrivate + endPoint;
        const apiResponse   = await axios.post(apiUrl, requestObj, { headers: { 'Authorization': bearerToken } });
        
        // HTTP SUCCESS.
        if ( apiResponse && apiResponse.status === 200 ) {
            
            return { status: 'SUCCESS' };
 
        }
        
        // HTTP ERROR.
        else return { status: 'ERROR', data: { type: 'http', code: apiResponse.status, description: apiResponse.statusText } }
 
    }
    catch (error) {
        
        // HTTP ERROR.
        return { status: 'ERROR', data: { type: 'http', code: error.response.status, description: error.response.statusText } }
        
    }
    
};

// PUT: '/api/private' data.
// This API will not return a payload, if response status is 200 then it is a success.
export const putDataNoPayloadPrivate = async (endPoint, requestObj, bearerToken) => {
    
    try {
        
        const apiUrl        = apiUrlPrivate + endPoint;
        const apiResponse   = await axios.put(apiUrl, requestObj, { headers: { 'Authorization': bearerToken } });
        
        // HTTP SUCCESS.
        if ( apiResponse && apiResponse.status === 200 ) {
            
            return { status: 'SUCCESS' };
 
        }
        
        // HTTP ERROR.
        else return { status: 'ERROR', data: { type: 'http', code: apiResponse.status, description: apiResponse.statusText } }
 
    }
    catch (error) {
        
        // HTTP ERROR.
        return { status: 'ERROR', data: { type: 'http', code: error.response.status, description: error.response.statusText } }
        
    }
    
};


/*** Scripts end... */
import React, { useState, useEffect } from 'react';

import { openExtLink } from '../hooks/utilityFunctions';


/*
 * This AppFooter component only shows on desktop & contains 
 * the ACCA copyright text.
 */
const AppFooter = () => {
    
    
    // Component state.
    const [isLit, setIsLit] = useState(false);
    
    
    /*
     * Component methods.
     */
    
    const toggleTxtHighlight = () => {
        
        setIsLit(!isLit);
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="app-footer cntr-txt">
            
            <div className="dim-txt">
                © ACCA 2022
                <span className="mgn-left-10 mgn-right-10">|</span>
                <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/student.html', true)}>ACCA Student resources</span>
                <span className="mgn-left-10 mgn-right-10">|</span>
                <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/privacy/data-protection.html', true)}>Privacy</span>
                <span className="mgn-left-10 mgn-right-10">|</span>
                <span className="txt-lnk-highlight txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/contact-us.html', true)}>Contact us</span>
            </div>
            
        </div>
    );
    
    
};

export default AppFooter;


/*** Scripts end... */

import React, { useContext, useRef, useEffect} from 'react';

import AppContext from '../../context/app/AppContext';
import Planner from './Planner';
import MobileIntro from './MobileIntro';
import MobileAction from './MobileAction';


/*
 * This AppBody component appears on both desktop & mobile
 * & displays the interactive planner. For mobile only it will also
 * display the intro screen & snapshot/help text screen.
 */
const AppBody = () => {
    
    
    // Reference Context api's
    const appContext                    = useContext(AppContext);
    
    // Destructure Context api's.
    const { displayType,
            screenshotStatus,
            scrollToPlanner,
            setScrollToPlanner }        = appContext;
    
    // Element reference hooks.
    const refMobScroll                  = useRef(null);
    
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const drawForDesktop = () => (
        <Planner />
    );
    
    const drawForMobile = () => (
        <>
            { !screenshotStatus && <MobileIntro /> }
            <div ref={refMobScroll} className="mob-planner-wrapper">
                <Planner />
            </div>
            { !screenshotStatus && <MobileAction /> }
        </>
    );
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //... 
        if ( scrollToPlanner && 
             refMobScroll.current !== null ) {
                 
            refMobScroll.current.scrollIntoView({ behavior: 'smooth' });
            setScrollToPlanner(false);
                
        }
        
    // eslint-disable-next-line 
    }, [scrollToPlanner]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="app-body">
            
            <div className={ 'planner-wrapper' + ( displayType === 'mob' ? ' flex-wrapper-col' : '' )}>

                { displayType === 'mob' ? drawForMobile() : drawForDesktop() }
                
            </div>
            
        </div>
    );
    
    
};

export default AppBody;


/*** Scripts end... */

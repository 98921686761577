import React, { useContext, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import LayerExams from './LayerExams';
import LayerCalendar from './LayerCalendar';
import LayerCalendarSticky from './LayerCalendarSticky';

import { IconReset } from '../hooks/ImageAssets';
import ChevronMidBkgrndB from '../../assets/img/chevron-mid-bkgrnd-b.png';


/*
 * This Planner component only appears on both desktop & mobile
 * & dislays the interactive exam planner, including section titles & help text
 */
const Planner = () => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    
    // Destructure Context api's.
    const { userAuthenticated,
            resetUserExams }                = authenticationContext;
    const { displayType,
            examHeaderHeight }              = appContext;
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className={ 'planner' + ( displayType === 'dsk' ? ' flex-wrapper-center' : '' )}>

            <div className="content">
                
                {/* Reset button */}
                { !userAuthenticated
                &&
                    <div className="btn-reset"
                         style={{ height: examHeaderHeight + 'px' }}
                         data-html2canvas-ignore>
                        <button className="content"
                                onClick={() => resetUserExams()}>
                            <IconReset />
                            <span className="dim-txt">Start over</span>
                        </button>
                        <div className="chevron-img"><img src={ChevronMidBkgrndB} alt="" /></div>
                    </div>
                }
                
                {/* Sticky calendar - Mobile only */}
                { displayType === 'mob' ? <LayerCalendarSticky /> : null }
                
                {/* Exams header, sticks & footer */}
                <LayerExams />
                
                {/* Calendar */}
                <LayerCalendar />
            </div>
            
        </div>
    );
    
    
};

export default Planner;


/*** Scripts end... */

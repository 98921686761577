import { useState, useEffect } from 'react';


const useWindowDimensions = () => {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
      
    const winWidth = hasWindow ? window.innerWidth : null;
    const winHeight = hasWindow ? window.innerHeight : null;
    
    return {
      winWidth,
      winHeight,
    };
    
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      
    if (hasWindow) {
        
      function handleResize() {
          
        setWindowDimensions(getWindowDimensions());
        
      }

      window.addEventListener('resize', handleResize);
      
      return () => window.removeEventListener('resize', handleResize);
      
    }
    
    // The eslint comment below removes missing dependency warning from console.
    // eslint-disable-next-line
  }, [hasWindow]);

  return windowDimensions;
  
}

export default useWindowDimensions;


/*** Scripts end... */

import React, { useContext, useRef, useState, useEffect } from 'react';

import AppContext from '../../context/app/AppContext';
import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ModalContext from '../../context/modal/ModalContext';
import { getSince1970,
         getSessionWeek } from '../hooks/utilityFunctions';


/*
 * This ExamCell component displays the exam button & countdown leading up to that exam.
 */
const ExamCell = ({ monthObj, eventsArr, examData, examStatus, dimBtns, onClickExamEvent, onClickGotoStudyPlanner, onClickExamTimeline }) => {
    
    
    // Reference Context api's. 
    const appContext                        = useContext(AppContext);
    const authenticationContext             = useContext(AuthenticationContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { staticsData }                   = appContext;
    const { userAuthenticated,
            userExamData,
            examsSessionData,
            curFetching }                   = authenticationContext;
    const { isVisible }                     = modalContext;
    
    // Element reference hooks.
    const cellRef                           = useRef(null);
    const timelineRef                       = useRef(null);
            
    // Component state.
    const [eventObj, setEventObj]           = useState(null);
    const [btnDisabled, setBtnDisabled]     = useState(true);
    const [btnPlanActive, setBtnPlanActive] = useState(false);
    const [hideTimeline, setHideTimeline]   = useState(false);
    const [timeoutPlan, setTimeoutPlan]     = useState(false);

    
    /*
     * Component methods.
     */
    
    const drawButton = () => {
        
        const userExam          = userExamData.find(item => item.examUNID === examData.UNID);
        const matchingEvent     = staticsData.examType.find(item => item.ID === examData.type);
        const typeOnDemandExam  = staticsData.examType.find(item => item.ID === 1);
        const typeExam          = staticsData.examType.find(item => item.ID === 2);
        const typeEPSExam       = staticsData.examType.find(item => item.ID === 3);
        const btnPlanObj        = staticsData.callToActionButtons.find(item => item.ID === 1)
        const cellHeight        = cellRef.current.clientHeight;
        const timelinePos       = cellHeight - 1;
        const btnDimDisable     = ( userAuthenticated && typeof userExam !== 'undefined' && userExam.studyPeriodLength > 0 ) ? ' btn-dim btn-disable' : ' btn-dim';
        const noTime            = ( typeof userExam !== 'undefined' && userExam.examSessionUNID !== null && examData.type !== 3 ) ? noStudyTime(userExam.examSessionUNID) : false;
        
        // Event type can be 'BOOK', 'EXAM' or 'ONDEMANDEXAM'.
        return (
            <>
            
                {/* Exam timeline: START */}
                <div className={'timeline' + ( btnDisabled ? ' disabled' : '' ) + ( hideTimeline ? ' hide' : '' )}
                     style={{ bottom: timelinePos + 'px' }}
                     onClick={() => onClickTimeline(eventObj.UNID)}>
                    <div className="timeline-placer">
                        <div ref={timelineRef} 
                             className="timeline-bars"></div>
                    </div> 
                </div>
                {/* Exam timeline: STOP */}
                
                {/* Exam/Plan buttons: START */}
                { examData.type === typeExam.ID && 
                    <>
                        { userExam && userExam.examSessionUNID === eventObj.UNID && userExam.studyPeriodLength > 0 ?
                            <>
                                { curFetching === 'userexam/session' && btnPlanActive ?
                                    <div className="spinner-plan"
                                         data-event-id={eventObj.UNID}>
                                        <span className="spinner"><span></span></span>
                                    </div>
                                :
                                    <button className={'btn-exam' + ( cssHighlight(eventObj.UNID) ? ' highlight' : '' ) + ( cssDim(eventObj.UNID) ? ' btn-dim' : '' )}
                                            style={{ backgroundColor: 'rgb(' + btnPlanObj.color + ')' }}
                                            data-event-id={eventObj.UNID}
                                            onClick={onClickBtnPlan}
                                            disabled={btnDisabled}>
                                        {btnPlanObj.label}
                                    </button>
                                }
                            </>
                        :
                        <button className={'btn-exam' + ( cssHighlight(eventObj.UNID) ? ' highlight' : '' ) + ( cssDim(eventObj.UNID) ? btnDimDisable : '' )}
                                style={{ backgroundColor: 'rgb(' + matchingEvent.color + ')' }}
                                data-event-id={eventObj.UNID}
                                onClick={() => onClickExamEvent(eventObj.UNID)}
                                disabled={btnDisabled}>
                            { userExam && userExam.examSessionUNID === eventObj.UNID && !noTime ? btnPlanObj.label : matchingEvent.label }
                        </button>
                        }
                    </>
                }
                { examData.type === typeOnDemandExam.ID && 
                    <>
                        { userExam && userExam.examSessionUNID === eventObj.UNID && userExam.studyPeriodLength > 0 ?
                            <>
                                { curFetching === 'userexam/session' && btnPlanActive ?
                                    <div className="spinner-plan"
                                         data-event-id={eventObj.UNID}>
                                        <span className="spinner"><span></span></span>
                                    </div>
                                :
                                    <button className={'btn-demand-exam' + ( cssHighlight(eventObj.UNID) ? ' highlight' : '' ) + ( cssDim(eventObj.UNID) ? ' btn-dim' : '' )}
                                            style={{ backgroundColor: 'rgb(' + btnPlanObj.color + ')' }}
                                            data-event-id={eventObj.UNID}
                                            onClick={onClickBtnPlan}
                                            disabled={btnDisabled}>
                                        {btnPlanObj.label}
                                    </button> 
                                }
                            </>
                        :
                            <button className={'btn-demand-exam' + ( cssHighlight(eventObj.UNID) ? ' highlight' : '' ) + ( cssDim(eventObj.UNID) ? btnDimDisable : '' )}
                                    style={{ backgroundColor: 'rgb(' + matchingEvent.color + ')' }}
                                    data-event-id={eventObj.UNID}
                                    onClick={() => onClickExamEvent(eventObj.UNID)}
                                    disabled={btnDisabled}>
                                { userExam && userExam.examSessionUNID === eventObj.UNID && !noTime ? btnPlanObj.label : matchingEvent.label }
                            </button>
                        }
                    </>
                }
                { examData.type === typeEPSExam.ID && 
                    <button className={'btn-epsm-exam' + ( cssHighlight(eventObj.UNID) ? ' highlight' : '' ) + ( cssDim(eventObj.UNID) ? ' btn-dim' : '' )}
                            style={{ backgroundColor: 'rgb(' + matchingEvent.color + ')' }}
                            data-event-id={eventObj.UNID}
                            onClick={() => onClickExamEvent(eventObj.UNID)}
                            disabled={btnDisabled}>
                        {matchingEvent.label}
                    </button> 
                }
                {/* Event button: STOP */}
            
            </>
        );
        
    };
    
    const cssHighlight = sessionUNID => {
        
        const session = userExamData.find(item => item.examSessionUNID === sessionUNID);
        
        if ( typeof session !== 'undefined' ) return true;
        else return false;
        
    };
    
    const cssDim = sessionUNID => {
        
        const session = userExamData.find(item => item.examSessionUNID === sessionUNID);
        
        if ( dimBtns && typeof session === 'undefined' ) return true;
        else return false;
        
    };
    
    const drawTimeline = userExam => {
        
        // If userExam status is 1/exempt or 2/passed there is no timeline so bail.
        if ( userExam.statusType === 1 || userExam.statusType === 2 ) return;
        
        // Get height of a cell & a bar in the cell.
        const cellHeight        = cellRef.current.clientHeight;
        const barHeight         = Math.round( ( cellHeight + 2 ) / 4 ); 
        const planning          = staticsData.studyActivityType.find(item => item.ID === 2);
        const learning          = staticsData.studyActivityType.find(item => item.ID === 3);
        const revision          = staticsData.studyActivityType.find(item => item.ID === 4);
        const planningPeriod    = getStudyPeriods('planning');
        const learningPeriod    = getStudyPeriods('learning');
        const revisionPeriod    = getStudyPeriods('revision');
        const weeksUntil        = getWeeksUntil(userExam.examSessionUNID);
        const periodArr         = [];
        
        // Guard against a null value in 'weeksUntil', if so then bail.
        if ( weeksUntil === null || weeksUntil < 1 ) return;
        
        // Populate array with period types.
        for ( let i = 0; i < planningPeriod; i++ ) { periodArr.push('planning'); }
        for ( let i = 0; i < learningPeriod; i++ ) { periodArr.push('learning'); }
        for ( let i = 0; i < revisionPeriod; i++ ) { periodArr.push('revision'); }
        
        // Trim array if weeksUntil is less than length of array.
        let trimmedArr          = periodArr.slice(-weeksUntil);
        let barType             = planning;
        let htmlStr             = '';
        
        for ( let i = 0; i < trimmedArr.length; i++ ) {
            
            if ( trimmedArr[i] === 'planning' )         barType = planning;
            else if ( trimmedArr[i] === 'learning' )    barType = learning;
            else if ( trimmedArr[i] === 'revision' )    barType = revision;
            
            htmlStr += `<div class="study-bar" style="height: ${barHeight}px; background-color: rgb(${barType.color})"></div>`;
            
        }

        // Insert the html into the timeline element.
        timelineRef.current.innerHTML = htmlStr;
        
    };
    
    const getStudyPeriods = activity => {
        
        const userExam          = userExamData.find(item => item.examUNID === examData.UNID);
        const studyData         = staticsData.sessionStudyPeriods.find(item => item.ID === examData.type);
        const studyType         = studyData[userExam.statusType === 3 ? 'take' : 'retake'];
        const studyPeriod       = studyType.studyPeriod.find(item => item.weeksUntil === userExam.studyPeriodLength);
        const lastStudyPeriod   = studyType.studyPeriod[studyType.studyPeriod.length - 1];
        const revisionWeeks     = typeof studyPeriod === 'undefined' && studyType.exceedMax ? lastStudyPeriod.revisionWeeks : studyPeriod.revisionWeeks;
        const planningWeeks     = ( userExam.studyPeriodLength - revisionWeeks ) > 1 ? 1 : 0;
        
        if ( activity === 'planning' ) return planningWeeks;
        else if ( activity === 'learning' ) return userExam.studyPeriodLength - ( revisionWeeks + planningWeeks );
        else return revisionWeeks;
 
    };
    
    const noStudyTime = sessionUNID => {
        
        // Set the number of weeks until the event, if the current month has already passed in the year do something...
        const weeksUntil        = getWeeksUntil(sessionUNID);
        
        // Get the Study Period data that matches this userExam (Exam type: 1/ondemandExam, 2/exam & exam StatusType: 3/taking, 4/retaking).
        const examUser          = userExamData.find(item => item.examUNID === examData.UNID);
        const statusKey         = examUser.statusType === 3 ? 'take' : 'retake';
        const studyPeriods      = staticsData.sessionStudyPeriods.find(item => item.ID === examData.type);
        const studyPeriod       = studyPeriods[statusKey];
        
        // If 'weeksUntil' is null then return false to noStudyTime().
        if ( weeksUntil === null ) return false;
        
        // Else check if the number of weeks until the event is less than the number of min study weeks for the event & return.
        else return ( weeksUntil - studyPeriod.minWeeks ) < 0;
        
    };
    
    const getWeeksUntil = sessionUNID => {
        
        // Get the Current day number & Session day number (should be first day of the month).
        const curDayNum         = getSince1970('dy');
        const sessionDayNum     = getSessionWeek(sessionUNID, examsSessionData, 'dy');
        
        if ( sessionDayNum === null ) return null;
        else return Math.round((sessionDayNum - curDayNum) / 7);
        
    };
    
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickBtnPlan = () => {
        
        if ( userAuthenticated ) setTimeoutPlan(true);
        
        onClickGotoStudyPlanner();
        
    };
    
    const onClickTimeline = sessionUNID => {
        
        if ( userAuthenticated ) setTimeoutPlan(true);
        
        onClickExamTimeline(sessionUNID);
        
    };
    
    
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // If this month cell has a matching exam for this month & year, then draw a button.
        eventsArr.forEach(item => {
            
            if ( item.date !== '' ) {
                
                // Convert JSON ISO date to a date object.
                const dateObj       = new Date(item.date);
                const sessionMonth  = dateObj.getMonth() + 1;
                const sessionYear   = dateObj.getFullYear();
            
                if ( sessionMonth === parseInt(monthObj.month) && 
                     sessionYear === parseInt(monthObj.year) ) setEventObj(item);
                
            }

        });

    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        // Check that we have an eventObj.
        if ( eventObj !== null ) {
        
            // If we have a matching 'userExam' & Session & 'studyPeriodLength' draw a timeline.
            const userExam = userExamData.find(item => item.examUNID === examData.UNID);
            
            if ( typeof userExam !== 'undefined' && 
                 userExam.statusType !== 0 &&
                 userExam.examSessionUNID === eventObj.UNID && 
                 userExam.studyPeriodLength > 0 ) drawTimeline(userExam);
                    
        }

    // eslint-disable-next-line 
    }, [eventObj]);
    
    useEffect(() => {
        
        // Check that we have an eventObj.
        if ( eventObj !== null ) {
            
            // Check that we have a matching userExam.
            const userExam = userExamData.find(item => item.examUNID === examData.UNID);
           
            // If 'userExamData' changes & the one that's changed matches this one update the timeline.
            if ( typeof userExam !== 'undefined' && 
                 userExam.statusType !== 0 ) {
                
                if ( userExam.examSessionUNID === eventObj.UNID &&
                     userExam.studyPeriodLength > 0 ) drawTimeline(userExam);
                else timelineRef.current.innerHTML = '';
                
            }
            // Else clear any timeline.
            else timelineRef.current.innerHTML = '';
            
        }

    // eslint-disable-next-line 
    }, [userExamData]);

    useEffect(() => {
        
        // If examStatus equals 1/exempt or 2/passed then hide the timeline
        // otherwise it can sometimes be seen when the exam stick is collapsed.
        if ( examStatus === 1 || examStatus === 2 ) setHideTimeline(true);
        else setHideTimeline(false);
        
        
    // eslint-disable-next-line 
    }, [examStatus]);
    
    useEffect(() => {
        
        // If we have a modal open then no need to disable the btns below it.
        if ( userAuthenticated && isVisible ) return;
        
        setBtnDisabled(curFetching === '' ? false : true);
        
        if ( curFetching === '' ) setBtnPlanActive(false);
        
    // eslint-disable-next-line 
    }, [curFetching]);
    
    useEffect(() => {
        
        let timer = null;
        
        // Trigger a Timeout for the Plan button spinner.
        if ( timeoutPlan ) {
            
            setTimeoutPlan(false);
            
            timer = setTimeout(() => { setBtnPlanActive(true) }, 100);
            
        }
        
        // When component unmounts, clear any timeouts.
        return () => {
            
            if ( timer !== null ) clearTimeout(timer);
            
        };
        
    // eslint-disable-next-line 
    }, [timeoutPlan]);
    
    
     
     
    /*
     * Component view.
     */
    
    return (
        <div ref={cellRef} 
             className="exam-cell">
            
            { eventObj !== null ? drawButton() : null}
            
        </div>
    );
    
    
};

export default ExamCell;


/*** Scripts end... */

import React, { useContext, useRef, useState, useEffect} from 'react';
import html2canvas from 'html2canvas';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import AppBody from '../layout/AppBody';
import AppHeader from '../layout/AppHeader';
import AppFooter from '../layout/AppFooter';
import TmplInsModal from '../modals/TmplInsModal';
import ModalContentLogin from '../modals/ModalContentLogin';
import ModalContentPromptLogin from '../modals/ModalContentPromptLogin';
import { IconTick } from '../hooks/ImageAssets';


/*
 * This Home component contains AppHeader, AppBody & AppFooter layout components.
 */
const Home = () => {
    
    
    // Local non-state variables.
    let selectedExams                       = [];
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { userAuthenticated,
            examsData,
            examsCompleted,
            updateCompletedArr }           = authenticationContext;
    const { isRedirect,
            displayType,
            isIntro,
            isInitialLoad,
            setIsIntro,
            screenshotStatus,
            setScreenshotTgt,
            setScreenshotStatus,
            modalFtrTxt,
            showChkboxModal,
            toggleCheckboxModal }           = appContext;
    const { showModal,
            hideModal }                     = modalContext;
    
    // Define some DOM reference hooks.
    const tgtRef                            = useRef(null); 
    
    // Component state.
    const [isHide, setIsHide]               = useState(true);
    
    // Check sessionStorage to see whether we have been forcibly logged out & should show
    // a login prompt when the site reloads.
    const showLoginVal                      = sessionStorage.getItem("show_login") ? true : false;
    const statusCodeVal                     = sessionStorage.getItem("status_code");
    
    

    /*
     * Component methods.
     */
    
    const updateSelectedExams = (event, examsArr) => {
        
        // If status is checked ADD exams to local 'selectedExams' array.
        if ( event.target.checked ) selectedExams = [...selectedExams, ...examsArr];
        
        // Else REMOVE exams from local 'selectedExams' array
        else selectedExams = [...selectedExams.filter(itemA => !examsArr.find(itemB => itemB.UNID === itemA.UNID))]
        
    };
    
    const checkExamsCompleted = examsArr => {
        
        for ( let i = 0; i < examsArr.length; i++ ) {
            
            const matchingArr = examsCompleted.filter(itemA => examsArr.find(itemB => itemB.UNID === itemA.UNID));

            if ( typeof matchingArr === 'undefined' ) return false;
            
            if ( matchingArr.length > 0 ) return true;
            else return false;
            
        }
        
    };
    
    // Return a line break if device is desktop.
    const addBreakOnDskTop = () => {
        
        if ( displayType === 'dsk' ) return <br />;
        else return '';
        
    };
    
    const showModalStep1 = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => {
            return (
                <div className="modal-body ">
                    <ModalContentLogin cusAction={showModalStep2} />
                </div>
            );
        };
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
    const showModalStep2 = () => {
        
        if ( examsData !== null ) {
            
            // Sync local 'selectedExams' with context state 'examsCompleted'.
            selectedExams = examsCompleted;

            // Group Exams by Section.
            const arrayAK = examsData.filter(item => item.sectionType === 1);
            const arrayAS = examsData.filter(item => item.sectionType === 2);
            const arrayEM = examsData.filter(item => item.sectionType === 3);
            const arraySP = examsData.filter(item => item.sectionType === 4);
            
            const completedAK = checkExamsCompleted(arrayAK);
            const completedAS = checkExamsCompleted(arrayAS);
            const completedEM = checkExamsCompleted(arrayEM);
            const completedSP = checkExamsCompleted(arraySP);
            
            // Instructional - define modal content.
            const txtTitleA     = 'Your ACCA Study planner';
            const htmlBody      = () => {
                return (
                    <div className="modal-body">
                        
                        <h2>Mark any exam levels already completed:</h2>
                        
                        <div className="modal-checkboxes">
                            
                            <label className="cus-chkbox">
                                <input type="checkbox" onChange={event => updateSelectedExams(event, arrayAK)} defaultChecked={completedAK} />
                                <span className="checkmark">{IconTick()}</span>
                                <span className="label">Applied Knowledge</span>
                            </label>
                            <label className="cus-chkbox">
                                <input type="checkbox" onChange={event => updateSelectedExams(event, arrayAS)} defaultChecked={completedAS} />
                                <span className="checkmark">{IconTick()}</span>
                                <span className="label">Applied Skills</span>
                            </label>
                            <label className="cus-chkbox">
                                <input type="checkbox" onChange={event => updateSelectedExams(event, arrayEM)} defaultChecked={completedEM} />
                                <span className="checkmark">{IconTick()}</span>
                                <span className="label">EPSM</span>
                            </label>
                            <label className="cus-chkbox">
                                <input type="checkbox" onChange={event => updateSelectedExams(event, arraySP)} defaultChecked={completedSP} />
                                <span className="checkmark">{IconTick()}</span>
                                <span className="label">Strategic Professional</span>
                            </label>
                            
                        </div>
                        
                        <div className="cta-btns flex-wrapper-center">
                            <button className="btn-pill btn-red" onClick={onClickUpdateExams}>Done</button>
                        </div>

                    </div>
                );
            };
            
            // Instructional - initialize modal.
            const props = {
                status  : true,
                props   : {
                    type                : 1,
                    size                : 'small',
                    closeOnClickModal   : false,
                    content             : <TmplInsModal txtTitleA={txtTitleA} 
                                                        txtFooter={modalFtrTxt}>{htmlBody()}</TmplInsModal>
                }
            };
            
            // Instructional - show modal.
            showModal(props);
        
        }
        
    };
    
    const showModalStep3 = () => {

        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    
                    <h2>Tap an exam and set it as exempt, {addBreakOnDskTop()} passed, taking or retaking</h2>
                    <h2>Plan out the exams you will be taking {addBreakOnDskTop()} over the next 12 months</h2>
                    <h2>Select a study period and build your plan</h2>
                    
                    <div className="cta-btns flex-wrapper-center">
                        <button className="btn-pill btn-red" onClick={showPlanner}>Got it</button>
                    </div>

                </div>
            );
        };
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} 
                                                    txtFooter={modalFtrTxt}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };  
    
    const screenshotSuccessModal = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        let ftrText         = 'This tool is designed for general planning purposes, for detailed information on progression rules and exam availability please refer to the ACCA website.';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    
                    <h2>A snapshot of your plan has been downloaded to your web browser</h2>
                    <h2>Reflect and act on your plan, use this tool at any time to explore your options and think ahead...</h2>
                    <h4>Good luck!</h4>
                    
                    <div className="cta-btns flex-wrapper-center">
                        <button className="btn-pill btn-red" onClick={() => hideModal()}>Got it</button>
                    </div>

                </div>
            );
        };
        
        // Create the footer text for the system modals.
        if ( displayType === 'dsk' ) ftrText = 'This tool is designed for general planning purposes, for detailed information on progression rules and exam availability please refer to the ACCA website.';
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} 
                                                    txtFooter={ftrText}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
    const onClickUpdateExams = () => {
        
        // Update global context state with completed exams.
        updateCompletedArr(selectedExams);
        
        // If required show next modal in sequence (only needed if following intro checkbox modal).
        if ( isIntro ) showModalStep3();
        
        // Else just close the modal.
        else hideModal();
        
    };
    
    const showPlanner = () => {
        
        hideModal();
        setIsIntro(false);
        
    };
    
    const onCloneDoc = (clonedDoc) => {
        
        clonedDoc.querySelector('.app-root').style.height = 'auto';
        clonedDoc.querySelector('.app-home').style.height = '880px';
        clonedDoc.querySelector('.app-home').style.maxHeight = '880px';
        clonedDoc.querySelector('.app-home .app-body').style.overflow = 'visible';
        clonedDoc.querySelector('.app-home .app-body').style.flex = 'none';
        clonedDoc.querySelector('.app-home .planner > .content').style.overflowX = 'visible';
        clonedDoc.querySelector('.app-home .planner > .content').style.overflowY = 'visible';
        clonedDoc.querySelector('.app-home .exams').style.overflowX = 'visible';
        clonedDoc.querySelector('.app-home .calendar').style.minWidth = '1370px';
        clonedDoc.querySelector('.app-home .exams-footer').style.display = 'block';

        clonedDoc.querySelector('.app-home .app-header').style.height = '100px';
        clonedDoc.querySelector('.app-home .exams-header').style.height = '95px';
        clonedDoc.querySelector('.app-home .exams-header').style.maxHeight = '95px';
        clonedDoc.querySelector('.app-home .exams-body').style.height = '556px';
        clonedDoc.querySelector('.app-home .exams-footer').style.height = '90px';
        
        clonedDoc.querySelectorAll('.app-home .exams-body .exam-header').forEach(el => el.style.minHeight = '0');
        clonedDoc.querySelectorAll('.app-home .exams-body .exam-body').forEach(el => el.style.minHeight = '0');
        clonedDoc.querySelectorAll('.app-home .exams-body .exam-footer').forEach(el => el.style.minHeight = '0');
        
        if ( displayType === 'mob' ) clonedDoc.querySelector('.app-home .mob-planner-wrapper').style.padding = '0';
        if ( displayType === 'mob' ) clonedDoc.querySelector('.app-home .mob-planner-wrapper .planner').style.padding = '0 50px';
        
    }
    
    const takeScreenshot = () => {
        
        const imgWidth      = 1500;
        const imgHeight     = 880;
            
        // Use html2canvas to take a screenshot of the planner.
        html2canvas(tgtRef.current, {
            windowWidth     : imgWidth,
            windowHeight    : imgHeight,
            width           : imgWidth,
            height          : imgHeight,
            // scale           : 4,
            onclone         : (clonedDoc) => onCloneDoc(clonedDoc)
            })
            .then(canvas => {
                
                const dataUrl = canvas.toDataURL('image/png', 1);
                const element = document.createElement('a');

                element.setAttribute('href', dataUrl);
                element.setAttribute('download', 'planner.png');
                element.style.display = 'none';
                
                document.body.appendChild(element);
                
                element.click();
                
                document.body.removeChild(element);
                
                setScreenshotStatus(false);
                screenshotSuccessModal();
                
            });

    };
    
    const promptToLogin = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => <ModalContentPromptLogin statusCodeVal={statusCodeVal} />;
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };

        
        
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        if ( showLoginVal ) {
            
            sessionStorage.removeItem("show_login");
            sessionStorage.removeItem("status_code");
            promptToLogin();
            
        }

    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
          
        // When tgtRef acquires the content element store in 
        // app state for global accessibility.
        if ( tgtRef ) setScreenshotTgt(tgtRef.current);
        
        // Show 1st of 3 instructional modals before the planner is revealed,
        // fade in the home page. Don't show modals if user is authenticated 
        // or this is a redirect from login/logout. 
        if ( !showLoginVal ) {
            
            if ( userAuthenticated || isRedirect ) showPlanner();
            else showModalStep1();
            
        }
        
        // Show the app when we are ready.
        setIsHide(false);

    // eslint-disable-next-line 
    }, [tgtRef]);
    
    useEffect(() => {
        
        // Monitor global state of checkbox modal, open if TRUE.
        if ( showChkboxModal ) {
            
            showModalStep2();
            
            // Reset to closed so it can be opened again in future.
            toggleCheckboxModal(false)
            
        }
        
    // eslint-disable-next-line 
    }, [showChkboxModal]);
    
    useEffect(() => {
        
        // Monitor 'screenshotStatus' if true take a screenshot.
        if ( screenshotStatus ) takeScreenshot();
        
    // eslint-disable-next-line 
    }, [screenshotStatus]);
 

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
        
            {/* On mobile mask out content when taking a screenshot */}
            { ( screenshotStatus && displayType === 'mob' ) && (
                <div className="screenshot-mobile-mask flex-wrapper-center">
                    <div>
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        <h3 className="txt">Taking a snapshot</h3>
                    </div>
                </div>
            ) }
        
            <div ref={tgtRef} 
                    id="app-home"
                    className={ 'app-home flex-wrapper-row' + ( screenshotStatus ? ' grabbing' : '' ) + ( isIntro ? ' intro' : '' ) + ( isInitialLoad ? ' transition' : '' ) + ( isHide ? ' hide' : '' ) }>
                <AppHeader />
                <AppBody />
                <AppFooter />
            </div>
        </>
    );
    
    
};

export default Home;


/*** Scripts end... */

import { TOGGLE_MODAL, 
         UPDATE_MODAL } from './ModalTypes';


const ModalReducer =  (state, action) => {

    switch (action.type) {
        case TOGGLE_MODAL:
            return {
                ...state,
                isVisible   : action.payload.status,
                props       : action.payload.props
            };
        case UPDATE_MODAL:
            return {
                ...state,
                props       : action.payload.props
            };
        default:
            return state;
    };

};

export default ModalReducer;


/*** Scripts end... */

import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';


const ProtectedRoute = ({ component: Component, ...rest }) => {
    
    
    // Reference hooks.
    const history                           = useHistory();
    
    // Reference Context API's. 
    const authenticationContext             = useContext(AuthenticationContext);
    
    // Destructure Context API's. 
    const { userAuthenticated,
            examStudyDaysData }             = authenticationContext;
    
    
    /*
     * Component view.
     */
    
    return <Route { ...rest } render={props => {
        
        switch (userAuthenticated) {
            case true: 
                // If the browser was refreshed and we therefore don't have 
                // any 'examStudyDaysData' redirect the user to the home page.
                return examStudyDaysData === null ? history.push('/') : <Component { ...props } />;
            default:
                history.push('/');
                return null;   
        }
        
    }}/>
    
    
};

export default ProtectedRoute;

/*** Scripts end... */

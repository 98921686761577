import { SET_IS_REDIRECT,
         SET_DISPLAY_TYPE,
         SET_STATICS_DATA,
         SET_IS_INTRO,
         SET_IS_INITIAL_LOAD,
         SET_SCROLL_TO_PLANNER,
         SET_SCREENSHOT_STATUS,
         SET_SCREENSHOT_ENABLE,
         SET_SCREENSHOT_TGT,
         SET_MODAL_FOOTER_TXT,
         SHOW_CHKBOX_MODAL,
         SHOW_PLAN_BTN_MODAL,
         SET_EXAM_HEADER_HEIGHT,
         SET_DOC_CLICKED,
         SET_HAS_TRANSITION } from './AppTypes';


const AppReducer =  (state, action) => {
    
    switch (action.type) {
        case SET_IS_REDIRECT:
            return {
                ...state,
                isRedirect          : action.payload
            };
        case SET_DISPLAY_TYPE:
            return {
                ...state,
                displayType         : action.payload
            };
        case SET_STATICS_DATA:
            return {
                ...state,
                staticsData         : action.payload
            };
        case SET_IS_INTRO:
            return {
                ...state,
                isIntro             : action.payload
            };
        case SET_IS_INITIAL_LOAD:
            return {
                ...state,
                isInitialLoad       : action.payload
            };
        case SET_SCROLL_TO_PLANNER:
            return {
                ...state,
                scrollToPlanner     : action.payload
            };
        case SET_SCREENSHOT_STATUS:
            return {
                ...state,
                screenshotStatus    : action.payload
            };
        case SET_SCREENSHOT_ENABLE:
            return {
                ...state,
                screenshotEnable    : action.payload
            };
        case SET_SCREENSHOT_TGT:
            return {
                ...state,
                screenshotTgt       : action.payload
            };
        case SET_MODAL_FOOTER_TXT:
            return {
                ...state,
                modalFtrTxt         : action.payload
            };
        case SHOW_CHKBOX_MODAL:
            return {
                ...state,
                showChkboxModal     : action.payload
            };
        case SHOW_PLAN_BTN_MODAL:
            return {
                ...state,
                showPlanBtnModal    : action.payload
            };
        case SET_EXAM_HEADER_HEIGHT:
            return {
                ...state,
                examHeaderHeight    : action.payload
            };
        case SET_DOC_CLICKED:
            return {
                ...state,
                docClicked          : action.payload.status,
                docClickedTgt       : action.payload.clickTgt
            };
        case SET_HAS_TRANSITION:
            return {
                ...state,
                hasTransition       : action.payload
            };
        default:
            return state;
    };
    
};

export default AppReducer;


/*** Scripts end... */

import React from 'react';

import AllContexts from './context/AllContexts';
import PreRender from './components/layout/PreRender';
import AppWrapper from './components/layout/AppWrapper';
import ErrorBox from './components/layout/ErrorBox';

import 'element-theme-default';
import './App.css';


const App = () => {

  
  /*
   * Component view.
   */
  
  return (
    <AllContexts>
      <PreRender />
      <AppWrapper />
      <ErrorBox />
    </AllContexts>
  );
  
  
};

export default App;


/*** Scripts end... */

import React, { useContext, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';


/*
 * This ModalContentPromptLogin component displays the HTML for the prompt user login modal.
 * This modal will appear if a user has been logged out but is attempting to call the server.
 */
const ModalContentPromptLogin = ({ statusCodeVal }) => {
    
    
    // Reference Context api's
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { signInUser }                    = authenticationContext;
    const { setIsIntro }                    = appContext;
    const { hideModal }                     = modalContext;
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const onClickContinue = () => {
        
        hideModal();
        setIsIntro(false);
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>    
            <div className="modal-body">  
            
                { statusCodeVal === '403' ? 
                    <>
                        <div className="list-item">You do not have access to the day-to-day <br />Study Planning toolkit</div>
                    </> 
                :
                    <>
                        <div className="list-item">You have been logged out</div>
                        <div className="list-item">To continue planning log in again</div>
                    </> 
                }      
                
                <div className="cta-btns flex-wrapper-center">
                    <button className="btn-pill btn-red" onClick={signInUser}>Log in</button>
                </div>
                
                <div className="cntr-txt pad-top-20">
                     <button className="txt-lnk" 
                             onClick={onClickContinue}>Or continue without logging in</button>
                </div>
                
            </div>
            <div className="modal-footer">
                This tool is designed for general planning purposes, for detailed information on progression rules and exam availability please refer to the ACCA website.
            </div>
        </>
    );
    
    
};

export default ModalContentPromptLogin;


/*** Scripts end... */

import React from 'react';


/*
 * This AppSpinner component displays a spinning white circle,
 * used when the app is loading initial authentication & data.
 */
const AppSpinner = () => {
    
    
    /*
     * Component view.
     */
    
    return (
        <div className="app-root-spinner">
          <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
        </div>
    );
    
    
};

export default AppSpinner;


/*** Scripts end... */

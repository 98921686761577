import React, { useContext, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ModalContext from '../../context/modal/ModalContext';
import { openExtLink } from '../hooks/utilityFunctions';
import useWindowDimensions from '../hooks/getWindowDimensions';


/*
 * This ModalContentLogin component displays the HTML for the Tag Exam modal.
 */
const ModalContentLogin = ({ cusAction }) => {
    
    
    // Reference Context api's
    const authenticationContext             = useContext(AuthenticationContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { signInUser }                    = authenticationContext;
    const { hideModal }                     = modalContext;
    
    // Get window dimensions.
    const { winWidth }                     = useWindowDimensions();
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    const onClickContinue = () => {
        
        // 
        if ( typeof cusAction !== 'undefined' && 
             cusAction !== null ) cusAction();
        
        // Else if there is no custom action just hide the modal.
        else hideModal();
        
    };
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            { winWidth > 360 &&
                <h2 className="list-header">Plan for exam success in the year ahead:</h2>
            }
                        
            <div className="list-item">Schedule and book your exams</div>
            
            { winWidth < 360 ?
                <div className="list-item">Create day-to-day study plans</div>
            :
                <div className="list-item">Add targets and tasks to detailed<br />day-to-day study plans</div>
            }
            
            { winWidth < 360 ?
                <div className="list-item">Check off tasks and reflect!</div>
            :
                <div className="list-item">Check off completed tasks and reflect<br />on your progress</div>
            } 
            
            <div className="modal-cookie-txt dim-txt">  
                We use cookies to personalise your experience, and by using this tool you are consenting to this. <span className="txt-lnk-underline" onClick={() => openExtLink('https://www.accaglobal.com/gb/en/footertoolbar/privacy/cookies-notice.html', true)}>Find out more about ACCA's use of cookies</span>
            </div>
            
            <div className="cta-btns flex-wrapper-center">
                <button className="btn-pill btn-red" onClick={signInUser}>Log in and start planning</button>
            </div>
            
            <div className={ 'cntr-txt' + ( winWidth < 360 ? ' pad-top-10' : ' pad-top-20' ) }>
                <button className="txt-lnk" 
                        onClick={onClickContinue}>Or continue without logging in</button>
            </div>
            
            <div className="modal-footer pad-top-10">
                Only logged in users can save their Exam Plans 
                <br />and use the day-to-day Study Planning toolkit.
            </div>
        </>
    );
    
    
};

export default ModalContentLogin;


/*** Scripts end... */

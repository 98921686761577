import React, { useEffect} from 'react';
import DOMPurify from 'dompurify';

import { ImgQuad } from '../hooks/ImageAssets';


/*
 * This TmplInsModal component is a wrapper template for instructional modals (the blue ones). It creates the generic
 * header & footer content & dislays the body content passed through to it via transclusion.
 */
const TmplInsModal = ({children, txtTitleA, txtFooter}) => {
    
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            
            {/* Quad graphic */}
            <div className="flex-wrapper-row flex-justify-center">
                <div className="quad-wrapper flex-self-center">
                    {ImgQuad()}
                    <span>Com<b>pass</b></span>
                </div>
            </div>
                
            {/* Modal headers */}
            <h1 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(txtTitleA) }} />
            
            {/* Modal body */}
            {children}
            
            {/* Modal footer */}
            { typeof txtFooter !== 'undefined' 
            && 
            <div className="modal-footer" 
                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(txtFooter) }} /> 
            }
                
        </>
    );
    
    
};

export default TmplInsModal;


/*** Scripts end... */

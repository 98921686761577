export const SET_IS_REDIRECT                = 'SET_IS_REDIRECT';
export const SET_DISPLAY_TYPE               = 'SET_DISPLAY_TYPE';
export const SET_STATICS_DATA               = 'SET_STATICS_DATA';
export const SET_USER_AUTHENTICATED         = 'SET_USER_AUTHENTICATED';
export const SET_IS_INTRO                   = 'SET_IS_INTRO';
export const SET_IS_INITIAL_LOAD            = 'SET_IS_INITIAL_LOAD';
export const SET_SCROLL_TO_PLANNER          = 'SET_SCROLL_TO_PLANNER';
export const SET_SCREENSHOT_STATUS          = 'SET_SCREENSHOT_STATUS';
export const SET_SCREENSHOT_ENABLE          = 'SET_SCREENSHOT_ENABLE';
export const SET_SCREENSHOT_TGT             = 'SET_SCREENSHOT_TGT';
export const SET_MODAL_FOOTER_TXT           = 'SET_MODAL_FOOTER_TXT';
export const SHOW_CHKBOX_MODAL              = 'SHOW_CHKBOX_MODAL';
export const SHOW_PLAN_BTN_MODAL            = 'SHOW_PLAN_BTN_MODAL';
export const SET_EXAM_HEADER_HEIGHT         = 'SET_EXAM_HEADER_HEIGHT';
export const SET_DOC_CLICKED                = 'SET_DOC_CLICKED';
export const SET_HAS_TRANSITION             = 'SET_HAS_TRANSITION';


/*** Scripts end... */

import React, { useContext, useRef, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';
import { openExtLink,
         getSince1970,
         getSessionDate,
         getSessionWeek,
         defineMonthsYears } from '../hooks/utilityFunctions';
import useWindowDimensions from '../hooks/getWindowDimensions';
import { IconTickWht } from '../hooks/ImageAssets';
import ExamCell from './ExamCell';
import TmplInsModal from '../modals/TmplInsModal';
import TmplSysModal from '../modals/TmplSysModal';
import ModalContentLogin from '../modals/ModalContentLogin';
import ModalContentTagExam from '../modals/ModalContentTagExam';
import ModalContentExamSelect from '../modals/ModalContentExamSelect';
import ModalContentExamPeriod from '../modals/ModalContentExamPeriod';
import ModalContentEPSMEvent from '../modals/ModalContentEPSMEvent';


/*
 * This ExamStick component displays the available date slots for a particular exam.
 */
const ExamStick = ({ examUNID, sectionType }) => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    const appContext                            = useContext(AppContext);
    const modalContext                          = useContext(ModalContext);
    
    // Reference hooks.
    const history                               = useHistory();
    
    // Destructure Context api's.
    const { userAuthenticated,
            curFetching,
            examsData,
            examsSessionData,
            userExamData,
            updateUserExam,
            examsCompleted,
            updateCompletedArr,
            postDataNoPayload,
            responseStatus,
            resetResponseStatus,
            getStudyDayData,
            examStudyDaysData }                 = authenticationContext;
    const { staticsData,
            showPlanBtnModal,
            setScreenshotEnable,
            disablePlanBtnModal,
            modalFtrTxt,
            hasTransition,
            setHasTransition }                  = appContext;
    const { showModal,
            hideModal, 
            updateModal,
            isVisible }                         = modalContext;
    
    // Define some DOM reference hooks.
    const headRef                               = useRef(null);
    const bodyRef                               = useRef(null);
    const footerRef                             = useRef(null);
    
    // Get window dimensions.
    const { winWidth, 
            winHeight }                         = useWindowDimensions();
    
    // Component state.
    const [monthsArr, setMonthsArr]             = useState([]);
    const [eventsArr, setEventsArr]             = useState([]);
    const [hdrMinHeight, setHdrMinHeight]       = useState(0);
    const [bdyMinHeight, setBdyMinHeight]       = useState(0);
    const [ftrMinHeight, setFtrMinHeight]       = useState(0);
    const [examStatus, setExamStatus]           = useState(0);
    const [statusLabel, setStatusLabel]         = useState('');
    const [isStill, setIsStill]                 = useState(true);
    const [examData, setExamData]               = useState(null);
    const [dimBtns, setDimBtns]                 = useState(false);
    const [completed, setCompleted]             = useState(false);
    const [statusColor, setStatusColor]         = useState('transparent');  
    const [btnDisabled, setBtnDisabled]         = useState(true);
    const [examStatusClass, setExamStatusClass] = useState('');
    
    // Local variables.
    const activityTypeArr                       = [2, 3, 4, 5];
    const exemptID                              = 1;
    const passedID                              = 2;
    const takingID                              = 3;
    const retakingID                            = 4;
    
    
    /*
     * Component methods.
     */
    
     // Set minHeights just before the stick closes and opens and then reset to 0
     // after the transition. So that sticks can still resize when the browser does.
    const setMinHeights = () => {
        
        setHdrMinHeight(headRef.current.clientHeight + 'px');
        setBdyMinHeight(bodyRef.current.clientHeight + 'px');
        setFtrMinHeight(footerRef.current.clientHeight + 'px');
        
    };
    
    const getShortName = () => {
        
        const exam = examsData.find(item => item.UNID === examUNID);
        
        return exam.shortName;
        
    };
    
    const getSectionTitle = () => {
        
        const section   = staticsData.examSectionType.find(item => item.ID === sectionType);
        
        return section.ID === 3 ? section.longLabel : section.label;
        
    };
    
    // Events are also known as Sessions.
    // Get all the events that match the current exams UNID.
    const getEvents = (examUNID) => {
        
        setEventsArr(examsSessionData.filter(item => item.examUNID === examUNID));
        
    };
    
    const drawFooterBtn = () => {
        
        //const userExam          = userExamData.find(item => item.examUNID === examUNID);
        const typeOnDemandExam  = staticsData.examType.find(item => item.ID === 1);
        const typeExam          = staticsData.examType.find(item => item.ID === 2);
        const btnBookObj        = staticsData.callToActionButtons.find(item => item.ID === 0);
        //const btnEditObj        = staticsData.callToActionButtons.find(item => item.ID === 2);
        
        // If this exam is of type 'ONDEMANDEXAM' or 'EXAM' then show a 'BOOK' or 'EDIT' 
        // button below the session buttons. Else if type is 'EPSEXAM' show no button.
        if ( eventsArr.length > 0  ) {
            
            if ( examData.type === typeOnDemandExam.ID || 
                 examData.type === typeExam.ID ) {
                    
                    // If we have a Study Period show the 'Edit' button.
                    // if ( typeof userExam !== 'undefined' && 
                    //      userExam.studyPeriodLength > 0 ) {
                        
                    //     return (
                    //         <button className="btn-plan"
                    //                 style={{ backgroundColor: `rgb(${btnEditObj.color})` }}
                    //                 onClick={() => onClickExamEventModal(userExam.examSessionUNID)}
                    //                 disabled={btnDisabled}>{btnEditObj.label}   
                    //         </button>
                    //     );
                        
                    // }
                    
                    // Else no Study Period exists show 'Book' button.
                    //else {
                        
                        return (
                            <button className="btn-book"
                                    style={{ backgroundColor: `rgb(${btnBookObj.color})` }} 
                                    onClick={() => openExtLink(examData.bookURL, true)}
                                    disabled={btnDisabled}>
                                {btnBookObj.label}
                            </button>
                        );
                        
                    //}
                    
                    
                    
                 }
            
        }
        else return null;
  
    };
    
    const updateAddExamStatusModalStep1 = () => {
        
        // System - define modal content.
        const txtTitleA     = getSectionTitle();
        const txtTitleB     = examData.shortName === 'EPSM' ? examData.shortName : examData.name;
        const exmStatusArr  = examData.statusTypes.split(',');
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalContentTagExam exmStatusArr={exmStatusArr} 
                                         examData={examData}  
                                         onClickAddExamStatusDone={onClickAddExamStatusDone} />
                </div>
            );
        };
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={modalFtrTxt}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };
    
    const updateTagExamModalStep2 = () => {
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    
                    <h2>Select your preferred exam session</h2>
                    <h2>Consider preparation time of this and any other exams that you will be sitting</h2>
                    <h2>To book the exam follow the link</h2>
                    
                    <div className="cta-btns flex-wrapper-center">
                        <button className="btn-pill btn-red" onClick={() => hideModal()}>Got it</button>
                    </div>

                </div>
            );
        };
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} 
                                                    txtFooter={modalFtrTxt}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };
    
    const showModalExamSelectEPSM = sessionUNID => {

        // System - define modal content.
        const txtTitleA     = getSectionTitle();
        const txtTitleB     = examData.shortName + ' complete by<br />' + getSessionDate(sessionUNID, examsSessionData, 'long');
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalContentEPSMEvent sessionUNID={sessionUNID}
                                           ctaBtnTxtA='Complete EPSM this month?'
                                           ctaBtnTxtB='Cancel this selection'
                                           ctaBtnFncA={onClickSessionSelectEPSM} 
                                           ctaBtnFncB={onClickSessionUnselect} />
                </div>
            );
        };
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={modalFtrTxt}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };

    const showModalExamSelect = sessionUNID => {
        
        // System - define modal content.
        const txtTitleA     = getSectionTitle();
        const txtTitleB     = examData.name + '<br />' + getSessionDate(sessionUNID, examsSessionData, 'long') + ' Exam';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalContentExamSelect sessionUNID={sessionUNID}
                                            noTime={noStudyTime(sessionUNID)}
                                            ctaBtnTxtA='Start planning'
                                            ctaBtnTxtB='Pick another date'
                                            ctaBtnFncA={onClickSessionSelect} 
                                            ctaBtnFncB={onClickSessionUnselect} />
                </div>
            );
        };
        const txtFooterPrv  = `<span class="txt-bld-red">NOTE</span> Reducing your study period or cancelling the plan may result in some of your existing tasks being hidden from view. Nothing is deleted, you can recover your planning by restoring previous settings.`;
        const txtFooterPub  = `<span class="txt-bld-gry">NOTE</span> Only logged in users can save their Exam Plans and add day-to-day tasks, milestones and reflections using the Study Planning toolkit.`;
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={userAuthenticated ? txtFooterPrv : txtFooterPub}
                                                    showLoginLnk={userAuthenticated ? false : true}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };
    
    const showModalExamPeriod = (sessionUNID, isAmend) => {
        
        // System - define modal content.
        const txtTitleA     = getSectionTitle();
        const txtTitleB     = examData.name + '<br />' + getSessionDate(sessionUNID, examsSessionData, 'long') + ' Exam';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalContentExamPeriod sessionUNID={sessionUNID}
                                            examData={examData}
                                            ctaBtnTxtA='No study planning required'
                                            ctaBtnTxtB='Done'
                                            ctaBtnFncA={onClickNoPeriod} 
                                            ctaBtnFncB={onClickAddPeriod} />
                </div>
            );
        };
        const txtFooterPrv  = `<span class="txt-bld-red">NOTE</span> Reducing your study period or cancelling the plan may result in some of your existing tasks being hidden from view. Nothing is deleted, you can recover your planning by restoring previous settings.`;
        const txtFooterPub  = `<span class="txt-bld-gry">NOTE</span> Only logged in users can save their Exam Plans and add day-to-day tasks, milestones and reflections using the Study Planning toolkit.`;
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={userAuthenticated ? txtFooterPrv : txtFooterPub}
                                                    showLoginLnk={userAuthenticated ? false : true}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        if ( isAmend ) showModal(props);
        else updateModal(props);
        
    };
    
    const showModalExamSummary = studyPeriodLength => {
        
        // Get study period weeks.
        const planningPeriod = getStudyPeriods('planning', studyPeriodLength);
        const learningPeriod = getStudyPeriods('learning', studyPeriodLength);
        const revisionPeriod = getStudyPeriods('revision', studyPeriodLength);
        
        // Instructional - define modal content.
        const txtTitleA     = 'Your ACCA Study planner';
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    
                    <h2>Your selected study period suggests the ACCA recommended approach to this exam:</h2>
                    
                    <div className="period-list">
                        
                        {staticsData.studyActivityType
                            .filter(item => activityTypeArr.includes(item.ID))
                            .map((item, idx) => (
                                <div key={idx}
                                     className="flex-wrapper-col flex-space-between"
                                     style={{ backgroundColor: 'rgb(' + item.color + ')' }}>
                                    <span>{item.label}</span>
                                    {/* Planning */}
                                    { item.ID === 2 && <span>{ planningPeriod > 1 ? planningPeriod + ' weeks' : planningPeriod + ' week'  }</span> }
                                    {/* Learning */}
                                    { item.ID === 3 && <span>{ learningPeriod > 1 ? learningPeriod + ' weeks' : learningPeriod + ' week'  }</span> }
                                    {/* Revision/Mock Exam */}
                                    { item.ID === 4 && <span>{ revisionPeriod > 1 ? revisionPeriod + ' weeks' : revisionPeriod + ' week'  }</span> }
                                    {/* Final Steps/Exam */}
                                    { item.ID === 5 && <span></span> }
                                </div>
                            ))
                        }
                        
                    </div>
                    
                    <div className="cta-btns flex-wrapper-center">
                        <button className="btn-pill btn-red" onClick={() => hideModal()}>Got it</button>
                    </div>

                </div>
            );
        };
        
        // Instructional - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 1,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplInsModal txtTitleA={txtTitleA} 
                                                    txtFooter={modalFtrTxt}>{htmlBody()}</TmplInsModal>
            }
        };
        
        // Instructional - show modal.
        showModal(props);
        
    };

    const gotoStudyPlannerModal = () => {
        
        // Show the Plan modal if this is the first time the Plan Btn has been clicked.
        if ( showPlanBtnModal ) {
                
            // Instructional - define modal content.
            const txtTitleA     = 'Your ACCA Study planner';
            const htmlBody      = () => {
                return (
                    <div className="modal-body ">
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-btm-0">Add day-to-day tasks and milestones</div>
                        :
                            <div className="list-item pad-btm-0">Add day-to-day tasks and target milestones</div>
                        }
                        
                        <div className="modal-cookie-txt pad-top-2 dim-txt">(Check out the exam curriculum for suggested topics)</div>
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-top-10 pad-btm-0">Configure Final Steps and Exam Date*</div>
                        :
                            <div className="list-item pad-top-10 pad-btm-0">Configure your Final Steps and Exam Date*</div>
                        }
                        
                        <div className="modal-cookie-txt pad-top-2 dim-txt">*Exact dates for scheduled exam sessions are usually released 6 months prior to the session window</div>
                        
                        { winWidth < 360 ?
                            <div className="list-item pad-top-10">Check off tasks as you go and reflect!</div>
                        :
                            <div className="list-item pad-top-10">Check off tasks as you go<br />and reflect on your progress</div>
                        }
                        
                        <div className="cta-btns flex-wrapper-center">
                            <button className="btn-pill btn-red" onClick={() => onClickGotoPlan()}>Got it</button>
                        </div>
                        
                    </div>
                );
            };
            const cusFtrTxt = 'In future releases we\'ll add the facility to auto-fill <br />tasks and get reminders on your device';
            
            // Instructional - initialize modal.
            const props = {
                status  : true,
                props   : {
                    type                : 1,
                    size                : 'small',
                    closeOnClickModal   : false,
                    content             : <TmplInsModal txtTitleA={txtTitleA} txtFooter={cusFtrTxt}>{htmlBody()}</TmplInsModal>
                }
            };
            
            // Instructional - show modal.
            showModal(props);
            
        }
        
        // Else just go straight to the Study Planner page.
        else gotoStudyPlanner();
        
    };
    
    const defineExamStatusClass = statusID => {
        
        const statusObj     = staticsData.examStatusType.find(item => item.ID === statusID); 
        const newClass      = typeof statusObj !== 'undefined' ? ` ${statusObj.label.toLowerCase()}` : '';
        
        setExamStatusClass(newClass);

    };
    
    const hasSessionSelected = () => {
        
        if ( examData === null ) return false;
        
        const userExam = userExamData.find(item => item.examUNID === examData.UNID);
        
        if ( typeof userExam !== 'undefined' &&
             userExam.statusType !== 0 ) {
            
            if ( userExam.examSessionUNID !== null ) return true;
            else return false;
            
        }
        else return false;
        
    };
    
    const noStudyTime = sessionUNID => {
        
        // Get the Current day number & Session day number (should be first day of the month).
        const curDayNum         = getSince1970('dy');
        const sessionDayNum     = getSessionWeek(sessionUNID, examsSessionData, 'dy');
        
        // Set the number of days until the exam session, then convert that into weeks.
        const weeksUntil        = Math.round((sessionDayNum - curDayNum) / 7);
        
        // Get the Study Period data that matches this userExam (Exam type: 1/ondemandExam, 2/exam & exam StatusType: 3/taking, 4/retaking).
        const examUser          = userExamData.find(item => item.examUNID === examUNID);
        const statusKey         = examUser.statusType === 3 ? 'take' : 'retake';
        const studyPeriods      = staticsData.sessionStudyPeriods.find(item => item.ID === examData.type);
        const studyPeriod       = studyPeriods[statusKey];
        
        // Check if the number of weeks until the event is less than the number of min study weeks for the event & return.
        return ( weeksUntil - studyPeriod.minWeeks ) < 0;
        
    };
    
    const getStudyPeriods = (activity, studyPeriodLength) => {
        
        const userExam          = userExamData.find(item => item.examUNID === examData.UNID);
        const studyData         = staticsData.sessionStudyPeriods.find(item => item.ID === examData.type);
        const studyType         = studyData[userExam.statusType === 3 ? 'take' : 'retake'];
        const studyPeriod       = studyType.studyPeriod.find(item => item.weeksUntil === studyPeriodLength);
        const lastStudyPeriod   = studyType.studyPeriod[studyType.studyPeriod.length - 1];
        const revisionWeeks     = typeof studyPeriod === 'undefined' && studyType.exceedMax ? lastStudyPeriod.revisionWeeks : studyPeriod.revisionWeeks;
        const planningWeeks     = ( userExam.studyPeriodLength - revisionWeeks ) > 1 ? 1 : 0;
        
        if ( activity === 'planning' ) return planningWeeks;
        else if ( activity === 'learning' ) return studyPeriodLength - ( revisionWeeks + planningWeeks );
        else return revisionWeeks;
        
    };
    
    const updateExamStatus = statusID => {
        
        hideModal();
        
        // If an event is selected then dim other buttons, but don't disable them.
        const userExam = userExamData.find(item => item.examUNID === examData.UNID);
        
        if ( typeof userExam !== 'undefined' ) {
            
            if ( userExam.examSessionUNID === null ) setDimBtns(false);
            else setDimBtns(true);
            
        }
        
        // Make sure any 'completed' state is removed.
        const tmpArr = examsCompleted.filter(item => item.UNID !== examUNID);
        
        updateCompletedArr(tmpArr);
        
        // Remove the 'still' class from the stick, this enables setting of maxHeight on
        // 'exam-header', 'exam-body' & 'exam-footer', required on mobile when stick closes.
        if ( statusID === exemptID || statusID === passedID ) setIsStill(false);
        
        // Add 'still' class back to 'exam-stick', else sticks won't resize when required.
        if ( statusID === takingID || statusID === retakingID || ( statusID === '' && completed === false ) ) setTimeout(() => setIsStill(true), 500);
        
        const matchingStatus    = staticsData.examStatusType.find(item => item.ID === statusID);
        const statusLabel       = statusID === 0 ? '' : matchingStatus.label;
        const statusColor       = statusID === 0 ? 'transparent' : 'rgb(' + matchingStatus.color + ')';
        
        setExamStatus(statusID);
        defineExamStatusClass(statusID);
        setStatusLabel(statusLabel);
        setStatusColor(statusColor);
        
        //const exSeUNID  = typeof userExam !== 'undefined' ? userExam.examSessionUNID : null;
        //const stPeLn    = typeof userExam !== 'undefined' ? userExam.studyPeriodLength : 0;
        
        // If 'userExam' is undefined then this is a new exam, certain values don't yet exist.
        // Or if 'statusID' is 0 then reset 'examSessionUNID' && 'studyPeriodLength'
        const exSeUNID  = typeof userExam === 'undefined' || statusID === 0 ? null : userExam.examSessionUNID;
        const stPeLn    = typeof userExam === 'undefined' || statusID === 0 ? 0 : userExam.studyPeriodLength;
        
        // Add exam to 'userExamData' array, Context method will check array for duplicates.
        updateUserExam({
            UNID                : new Date().getTime(),
            examUNID            : examUNID,
            examSessionUNID     : exSeUNID,
            studyPeriodLength   : stPeLn,
            statusType          : statusID
        });
        
        // Show the instructional modal if status is taking/retaking & Section is not 'Ethics Module'.
        if ( sectionType !== 3 && 
             ( statusID === takingID || statusID === retakingID ) ) updateTagExamModalStep2();
        
    };
    
    const updateExamSession = (sessionUNID, studyPeriodLength) => {
        
        hideModal();
        
        // If an event is selected then dim other buttons, but don't disable them.
        if ( sessionUNID === null ) setDimBtns(false);
        else setDimBtns(true);

        // Enable the screenshot button, 
        setScreenshotEnable(true);
        
        // Get matching userExam which will be updated.
        const userExam = userExamData.find(item => item.examUNID === examUNID);
        
        // Update exam in 'userExamData' array, adding selected Session UNID.
        if ( typeof userExam !== 'undefined' &&
             userExam.statusType !== 0 ) {
            
            userExam.examSessionUNID    = sessionUNID;
            userExam.studyPeriodLength  = studyPeriodLength;
            
            updateUserExam(userExam);
            
        }
        
        // Open Instructional modal showing a summary of study periods (only if study periods are set).
        if ( studyPeriodLength !== 0 ) showModalExamSummary(studyPeriodLength);
        
    };
    
    const gotoStudyPlanner = () => {
        
        history.push('/study-planner');
        
    };
    
    
    
 
    
    /*
     * Component event handlers.
     */
    
    const onClickAddExamStatusDone = statusID => {
        
        // If new 'statusID' matches old 'statusID' just close the modal & bail.
        const userExam = userExamData.find(item => item.examUNID === examData.UNID);
        
        if ( typeof userExam !== 'undefined' && 
             userExam.statusType === statusID ) {
               
            hideModal();
            return;
            
        }

        // If 'authenticated' update server.
        // If 'statusID' is 0 then the exam is marked as no status.
        if ( userAuthenticated ) {
            
            // If 'userExam' is undefined then this is a new exam, certain values don't yet exist.
            // Or if 'statusID' is 0 then reset 'examSessionUNID' && 'studyPeriodLength'
            const exSeUNID  = typeof userExam === 'undefined' || statusID === 0 ? null : userExam.examSessionUNID;
            const stPeLn    = typeof userExam === 'undefined' || statusID === 0 ? 0 : userExam.studyPeriodLength;
            
            postDataNoPayload('userexam', { examUNID, statusID }, { userExam: { examUNID, examSessionUNID: exSeUNID, studyPeriodLength: stPeLn, statusType: statusID } });
            
        }
        
        // Else only update local data.
        else updateExamStatus(statusID);

    };
    
    const onClickSessionSelectEPSM = sessionUNID => {
        
        onClickNoPeriod(sessionUNID);
 
    };
    
    const onClickSessionSelect = sessionUNID => {
        
        // If there is not enough time to select a study period
        // before the event starts then just highlight the event.
        if ( noStudyTime(sessionUNID) ) onClickNoPeriod(sessionUNID);
        
        // Else allow the user to select a study period.
        else showModalExamPeriod(sessionUNID);
 
    };
    
    // Close the modal and unselect all sessions,
    // if authenticated submit exam data to the server.
    const onClickSessionUnselect = () => {

        // If 'authenticated' update server, passing a null sessionUNID.
        if ( userAuthenticated ) onClickNoPeriod(null);
        
        // Else only update local data.
        else updateExamSession(null, 0);

    };

    const onClickNoPeriod = sessionUNID => {
        
        // If 'authenticated' update server.
        if ( userAuthenticated ) {
            
            const requestObj = {
                userExamSession: {
                    examUNID: examUNID,
                    examSessionUNID: sessionUNID,
                    studyPeriodLength: 0,
                    studyPlanningLength: 0,
                    studyExamLength: 0,
                    studyTypes: [],
                    starterTasks        : [
                        staticsData.copy.starterTask1,
                        staticsData.copy.starterTask2,
                        staticsData.copy.starterTask3,
                        staticsData.copy.starterTask4,
                        staticsData.copy.starterTask5,
                        staticsData.copy.starterTask6,
                        staticsData.copy.starterTask7
                    ]
                }
            };
            
            postDataNoPayload('userexam/session', { examUNID, sessionUNID, studyPeriodLength: 0 }, requestObj);
            
        }
        
        // Else only update local data.
        else updateExamSession(sessionUNID, 0);
        
    }
    
    const onClickAddPeriod = (sessionUNID, studyPeriodLength, studyTypesArr) => {
        
        // If 'authenticated' update server.
        if ( userAuthenticated ) {
            
            const exam          = examsData.find(item => item.UNID === examUNID);
            const requestObj    = {
                userExamSession: {
                    examUNID            : examUNID,
                    examSessionUNID     : sessionUNID,
                    studyPeriodLength   : studyPeriodLength,
                    studyPlanningLength : 0,
                    studyExamLength     : exam.type === 1 ? 4 : 2,
                    studyTypes          : studyTypesArr,
                    starterTasks        : [
                        staticsData.copy.starterTask1,
                        staticsData.copy.starterTask2,
                        staticsData.copy.starterTask3,
                        staticsData.copy.starterTask4,
                        staticsData.copy.starterTask5,
                        staticsData.copy.starterTask6,
                        staticsData.copy.starterTask7
                    ]
                }
            };
            
            postDataNoPayload('userexam/session', { examUNID, sessionUNID, studyPeriodLength }, requestObj);
            
        }
        
        // Else only update local data.
        else updateExamSession(sessionUNID, studyPeriodLength);
  
    }

    // Open a modal & set the status of the current exam (Exempt, Passed, Taking...etc)
    const onClickAddExamStatusModal = () => {
        
        // Open the modal & show content for step 1.
        updateAddExamStatusModalStep1();
        
    };
    
    // Open a modal & select an event date for the current exam.
    // This begins the process to select a study period.
    const onClickExamEventModal = sessionUNID => {
        
        // Open the modal & show content for step 1.
        // EM/EPSM has a different modal to all others.
        if ( sectionType === 3 ) showModalExamSelectEPSM(sessionUNID);
        else {
            
            const userExam  = userExamData.find(item => item.examUNID === examData.UNID);
            const noTime    = noStudyTime(sessionUNID);
            
            // If the exam already has a 'examSessionUNID' then we can go straight to 'showModalExamPeriod' skipping the first modal.
            if ( noTime ||
                 userExam.examSessionUNID === null || 
                 userExam.examSessionUNID !== sessionUNID ) showModalExamSelect(sessionUNID);
            else showModalExamPeriod(sessionUNID, true);
            
        }

    };
    
    // If the 'Plan' button or 'Timeline' bars are clicked & the user is authenticated show the planner intro modal
    // else open a modal to ask the user to login before accessing the Study Planner.
    const onClickGotoStudyPlanner = () => {
        
        // Go to Study Planner.
        if ( userAuthenticated ) {
            
            // Fetch the study planner data before showing the info modal.
            const userExam = userExamData.find(item => item.examUNID === examData.UNID);
            
            getStudyDayData('userexam/session', examUNID, userExam.examSessionUNID);
            
        }
        
        // Else show login prompt modal.
        else {
            
            // Instructional - define modal content.
            const txtTitleA     = 'Your ACCA Study planner';
            const htmlBody      = () => {
                return (
                    <div className="modal-body ">
                        <ModalContentLogin />
                    </div>
                );
            };
            
            // Instructional - initialize modal.
            const props = {
                status  : true,
                props   : {
                    type                : 1,
                    size                : 'small',
                    closeOnClickModal   : false,
                    content             : <TmplInsModal txtTitleA={txtTitleA}>{htmlBody()}</TmplInsModal>
                }
            };
            
            // Instructional - show modal.
            showModal(props);
            
        }
        
    };
    
    // On clicking the exam timeline if the user is logged in go to the Study Planner page,
    // else if the user is not logged in open the select Study Periods modal.
    const onClickExamTimeline = () => {
        
        const userExam = userExamData.find(item => item.examUNID === examUNID);
        
        // Go to Study Planner.
        if ( userAuthenticated ) {
            
            getStudyDayData('userexam/session', examUNID, userExam.examSessionUNID);
            
        }
        
        // Open Study Periods modal, skipping the first 2 screens.
        else showModalExamPeriod(userExam.examSessionUNID, true);
        
    };
    
    // Close the Study Planner intro modal & navigate to the planner page.
    const onClickGotoPlan = () => {
        
        // Instructional - hide modal.
        hideModal();
        
        // Disable modal so it doesn't appear next time the Plan Btn is clicked.
        disablePlanBtnModal();
        
        // Go to Study Planner page.
        gotoStudyPlanner();
        
    };
    
    
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // On mobile when exam stick shrinks it's content gets squeezed together since
        // their heights are set as percentages to allow for different size devices.
        // To fix this set a min-height after each stick has been drawn.
        setMinHeights();
        
        // Define the current set of 12 months & years.
        setMonthsArr(defineMonthsYears());
        
        // Get this exams events.
        getEvents(examUNID);

        // Make a local copy of this exams data.
        setExamData(examsData.find(item => item.UNID === examUNID ));

        // After a delay to let the page load, enable exam stick transitions.
        setHasTransition(false);
        
        setTimeout(() => {
            
            setHasTransition(true);
            
        }, 1000);

    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        // When local 'examData' & global 'userExamData' are populated check the status of this exam.
        if ( examData !== null && userExamData !== null ) {
            
            // Check whether this exam has been selected by the user.
            const userExam = userExamData.find(item => item.examUNID === examUNID);
            
            if ( typeof userExam !== 'undefined' && 
                 userExam.statusType !== 0 ) {
                
                const matchingStatus    = staticsData.examStatusType.find(item => item.ID === userExam.statusType);
                const matchingSession   = examsSessionData.find(item => item.UNID === userExam.examSessionUNID);
                
                if ( typeof matchingSession !== 'undefined' ) setDimBtns(true);
        
                setExamStatus(userExam.statusType);
                defineExamStatusClass(userExam.statusType);
                setStatusLabel(matchingStatus.label);
                setStatusColor('rgb(' + matchingStatus.color + ')');
                
            }
            
        }

    // eslint-disable-next-line 
    }, [examData, userExamData]);
    
    useEffect(() => {
        
        // Update minHeights if browser dimensions change.
        setMinHeights();
        
    // eslint-disable-next-line 
    }, [winWidth, winHeight]);
    
    // Exams completed set by checkboxes in modal.
    useEffect(() => {
        
        const exam = examsCompleted.find(item => item.UNID === examUNID);
        
        if ( typeof exam !== 'undefined' ) {
            
            setMinHeights();
            setIsStill(false);
            setCompleted(true);
            setDimBtns(false);
            setExamStatus(0);
            defineExamStatusClass(0);
            setStatusLabel('');
            setStatusColor('transparent');
            
        }
        else setCompleted(false);
        
    // eslint-disable-next-line 
    }, [examsCompleted]);
    
    // Listen for 'userExamData' changes and reset.
    useEffect(() => {
        
        const userExam = userExamData.find(item => item.examUNID === examUNID);
        
        if ( typeof userExam === 'undefined' && !completed ) {
            
            // Reset all local exam props.
            setExamStatus(0);
            defineExamStatusClass(0);
            setStatusLabel('');
            setStatusColor('transparent');
            setDimBtns(false);
            
        }
        
    // eslint-disable-next-line 
    }, [userExamData]);
    
    // Listen for 'examStudyDaysData' changes, if data has been added go to the
    // next step in showing the Study Planner page.
    useEffect(() => {
        
        if ( examStudyDaysData !== null && 
             examStudyDaysData.examUNID === examUNID ) {
            
            gotoStudyPlannerModal();
            
        }
        
    // eslint-disable-next-line 
    }, [examStudyDaysData]);
    
    // Listen for 'responseStatus' changes and if they are TRUE 
    // continue updating local model.
    useEffect(() => {
        
        if ( responseStatus !== null && 
             responseStatus.status === true && 
             responseStatus.props.examUNID === examUNID ) {
            
            if ( responseStatus.endPoint === 'userexam' ) updateExamStatus(responseStatus.props.statusID);
            if ( responseStatus.endPoint === 'userexam/session' ) updateExamSession(responseStatus.props.sessionUNID, responseStatus.props.studyPeriodLength);
            
            resetResponseStatus();
            
        }
        
    // eslint-disable-next-line 
    }, [responseStatus]);
    
    useEffect(() => {
        
        // If we have a modal open then no need to disable the btns below it.
        if ( isVisible ) return;
        
        setBtnDisabled(curFetching === '' ? false : true);

    // eslint-disable-next-line 
    }, [curFetching]);
    
    

     
     
    /*
     * Component view.
     */
    
    return (
        <div className={'exam-stick' + (examStatusClass) + ( isStill ? ' still' : '' ) + ( completed ? ' completed' : '' ) + ( hasTransition ? ' has-transition' : '' )}>
            <div className="content">
                
                {/* Exam stick header: START */}
                <div ref={headRef} 
                     className={'exam-header tick'}
                     style={{ minHeight: hdrMinHeight }}>
                         
                    <button onClick={onClickAddExamStatusModal}
                            style={{ height: hdrMinHeight }}
                            disabled={btnDisabled}>
                        <div className="exam-status"
                             style={{ backgroundColor: statusColor }}>
                            <div className="text">
                                {statusLabel}
                            </div>
                            <div className="dots">
                                <span className="icon-dot"></span>
                                <span className="icon-dot"></span>
                                <span className="icon-dot"></span>
                            </div>
                        </div>
                        <div className="exam-title">
                            {getShortName(sectionType, examUNID)}
                            {IconTickWht()}
                        </div>
                    </button>
                    
                </div>
                {/* Exam stick header: STOP */}
                
                {/* Exam stick body: START */}
                <div ref={bodyRef} 
                     className={ 'exam-body' + ( hasSessionSelected() ? '' : ' highlight-all' ) + ( btnDisabled ? ' disabled' : '' ) }
                     style={{ minHeight: bdyMinHeight }}>

                    {monthsArr.map((monthObj, idx) => (
                        <ExamCell key={idx} 
                                  monthObj={monthObj}
                                  eventsArr={eventsArr} 
                                  sectionType={sectionType} 
                                  examData={examData} 
                                  examStatus={examStatus}
                                  dimBtns={dimBtns} 
                                  onClickExamEvent={onClickExamEventModal}
                                  onClickGotoStudyPlanner={onClickGotoStudyPlanner} 
                                  onClickExamTimeline={onClickExamTimeline} />
                    ))}
                    
                    <div className="bkgrnd-mask"></div>
                </div>
                {/* Exam stick body: STOP */}
                
                {/* Exam stick footer: START */}
                <div ref={footerRef} 
                     className={'exam-footer' + ( btnDisabled ? ' disabled' : '' )}
                     style={{ minHeight: ftrMinHeight }}>
                    {drawFooterBtn()}
                    
                    <div className="bkgrnd-mask"></div>
                </div>
                {/* Exam stick footer: STOP */}
                
                {/* Full height button */}
                { examStatus === 0 && (
                    <button className="btn-cover-stick"
                            onClick={onClickAddExamStatusModal}
                            disabled={btnDisabled}></button>
                ) }
                
                
            </div>
        </div>
    );
    
    
};

export default ExamStick;


/*** Scripts end... */

import React, { useReducer, useState, useEffect } from 'react';
import { PublicClientApplication,
         InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';

import AuthenticationContext from './AuthenticationContext';
import AuthenticationReducer from './AuthenticationReducer';
import { SET_AUTH_APPLICATION,
         SET_BEARER_TOKEN,
         SET_USER_AUTHENTICATED,
         SET_RESPONSE_STATUS,
         SET_FETCH_ERROR,
         SET_CUR_FETCHING,
         SET_PUBLIC_DATA,
         SET_PRIVATE_DATA,
         SET_OVERRIDE_DATA,
         SET_STUDY_DAYS_DATA,
         CLEAR_STUDY_DAYS_DATA,
         UPDATE_STUDY_TASKS_DATA,
         UPDATE_USER_EXAMS,
         UPDATE_COMPLETED_EXAMS,
         RESET_EXAM_PLANNER } from './AuthenticationTypes';
import { getDataPublic, 
         getDataPrivate,
         postDataPrivate,
         postDataNoPayloadPrivate,
         putDataNoPayloadPrivate } from '../../components/hooks/fetchMethods';

import { msalConfig, 
         loginConfig,
         apiUrlPrivate } from '../../authConfig.js';


/*
 * We are using Azure AD B2C to handle authentication. When a user clicks 'sign in' they are redirected
 * to an Azure log in page where they can enter their details. THey will then be redirected back to this
 * app via the server. The server will append an ID Token as a cookie to the redirect url which we can then 
 * use to authorize future server calls. If the user signs out then they should be signed out of Azure and 
 * also their local cookies & session storage will be cleared so they can no longer call the server using 
 * the ID Token.
 */
const AuthenticationState = props => {
    
    
    // Define initial state.
    const initialState = {
        publicClientApplication     : null,
        bearerToken                 : '',
        userAuthenticated           : false,
        responseStatus              : null,
        userName                    : '',
        examsData                   : null,
        examsSessionData            : null,
        examStudyDaysData           : null,
        studyTasksData              : null,
        userData                    : null,
        userExamData                : null,
        fetchError                  : null,
        examsCompleted              : [],
        curFetching                 : ''
    };
    
    // Initialize Reducer hook.
    const [state, dispatch]         = useReducer(AuthenticationReducer, initialState);
    
    // Component state.
    const [publicClientApplication, setPublicClientApplication] = useState(null);
    
    
    /*
     * Component methods.
     */ 
    
    // Initialize Azure AD B2C instance.
    const initB2C = () => {
        
        setPublicClientApplication(new PublicClientApplication(msalConfig));
        
    };
    
    // Set current fetching value so that we can add/remove a spinner to/from the appropriate button.
    const setCurFetching = val => {

        dispatch({
            type        : SET_CUR_FETCHING,
            payload     : val
        });
 
    };
    
    const handleLoggedInError = (error, accounts) => {
        
        setCurFetching('');
                
        if ( error instanceof InteractionRequiredAuthError ) {
        
            publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
            
        }
        else {
            
            dispatch({
                type        : SET_FETCH_ERROR,
                payload     : { type: 'http', code: 401, description: '' }
            });
            
        }
        
    };
    
    
    
    
    /*
     * API methods.
     */
    
    const getData = async (endPoint, action) => {
        
        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();
        
        // Use the PRIVATE API if we have a local B2C account (user is logged in).
        // MSAL Docs: https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
        if ( accounts.length > 0 ) {
            
            try {
                
                const tokenResponse = await publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] });
                
                if ( !tokenResponse.accessToken || tokenResponse.accessToken === '' ) publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
                else {
                    
                    //const brToken = `Bearer ${tokenResponse.accessToken}`;
                
                    // Store the bearer token for future use.
                    // dispatch({
                    //     type        : SET_BEARER_TOKEN,
                    //     payload     : brToken
                    // });
                    
                    getDataPrivate(endPoint, `Bearer ${tokenResponse.accessToken}`)
                        .then(response => {
                            
                            if ( response.status === 'SUCCESS' ) {
                                
                                dispatch({
                                    type        : action,
                                    payload     : response.data
                                });
                                
                            }
                            else {
                                
                                dispatch({
                                    type        : SET_FETCH_ERROR,
                                    payload     : { ...response.data, req: { endPoint } }
                                });
                                
                            }
                            
                        }); 
                    
                }

            }
            catch (error) {
                
                handleLoggedInError(error, accounts);
                
            }
 
        }
        
        // Use the PUBLIC API if we have no local account (user not logged in).
        else {
            
            getDataPublic(endPoint)
                .then(response => {
                    
                    if ( response.status === 'SUCCESS' ) {
                        
                        dispatch({
                            type        : SET_PUBLIC_DATA,
                            payload     : response.data
                        });
                        
                    }
                    else {
                        
                        dispatch({
                            type        : SET_FETCH_ERROR,
                            payload     : { ...response.data, req: { endPoint } }
                        });
                        
                    }

                });
            
        }
        
    };
    
    const postData = async (endPoint, requestObj) => {

        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();
        setCurFetching(endPoint);
        
        try {
            
            const tokenResponse = await publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] });
                
            if ( !tokenResponse.accessToken || tokenResponse.accessToken === '' ) publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
            else {
                
                postDataPrivate(endPoint, requestObj, `Bearer ${tokenResponse.accessToken}`)
                    .then(response => {
                        
                        setCurFetching('');
                        
                        if ( response.status === 'SUCCESS' ) {
                            
                            //...
                            
                        }
                        else {
                            
                            dispatch({
                                type        : SET_FETCH_ERROR,
                                payload     : { ...response.data, req: { endPoint } }
                            });
                            
                        }
                        
                    });
                
            }
            
        }
        catch (error) {
            
            
            handleLoggedInError(error, accounts);
            
        }
        
    };
    
    const postDataNoPayload = async (endPoint, props, requestObj) => {

        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();
        setCurFetching(endPoint);
        
        try {
            
            const tokenResponse = await publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] });
                
            if ( !tokenResponse.accessToken || tokenResponse.accessToken === '' ) publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
            else {
                
                postDataNoPayloadPrivate(endPoint, requestObj, `Bearer ${tokenResponse.accessToken}`)
                    .then(response => {
                        
                        setCurFetching('');
                        
                        if ( response.status === 'SUCCESS' ) {
                            
                            dispatch({
                                type        : SET_RESPONSE_STATUS,
                                payload     : { status: true, endPoint, props }
                            });
                            
                        }
                        else {
                            
                            dispatch({
                                type        : SET_FETCH_ERROR,
                                payload     : { ...response.data, req: { endPoint } }
                            });
                            
                        }
                        
                    });
                
            }
            
        }
        catch (error) {
            
            handleLoggedInError(error, accounts);
            
        }
        
    };
    
    const putDataNoPayload = async (endPoint, props, requestObj) => {

        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();
        setCurFetching(endPoint);
        
        try {
            
            const tokenResponse = await publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] });
                
            if ( !tokenResponse.accessToken || tokenResponse.accessToken === '' ) publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
            else {
                
                putDataNoPayloadPrivate(endPoint, requestObj, `Bearer ${tokenResponse.accessToken}`)
                    .then(response => {
                        
                        setCurFetching('');
                        
                        if ( response.status === 'SUCCESS' ) {
                            
                            dispatch({
                                type        : SET_RESPONSE_STATUS,
                                payload     : { status: true, endPoint, props }
                            });
                            
                        }
                        else {
                            
                            dispatch({
                                type        : SET_FETCH_ERROR,
                                payload     : { ...response.data, req: { endPoint } }
                            });
                            
                        }
                        
                    });
                
            }
            
        }
        catch (error) {
            
            handleLoggedInError(error, accounts);
            
        }
        
    };
    
    const getStudyDayData = async (endPoint, examUNID, sessionUNID) => {
        
        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();
        
        // Use the PRIVATE API if we have a local B2C account (user is logged in).
        // MSAL Docs: https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
        if ( accounts.length > 0 ) {
            
            setCurFetching(endPoint);
            
            try {
                
                const tokenResponse = await publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] });
                
                if ( !tokenResponse.accessToken || tokenResponse.accessToken === '' ) publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
                else {
                
                    getDataPrivate(endPoint + '?id=' + sessionUNID, `Bearer ${tokenResponse.accessToken}`)
                        .then(response => {
                            
                            setCurFetching('');
                            
                            if ( response.status === 'SUCCESS' ) {
                                
                                dispatch({
                                    type        : SET_STUDY_DAYS_DATA,
                                    payload     : { 
                                        examUNID        : examUNID,
                                        sessionUNID     : sessionUNID,
                                        studyDaysData   : response.data
                                    }
                                });
                                
                            }
                            else {
                                
                                dispatch({
                                    type        : SET_FETCH_ERROR,
                                    payload     : { ...response.data, req: { endPoint } }
                                });
                                
                            }
                            
                        });
                    
                }
                
            }
            catch (error) {
                
                handleLoggedInError(error, accounts);
                
            }
        
        }
        else {
            
            dispatch({
                type        : SET_FETCH_ERROR,
                payload     : { type: 'loggedout' }
            });
            
        }
        
    };
    
    // Call a Promise to send data to the server.
    // This fetch is async so the client will update even before a response has come back.
    // The client will disable the updated Task until a success response is received or show an error mesasage if applicable.
    const postDataAsync = (endPoint, requestObj, taskUNID) => {
        
        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();

        return new Promise((resolve, reject) => {
            
            if ( accounts.length > 0 ) {
                
                try {
                    
                    publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] })
                        .then(tokenResponse => {
                            
                            axios.post(apiUrlPrivate + endPoint, requestObj, { headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` } })
                                .then(response => {
                                    
                                    if ( response.status === 200 ) {
                                        
                                        if ( response.data.errorCode === 0 ) resolve({ taskUNID, newTaskUNID: response.data.payload.UserExamSessionTask.UNID });
                                        else {
                                            
                                            dispatch({
                                                type        : SET_FETCH_ERROR,
                                                payload     : { type: 'server', code: response.data.errorCode, description: response.data.errorDescription }
                                            });
                                            reject('Failed');
                                            
                                        }
                                        
                                    }
                                    else {
                                        
                                        dispatch({
                                            type        : SET_FETCH_ERROR,
                                            payload     : { type: 'http', code: response.status, description: response.statusText }
                                        });
                                        reject('Failed');
                                        
                                    } 
                                    
                                })
                                .catch(error => {
                                    
                                    dispatch({
                                        type        : SET_FETCH_ERROR,
                                        payload     : { type: 'http', code: error.response.status, description: error.response.statusText }
                                    });
                                    reject('Failed');
                                    
                                });
                            
                        })
                        .catch(error => {
                            
                            publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
                            reject('Failed');
                            
                        });
 
                }
                catch (error) {
                    
                    handleLoggedInError(error, accounts);
                    reject('Failed');
                    
                }

            }
            else {
            
                dispatch({
                    type        : SET_FETCH_ERROR,
                    payload     : { type: 'loggedout' }
                });
                
                reject('Failed');
                
            }
            
        });
        
    };
    
    // Call a Promise to send data to the server.
    // This fetch is async so the client will update even before a response has come back.
    // The client will disable the updated Task until a success response is received or show an error mesasage if applicable.
    const putDataAsync = async (endPoint, requestObj, taskUNID) => {
        
        const accounts = publicClientApplication.getAllAccounts();
        
        clearFetchError();

        return new Promise((resolve, reject) => {
            
            if ( accounts.length > 0 ) {
                
                try {
                    
                    publicClientApplication.acquireTokenSilent({ scopes: loginConfig.scopes, account: accounts[0] })
                        .then(tokenResponse => {
                            
                            axios.put(apiUrlPrivate + endPoint, requestObj, { headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` } })
                                .then(response => {

                                    if ( response.status === 200 ) {
                                        
                                        if ( response.data.errorCode === 0 ) resolve({ taskUNID });
                                        else {
                                            
                                            dispatch({
                                                type        : SET_FETCH_ERROR,
                                                payload     : { type: 'server', code: response.data.errorCode, description: response.data.errorDescription }
                                            });
                                            reject('Failed');
                                            
                                        }
                                        
                                    }
                                    else {
                                        
                                        dispatch({
                                            type        : SET_FETCH_ERROR,
                                            payload     : { type: 'http', code: response.status, description: response.statusText }
                                        });
                                        reject('Failed');
                                        
                                    } 
                                    
                                })
                                .catch(error => {

                                    dispatch({
                                        type        : SET_FETCH_ERROR,
                                        payload     : { type: 'http', code: error.response.status, description: error.response.statusText }
                                    });
                                    reject('Failed');
                                    
                                });
                            
                        })
                        .catch(error => {
                            
                            publicClientApplication.acquireTokenRedirect({ scopes: loginConfig.scopes, account: accounts[0] });
                            reject('Failed');
                            
                        });
    
                }
                catch (error) {
                    
                    handleLoggedInError(error, accounts);
                    reject('Failed');
                    
                }
 
            }
            else {
            
                dispatch({
                    type        : SET_FETCH_ERROR,
                    payload     : { type: 'loggedout' }
                });
                
                reject('Failed');
                
            }
            
        });
        
    };
    
    // Sign user in. This will redirect browser to Azure B2C login page, 
    // then bring the user back here via the server.
    const signInUser = () => {
        
        try {
            
            sessionStorage.setItem("is_redirect", "true");
            publicClientApplication.loginRedirect(loginConfig);
        
        }
        catch(error) {
        
            sessionStorage.removeItem("is_redirect");
            console.log('ERROR:', error);
        
        }
        
    };
    
    // Sign out user will set 'userAuthenticated' to false, 
    // clear seassion storage & clear all cookies.
    const signOutUser = (status) => {
        
        try {
            
            sessionStorage.setItem("is_redirect", "true");
            
            if ( typeof status !== 'undefined' &&
                 ( status === 401 || status === 403 ) ) {
                     
                    sessionStorage.setItem("show_login", "true");
                    sessionStorage.setItem("status_code", status);
                    
            }
            
            publicClientApplication.logoutRedirect();
        
        }
        catch(error) {
        
            sessionStorage.removeItem("is_redirect");
            sessionStorage.removeItem("show_login");
            sessionStorage.removeItem("status_code");
            console.log('ERROR:', error);
        
        }
        
    };
    
    // UPDATE or ADD a userExam in the 'userExamData' array.
    const updateUserExam = exam => {
        
        let tmpArr = [];
        
        // If we already have this exam in the array update it instead of adding it again.
        const examMatch = state.userExamData.find(item => item.examUNID === exam.examUNID);
        
        // UPDATE: The array already has this exam.
        if ( typeof examMatch !== 'undefined' ) {
            
            const filteredArr = state.userExamData.filter(item => item.examUNID !== exam.examUNID);
            
            tmpArr = [...filteredArr, exam];
            
        }
        
        // ADD: The array doesn't yet have this exam.
        else tmpArr = [...state.userExamData, exam];

        dispatch({
            type        : UPDATE_USER_EXAMS,
            payload     : tmpArr
        });
        
    };
    
    // UPDATE exam completed array, only used by logged out users.
    // Any completed exams should be removed from the 'userExamData' array.
    const updateCompletedArr = (examsArr) => {
        
        // Update completed exams array.
        dispatch({
            type        : UPDATE_COMPLETED_EXAMS,
            payload     : examsArr
        });
        
        // Update user exams array.
        const filteredArr = state.userExamData.filter(itemA => !examsArr.find(itemB => itemB.UNID === itemA.examUNID));
        
        dispatch({
            type        : UPDATE_USER_EXAMS,
            payload     : filteredArr
        });
        
    };
    
    // Reset user exam data, only available to logged out users.
    const resetUserExams = () => {
        
        dispatch({
            type        : RESET_EXAM_PLANNER
        });
        
    };
    
    // Reset 'responseStatus' to FALSE.
    const resetResponseStatus = () => {
        
        dispatch({
            type        : SET_RESPONSE_STATUS,
            payload     : null
        });
        
    };

    // Clear 'examStudyDaysData' when leaving the Study Planner, so that we always get new data when returning.
    const clearStudyDaysData = () => {
        
        dispatch({
            type        : CLEAR_STUDY_DAYS_DATA
        });
        
    };
    
    // Add a task to the tasks array.
    const addStudyDayTask = newTask => {
        
        const tasksArr = [...state.studyTasksData];
        
        tasksArr.push(newTask);
        
        dispatch({
            type        : UPDATE_STUDY_TASKS_DATA,
            payload     : tasksArr
        });
        
    };
    
    // Update a task in the tasks array.
    const updateStudyDayTask = taskObj => {
        
        const idx           = state.studyTasksData.findIndex(item => item.UNID === taskObj.UNID);
        const filteredArr   = state.studyTasksData.filter(item => item.UNID !== taskObj.UNID);
        
        filteredArr.splice(idx, 0, taskObj);
        
        dispatch({
            type        : UPDATE_STUDY_TASKS_DATA,
            payload     : filteredArr
        });
        
    };
    
    // Delete a task from the tasks array.
    const deleteStudyDayTask = taskUNID => {
        
        const filteredArr = state.studyTasksData.filter(item => item.UNID !== taskUNID);    
        
        dispatch({
            type        : UPDATE_STUDY_TASKS_DATA,
            payload     : filteredArr
        });
        
    };
    
    // Update an existing tasks UNID, this is done after a new task has been created with a temporary UNID.
    const updateTaskUNID = ({ oldUNID, newUNID }) => {
        
        const idx           = state.studyTasksData.findIndex(item => item.UNID === oldUNID);
        const matchingTask  = state.studyTasksData.find(item => item.UNID === oldUNID);
        const filteredArr   = state.studyTasksData.filter(item => item.UNID !== oldUNID); 
        const taskObj       = {...matchingTask};
        
        taskObj.UNID        = newUNID;
        
        filteredArr.splice(idx, 0, taskObj);
        
        dispatch({
            type        : UPDATE_STUDY_TASKS_DATA,
            payload     : filteredArr
        });
        
    };
    
    const clearFetchError = () => {
        
        dispatch({
            type        : SET_FETCH_ERROR,
            payload     : null
        });
        
    };
    
    
 
    
    
    /*
     * Component hooks.
     */
    
    useEffect(() => {
         
        const hostname          = window.location.hostname;
        const allowedNamesArr   = ['localhost', 'globebyte'];
        const urlParams         = new URLSearchParams(window.location.search);
        const overrideLogin     = urlParams.has('overridelogin');
        
        let strAppears          = false;
        
        allowedNamesArr.forEach(item => {
            
            if ( hostname.includes(item) ) strAppears = true;
            
        });
        
        // If we are on an allowed domain name & if the querystring
        // includes 'overridelogin' then set authenticated to true.
        if ( strAppears && overrideLogin ) {
            
            dispatch({
                type        : SET_OVERRIDE_DATA
            });
            
            // Fetch initial PUBLIC API data.
            getData('exam', SET_PRIVATE_DATA);
            
        }
        
        // Else user will need to authenticate.
        else initB2C();

    // eslint-disable-next-line
    }, []);
    
    useEffect(() => {

        // If 'publicClientApplication' exists
        if ( publicClientApplication && publicClientApplication !== 'override' ) {
            
            dispatch({
                type        : SET_AUTH_APPLICATION,
                payload     : publicClientApplication
            });

            // This listener is fired when the site reloads following a login or logout event.
            // If a user has successfully logged in then we can fetch user data.
            publicClientApplication.handleRedirectPromise()
                .then(tokenResponse => {
                    
                    // Token response FAIL, but check to see whether we
                    // have an account stored locally. If we do then we are still authorized,
                    // as we are responding to a logged in browser refresh.
                    if ( tokenResponse === null ) {
                        
                        const accounts = publicClientApplication.getAllAccounts();
                        
                        // Local account not found.
                        if ( accounts.length === 0 ) {
                            
                            dispatch({
                                type        : SET_USER_AUTHENTICATED,
                                payload     : false
                            });
                            
                        }
                        
                        // Local account exists.
                        else {
                            
                            dispatch({
                                type        : SET_USER_AUTHENTICATED,
                                payload     : true
                            });
                            
                        }
                        
                        
                    }
                    
                    // Token response SUCCESS.
                    else {
                        
                        dispatch({
                            type        : SET_USER_AUTHENTICATED,
                            payload     : true
                        });
                        
                    }
                    
                    // Fetch initial PUBLIC or PRIVATE API data.
                    getData('exam', SET_PRIVATE_DATA);
                    
                })
                .catch(error => {
                    
                    console.error(error);
                    
                    // Instead of showing an error msg the user can interact with the 
                    // site in sandbox mode, with the option of logging in later.
                    dispatch({
                        type        : SET_USER_AUTHENTICATED,
                        payload     : false
                    });
                    
                    // Fetch initial PUBLIC API data.
                    getData('exam', SET_PRIVATE_DATA);
                    
                });
                 
        }

    // eslint-disable-next-line
    }, [publicClientApplication]);
    
    
    
    
    /*
     * Return context state & methods.
     */
    
    return <AuthenticationContext.Provider value={{
        publicClientApplication     : state.publicClientApplication,
        userAuthenticated           : state.userAuthenticated,
        userName                    : state.userName,
        examsData                   : state.examsData,
        examsSessionData            : state.examsSessionData,
        userData                    : state.userData,
        userExamData                : state.userExamData,
        fetchError                  : state.fetchError,
        curFetching                 : state.curFetching,
        examsCompleted              : state.examsCompleted,
        responseStatus              : state.responseStatus,
        examStudyDaysData           : state.examStudyDaysData,
        studyTasksData              : state.studyTasksData,
        setPublicClientApplication,
        getData,
        postData,
        postDataNoPayload,
        putDataNoPayload,
        signInUser,
        signOutUser,
        updateUserExam,
        updateCompletedArr,
        resetUserExams,
        resetResponseStatus,
        getStudyDayData,
        clearStudyDaysData,
        addStudyDayTask,
        updateStudyDayTask,
        deleteStudyDayTask,
        postDataAsync,
        putDataAsync,
        updateTaskUNID,
        clearFetchError
    }}>
        {props.children}
    </AuthenticationContext.Provider>
    
};

export default AuthenticationState;


/*** Scripts end... */

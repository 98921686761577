import React, { useContext, useRef, useState, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';
import ModalContext from '../../context/modal/ModalContext';

import { IconTickCircled } from '../hooks/ImageAssets';
import { IconFlagCircled } from '../hooks/ImageAssets';
import { IconPlusCircled } from '../hooks/ImageAssets';


/*
 * This ModalChooseTask component displays the HTML for the Choose Task modal.
 */
const ModalChooseTask = ({ itemData, addServerTask, updateServerTask, disabledTasks }) => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const appContext                        = useContext(AppContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { studyTasksData,
            putDataNoPayload,
            addStudyDayTask,
            updateStudyDayTask,
            deleteStudyDayTask}             = authenticationContext;
    const { staticsData,
            toggleDocClicks,
            docClicked,
            docClickedTgt }                 = appContext;
    const { hideModal }                     = modalContext;
    
    // Define some DOM reference hooks.
    const txtRefs                           = useRef([]);
    const toggleBtnRef                      = useRef(null);
    const scrollerRef                       = useRef(null);
    const newTextRef                        = useRef(null);
    
    // Component state.
    const [showSelector, setShowSelector]   = useState(false);
    const [taskTypesArr, setTaskTypesArr]   = useState([]);
    const [curTaskType, setCurTaskType]     = useState(null);
    const [newTaskText, setNewTaskText]     = useState('');
    const [taskListArr, setTaskListArr]     = useState([]);
    const [hasMilestone, setHasMilestone]   = useState(0);
    const [confirmBtnIdx, setConfirmBtnIdx] = useState(null);
    const [dirtyText, setDirtyText]         = useState('');
    const [isAddTask, setIsAddTask]         = useState(false);
    
    // Local variables.
    const maxNumTasks                       = 15;
    const maxTaskChars                      = 144;
    
    
    /*
     * Component methods.
     */
    
    // Reference this day's tasks from the 'studyTasksData' array.
    const filterDaysTasks = () => {
        
        // Filter then deep clone the tasks array.
        const filteredTasksArr  = studyTasksData.filter(item => item.userExamStudyDayUNID === itemData.UNID);
        const clonedArr         = JSON.parse(JSON.stringify(filteredTasksArr));
        
        // Define the task list array.
        setTaskListArr(clonedArr);
        
    };

    const setTaskHeights = () => {
        
        if ( txtRefs.current.length > 0 ) {
                
            txtRefs.current.forEach(item => {
                
                if ( item !== null ) item.style.height = item.scrollHeight + 'px';
                
            });

        }
        
    };
    
    const drawTaskItems = () => {
        
        // Reset txtRefs array.
        txtRefs.current = [];
        
        // Return drawn task items.
        return taskListArr.map((item, idx) => (
            <div key={idx}
                 className={'task-item flex-wrapper-col' + ( disabledTasks.includes(item.UNID) ? ' disabled' : '' )}>
                <button className={ 'btn-task-check' + ( item.completed ? '' : ' unchecked' ) }
                        onClick={() => onClickTaskCompleted(item.UNID)}>
                    <IconTickCircled />
                </button>
                <div className="cus-editable-textarea flex-self-center">
                    <div className={'btn-confirm' + ( confirmBtnIdx === idx ? ' show' : '' )}>
                        <div className="btn-grp">
                            <span className="txt-dim">Delete?</span>
                            <button className="txt-lnk txt-lnk-underline"
                                    onClick={() => onClickDeleteTask(item.UNID, idx)}>Yes</button>
                            <span>|</span>
                            <button className="txt-lnk txt-lnk-underline"
                                    onClick={() => onClickDeleteTask(null)}>No</button>
                        </div>
                    </div>
                    <textarea ref={element => { txtRefs.current[idx] = element }}
                                value={item.title}
                                maxLength={maxTaskChars}
                                rows="1"
                                onChange={event => onChangeTaskText(event, item.UNID, event.target.value)}
                                onFocus={event => onFocusTaskText(event, item.UNID, event.target.value)}
                                onBlur={event => onBlurTaskText(event, item.UNID, event.target.value)}
                                onKeyDown={event => onKeyDownTaskText(event, item.UNID, event.target.value)}>
                    </textarea>
                </div>
                <div className="btn-task-delete-wrapper">
                <button className="btn-task-delete"
                        onClick={() => setConfirmBtnIdx(idx)}></button>
                </div>
            </div>
        ));
 
    };
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickDone = () => {
        
        // Update any changes to the Study Type, locally & async on the server.
        if ( curTaskType.ID !== itemData.studyType ) {
            
            // Update 'studyType' locally.
            itemData.studyType = curTaskType.ID;
            
            // Update 'studyType' on the server.
            // This will be ASYNC so post & forget, but if an error does come back handle that.
            putDataNoPayload('userexam/session/day/type', { itemUNID: itemData.UNID }, { userExamSessionDay: { UNID: itemData.UNID, studyType: curTaskType.ID } });
            
        }
        
        // Update any changes to the Milestone status, locally & async on the server.
        if ( hasMilestone !== itemData.milestoneType ) {
            
            // Update 'milestoneType' locally.
            itemData.milestoneType = hasMilestone;
            
            // Update 'milestoneType' on the server.
            // This will be ASYNC so post & forget, but if an error does come back handle that.
            putDataNoPayload('userexam/session/day/milestone', { itemUNID: itemData.UNID }, { userExamSessionDay: { UNID: itemData.UNID, milestoneType: hasMilestone } });
            
        }
        
        // Close modal.
        hideModal();
        
    };
    
    const onClickSelectorOption = val => {
        
        // Update type object.
        let selType = taskTypesArr.find(item => item.ID === val.ID);
        
        setCurTaskType(selType);
        setShowSelector(false);
        
    };
    
    const onClickAddTask = () => {
        
        // Bail if max number of tasks is exceeded.
        if ( taskListArr.length >= maxNumTasks ) return;
        
        // Note that this is an add task so we can jump scroller to end.
        setIsAddTask(true);
        
        const tmpUNID = new Date().getTime();
        
        // Create a new task object.
        const newTask = {
            UNID                    : tmpUNID,
            userExamStudyDayUNID    : itemData.UNID,
            title                   : newTaskText,
            completed               : 0
        };

        // Add new task to local array.
        addStudyDayTask(newTask);
        
        // Add new task to the server.
        addServerTask({
            endPoint    : 'userexam/session/task',
            requestObj  : {
                userExamSessionTask: {
                    userExamStudyDayUNID    : itemData.UNID,
                    title                   : newTaskText
                }
            },
            taskUNID    : tmpUNID
        });

        // Clear current task in the new text textarea.
        setNewTaskText('');
        
        // Set focus on the new text textarea, if it exists.
        if ( newTextRef !== null ) newTextRef.current.focus();
        
    };
    
    const onChangeTaskText = (event, taskUNID, text) => {
        
        const task      = taskListArr.find(item => item.UNID === taskUNID);
        
        task.title      = text;
        
        // Update local task state.
        updateStudyDayTask(task);
        
        // Also update height of text area to match content.
        event.currentTarget.style.height = event.currentTarget.scrollHeight + 'px';
        
    };
    
    const onFocusTaskText = (event, taskUNID, text) => {
        
        // Store text value of currently focused task, when user finished
        // editing only post to the server if it has changed.
        setDirtyText(text);
        
    };
    
    const onBlurTaskText = (event, taskUNID, text) => {
        
        // If text was not changed bail.
        if ( dirtyText === text ) return;
        
        // Update task on the server.
        updateServerTask({
            endPoint    : 'userexam/session/task/title',
            requestObj  : {
                userExamSessionTask: {
                    UNID        : taskUNID,
                    title       : text
                }
            },
            taskUNID    : taskUNID
        });
        
    };
    
    const onKeyDownTaskText = (event, taskUNID, text) => {
        
        if ( event.key === 'Enter' ) {
            
            event.preventDefault();
            
            // If text was not changed bail.
            if ( dirtyText === text ) return;
            
            // Update task on the server.
            updateServerTask({
                endPoint    : 'userexam/session/task/title',
                requestObj  : {
                    userExamSessionTask: {
                        UNID        : taskUNID,
                        title       : text
                    }
                },
                taskUNID    : taskUNID
            });
            
            // Blur current textarea.
            event.currentTarget.blur();
            
        }
        
    };
    
    const onKeyDownTaskSubmit = (event) => {
        
        if ( event.key === 'Enter' ) {
            
            event.preventDefault();
            
            // If textarea is empty, bail.
            if ( newTaskText === '' ) return;
            
            // Update task on the server.
            onClickAddTask();
            
        }
        
    };
    
    const onClickDeleteTask = (taskUNID, idx) => {
        
        // Hide the red delete confirm buttons.
        setConfirmBtnIdx(null);
        
        // Delete the task.
        if ( taskUNID !== null ) {
            
            // Remove task from local array.
            deleteStudyDayTask(taskUNID);
            
            // Update task on the server.
            // Status 0 means make the task inactive, akin to deleting it.
            updateServerTask({
                endPoint    : 'userexam/session/task/status',
                requestObj  : {
                    userExamSessionTask: {
                        UNID        : taskUNID,
                        status      : 0
                    }
                },
                taskUNID    : taskUNID
            });
            
        }

    };
    
    const onClickTaskCompleted = taskUNID => {
        
        const matchingTask      = taskListArr.find(item => item.UNID === taskUNID);
        const clonedTask        = {...matchingTask};
        const newState          = clonedTask.completed === 0 ? 1 : 0;
        
        clonedTask.completed    = newState;
        
        // Update local task state.
        updateStudyDayTask(clonedTask);
        
        // Update task on the server.
        updateServerTask({
            endPoint    : 'userexam/session/task/completed',
            requestObj  : {
                userExamSessionTask: {
                    UNID        : taskUNID,
                    completed   : newState
                }
            },
            taskUNID    : taskUNID
        });

    };
    

    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {

        // Define the task types array.
        setTaskTypesArr(staticsData.studyActivityType);

        // Set local hasMilestone to match items milestone state.
        setHasMilestone(itemData.milestoneType);
        
        // Set height's of task textarea's to match content.
        // Manipulate DOM in a timeout, otherwise the refs aren't ready.
        setTimeout(setTaskHeights, 50);
        
        // Auto scroll the tasks window to the bottom, so the new task area is visible.
        setTimeout(() => {
            
            scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
            
        }, 100);
        
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        // Set the current task type.
        if ( taskTypesArr.length > 0 ) {
             
            const curType = taskTypesArr.find(item => item.ID === itemData.studyType);
        
            setCurTaskType(curType);
            
        }
        
    // eslint-disable-next-line 
    }, [taskTypesArr]);
    
    useEffect(() => {
        
        // Toggle document clicks to close a selector drop down 
        // when the selector has opened.
        if ( showSelector ) toggleDocClicks(true);
        
    // eslint-disable-next-line 
    }, [showSelector]);
    
    useEffect(() => {
        
        // If document was clicked & we are not on the toggle button then close this popover.
        if ( docClicked && docClickedTgt !== toggleBtnRef.current ) {
            
            setShowSelector(false);
            
        }

    // eslint-disable-next-line 
    }, [docClicked, docClickedTgt]);
    
    useEffect(() => {

        // Update the task list array for this day.
        filterDaysTasks();

    // eslint-disable-next-line 
    }, [studyTasksData]);
    
    useEffect(() => {

        //Set height's of task textarea's to match content.
        setTaskHeights();
        
        if ( isAddTask ) {
            
            // Reset isAddTask so that scroller doesn't jump when editing or ticking tasks.
            setIsAddTask(false);
            
            // Auto scroll the tasks window to the bottom, so the new task area is visible.
            scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
            
        }

    // eslint-disable-next-line 
    }, [taskListArr]);


    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="choose-task flex-wrapper-row">
            
            {/* Task type selector */}
            { curTaskType !== null &&
                <div className={ 'cus-selector' + ( showSelector ? ' show' : '' )}>
                    <div className="sel-static">
                        <button ref={toggleBtnRef}
                                style={{ backgroundColor: `rgb(${curTaskType.color})` }}
                                onClick={() => ( showSelector ? setShowSelector(false) : setShowSelector(true) )}>
                            {curTaskType.label}
                        </button>
                    </div>
                    <div className="sel-dynamic">
                        <div className="scroller-wrapper">
                            <div className="scroller">
                                <ul>
                                    {taskTypesArr.map((item, idx) => (
                                        <li key={idx}>
                                            <button onClick={() => onClickSelectorOption(item)}>{item.label}</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
            <div className="pad-btm-10 cntr-txt scroll-shadow-top">Add up to {maxNumTasks} tasks and check as completed</div>
            
            {/* Task text inputs */}
            <div ref={scrollerRef} className="tasks-control">

                {/* Current task list with check & delete buttons */}
                <div className="tasks-wrapper">
                    <div className="list">
                        
                            {/* Looping task item, can check, delete or edit task */}
                            {drawTaskItems()}

                    </div>
                </div>
                
                {/* Add task control */}
                { taskListArr.length < maxNumTasks &&
                    <div className="flex-wrapper-col mgn-btm-15">
                        <div className="cus-textarea">
                            <textarea ref={newTextRef}
                                      value={newTaskText}
                                      maxLength={maxTaskChars}
                                      onChange={event => setNewTaskText(event.target.value)}
                                      onKeyDown={event => onKeyDownTaskSubmit(event)}>
                            </textarea>
                            <div className="charCount">{newTaskText.length} of {maxTaskChars}</div>
                        </div>
                        <button className={ 'btn-task-add' + ( newTaskText === '' ? ' disabled' : '' ) }
                                onClick={onClickAddTask}><IconPlusCircled /></button>
                    </div>
                }
                
            </div>
            
            {/* Modal footer: Milestone & Done buttons */}
            <div className="flex-wrapper-col pad-top-15 scroll-shadow-btm">

                {/* Mark day's tasks as a milestone button */}
                <div className="milestone-flag flex-wrapper-col flex-align-center">
                    <button className={ 'btn-flag-check' + ( hasMilestone === 1 ? '' : ' unchecked' ) }
                            onClick={() => setHasMilestone(hasMilestone === 0 ? 1 : 0)}>
                        <IconFlagCircled />
                    </button>
                    <div className="btn-milestone-txt" 
                        onClick={() => setHasMilestone(hasMilestone === 0 ? 1 : 0)}>Mark the day's tasks as a milestone?</div>
                </div>
                
                {/* Done button, updates item data & closes modal */}
                <div className="flex-wrapper-row flex-justify-center">
                    <button className="btn-pill btn-gry btn-done-tasks shadow-lgt"
                            onClick={onClickDone}>Done</button>
                </div>
            
            </div>
            
        </div>
    );
    
    
};

export default ModalChooseTask;


/*** Scripts end... */

import React, { useState, useEffect} from 'react';

import { defineMonths,
         defineYear } from '../hooks/utilityFunctions';


/*
 * This LayerCalendarSticky component displays the calendar over the exams,
 * only required on mobile.
 */
const LayerCalendarSticky = () => {
    
    
    // Component state.
    const [monthsArr, setMonthsArr]     = useState([]);
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Define the current set of 12 months.
        setMonthsArr(defineMonths(true));
        
    // eslint-disable-next-line 
    }, []);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <div className="calendar-sticky-wrapper">
            <div className="calendar-sticky"
                data-html2canvas-ignore>
                <div className="mob-content">
                    <div className="spacer-1"></div>
                    <div className="mob-stick">
                        <div className="spacer-2"></div>
                        <div className="mob-rows">
                                
                            {monthsArr.map((month, idx) => (
                                <div key={idx} className="row"><div>{month} <span className="mgn-left-5 dim-txt">{defineYear(month)}</span></div></div>
                            ))}
                        
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    );
    
    
};

export default LayerCalendarSticky;


/*** Scripts end... */

import React, { useContext } from 'react';
import { Dialog } from 'element-react';

import ModalContext from '../../context/modal/ModalContext';


/*
 * This Modal component displays both the Instructional & System dialog modals.
 */
const Modal = () => {
    
    
    // Reference Context API's. 
    const modalContext                  = useContext(ModalContext);
    
    // Destructure Context API's. 
    const { isVisible,
            props,
            hideModal }                 = modalContext;
            
     
    
    
    /*
     * Component view.
     */
    
    return (
        <Dialog size={props ? props.size : 'small'}
                visible={isVisible}
                onCancel={hideModal}
                lockScroll={true}
                showClose={false}
                closeOnClickModal={props && props.closeOnClickModal}
                customClass={ 'modal-cus' + ( props && props.type === 0 ? ' modal-sys' : ' modal-ins' ) }
        >
            { props &&
            <>
                <Dialog.Body>
                    <div>{props.content}</div>
                </Dialog.Body>
            </>
            }
        </Dialog>
    );
    
    
};

export default Modal;

/*** Scripts end... */
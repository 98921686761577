import React, { useReducer } from 'react';

import ModalContext from './ModalContext';
import ModalReducer from './ModalReducer';
import { TOGGLE_MODAL, 
         UPDATE_MODAL } from './ModalTypes';


const ModalState = props => {
    
    
    // Define initial state.
    const initialState = {
        isVisible       : false,
        props           : null
    };
    
    // Initialize Reducer hook.
    const [state, dispatch] = useReducer(ModalReducer, initialState);
    
    
    /*
     * Component methods.
     */
    
    const showModal = props => {
        
        dispatch({
            type    : TOGGLE_MODAL,
            payload : props
        });
        
    };
    
    const hideModal = () => {
        
        dispatch({
            type    : TOGGLE_MODAL,
            payload : {
                status  : false,
                props   : null
            }
        });
        
    };
    
    const updateModal = props => {
        
        dispatch({
            type    : UPDATE_MODAL,
            payload : props
        });
        
    };
    
    
    /*
     * Return context state & methods.
     */
    
    return <ModalContext.Provider value={{
        isVisible               : state.isVisible,
        props                   : state.props,
        showModal,
        hideModal,
        updateModal
    }}>
        {props.children}
    </ModalContext.Provider>
    
};

export default ModalState;


/*** Scripts end... */

import React, { useContext, useEffect} from 'react';
import DOMPurify from 'dompurify';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import AppContext from '../../context/app/AppContext';


/*
 * This TmplSysModal component is a wrapper template for system modals (the white ones). It creates the generic
 * header & footer content & dislays the body content passed through to it via transclusion.
 */
const TmplSysModal = ({children, txtTitleA, txtTitleB, txtFooter, showLoginLnk}) => {
    
    
    // Reference Context api's.
    const authenticationContext         = useContext(AuthenticationContext);
    const appContext                    = useContext(AppContext);
    
    // Destructure Context api's.
    const { signInUser }                = authenticationContext;
    const { staticsData }               = appContext;
    
    // Component state.
    //...
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        //...
        
    // eslint-disable-next-line 
    }, [staticsData]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
                
            {/* Modal headers */}
            <h5 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(txtTitleA) }} />
            <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(txtTitleB) }} />
            
            {/* Modal body */}
            {children}
            
            {/* Modal footer - Text */}
            { txtFooter !== '' ? 
                <div className="modal-footer" 
                     dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(txtFooter) }} /> 
            : null }
            
            {/* Modal footer - Login link shown on modals when user is not logged in. */}
            { ( typeof showLoginLnk !== 'undefined' && showLoginLnk ) &&
                <div className="modal-footer pad-top-0">
                    <button className="txt-lnk txt-lnk-underline txt-bld-red" onClick={signInUser}>Log in and start planning your studies now</button>
                </div>
            }
                
        </>
    );
    
    
};

export default TmplSysModal;


/*** Scripts end... */

/*
 * A central library to server images from. 
 */

import RetinaImage from 'react-retina-image';

import ImgAccaLogoX1 from '../../assets/img/acca_logo.png';
import ImgAccaLogoX2 from '../../assets/img/acca_logo@2x.png';
import ImgAccaLogoX3 from '../../assets/img/acca_logo@3x.png';
import ImgAccaLogoTxtX1 from '../../assets/img/acca_logo_txt.png';
import ImgAccaLogoTxtX2 from '../../assets/img/acca_logo_txt@2x.png';
import ImgAccaLogoTxtX3 from '../../assets/img/acca_logo_txt@3x.png';

import ImgQuadX1 from '../../assets/img/quad.png';
import ImgQuadX2 from '../../assets/img/quad@2x.png';
import ImgQuadX3 from '../../assets/img/quad@3x.png';

import TickWht from '../../assets/img/tick-wht.png';
import TickBlk from '../../assets/img/tick-blk.png';

import Pointer from '../../assets/img/pointer.svg';
import { ReactComponent as ImgTick } from '../../assets/img/tick.svg';
import { ReactComponent as ImgReset } from '../../assets/img/reset.svg';
import { ReactComponent as ImgTickCircled } from '../../assets/img/icon-tick-circled.svg';
import { ReactComponent as ImgFlagCircled } from '../../assets/img/icon-flag-circled.svg';
import { ReactComponent as ImgPlusCircled } from '../../assets/img/icon-plus-circled.svg';
import { ReactComponent as ImgStudyPlanning } from '../../assets/img/icon-study-planning.svg';
import { ReactComponent as ImgExamPlanning } from '../../assets/img/icon-exam-planning.svg';

import { ReactComponent as ImgMood1 } from '../../assets/img/mood-1.svg';
import { ReactComponent as ImgMood2 } from '../../assets/img/mood-2.svg';
import { ReactComponent as ImgMood3 } from '../../assets/img/mood-3.svg';
import { ReactComponent as ImgMood4 } from '../../assets/img/mood-4.svg';
import { ReactComponent as ImgMood5 } from '../../assets/img/mood-5.svg';
import { ReactComponent as ImgMood6 } from '../../assets/img/mood-6.svg';
import { ReactComponent as ImgMood7 } from '../../assets/img/mood-7.svg';
import { ReactComponent as ImgMood8 } from '../../assets/img/mood-8.svg';
import { ReactComponent as ImgMood9 } from '../../assets/img/mood-9.svg';


/*
 * Export the jpg, png or svg images.
 * Some icons don't appear here as they are custom font icons.
 */
// PNG's
export const ImgLogo            = () => <RetinaImage src={[ImgAccaLogoX1, ImgAccaLogoX2, ImgAccaLogoX3]} alt="ACCA Think Ahead" />;
export const ImgLogoTxt         = () => <RetinaImage src={[ImgAccaLogoTxtX1, ImgAccaLogoTxtX2, ImgAccaLogoTxtX3]} alt="ACCA Think Ahead" />;
export const ImgQuad            = () => <RetinaImage src={[ImgQuadX1, ImgQuadX2, ImgQuadX3]} alt="Planner" />;
export const ImgQuadx3          = () => <RetinaImage src={[ImgQuadX3]} alt="Planner" />;
export const IconTickWht        = () => <span className="icon-tick"><img src={TickWht} alt="" /></span>;
export const IconTickBlk        = () => <span className="icon-tick"><img src={TickBlk} alt="" /></span>;
export const IconPointer        = () => <img src={Pointer} alt="Take a snapshot" />;

// SVG's
export const IconTick           = () => <span className="icon-tick"><ImgTick /></span>;
export const IconReset          = () => <span className="icon-reset"><ImgReset /></span>;
export const IconTickCircled    = () => <ImgTickCircled />;
export const IconFlagCircled    = () => <ImgFlagCircled />;
export const IconPlusCircled    = () => <ImgPlusCircled />;
export const IconStudyPlanning  = () => <ImgStudyPlanning />;
export const IconExamPlanning   = () => <ImgExamPlanning />;

export const IconMood1          = () => <ImgMood1 />;
export const IconMood2          = () => <ImgMood2 />;
export const IconMood3          = () => <ImgMood3 />;
export const IconMood4          = () => <ImgMood4 />;
export const IconMood5          = () => <ImgMood5 />;
export const IconMood6          = () => <ImgMood6 />;
export const IconMood7          = () => <ImgMood7 />;
export const IconMood8          = () => <ImgMood8 />;
export const IconMood9          = () => <ImgMood9 />;


/*** Scripts end... */
import React, { useContext, useState, useEffect } from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';
import ModalContext from '../../context/modal/ModalContext';
import { getDateSuffix } from '../hooks/utilityFunctions';
import ModalChooseMood from '../modals/ModalChooseMood';
import ModalChooseTask from '../modals/ModalChooseTask';
import TmplSysModal from '../modals/TmplSysModal';

import { IconTickCircled } from '../hooks/ImageAssets';
import { IconFlagCircled } from '../hooks/ImageAssets';
import { IconMood1,
         IconMood2,
         IconMood3,
         IconMood4,
         IconMood5,
         IconMood6,
         IconMood7,
         IconMood8,
         IconMood9,
         IconPlusCircled } from '../hooks/ImageAssets';


/*
 * This StudyPlanItem component displays a Study Planner list item, showing the tasks & mood for that day.
 */
const StudyPlanItem = ({ itemData, examUNID, tasks }) => {
    
    
    // Reference Context api's.
    const authenticationContext             = useContext(AuthenticationContext);
    const modalContext                      = useContext(ModalContext);
    
    // Destructure Context api's.
    const { examsData,
            postDataAsync,
            putDataAsync,
            updateTaskUNID }                = authenticationContext;
    const { showModal }                     = modalContext;
    
    // Component state.
    const [moodArr, setMoodArr]             = useState([]);
    const [examData, setExamData]           = useState(null);
    const [itemDate, setItemDate]           = useState('');
    const [unidValues, setUnidValues]       = useState(null);
    const [disabledTasks, setDisabledTasks] = useState([]);
    
    
    /*
     * Component methods.
     */
    
    const formatDate = val => {
        
        const daysArr       = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const monthsArr     = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const date          = new Date(val);
        
        return {
            dayNum      : date.getDate(),
            dayName     : daysArr[date.getDay()],
            monthName   : monthsArr[date.getMonth()],
            yearNum     : date.getFullYear()
        }
        
    };
    
    const htmlDate = val => {
        
        const tmpDate = formatDate(val);
        
        return (
            <>
                <span className="is-mobile is-inline">{tmpDate.dayName.substring(0, 3)}<br /></span>
                <span className="is-mobile is-inline">{tmpDate.dayNum} {tmpDate.monthName.substring(0, 3)}<br /></span>
                <span className="is-mobile is-inline dim-txt">{tmpDate.yearNum}</span>
                
                <span className="is-desktop is-inline">{tmpDate.dayName}<br /></span>
                <span className="is-desktop is-inline">{tmpDate.dayNum}{getDateSuffix(tmpDate.dayNum)} {tmpDate.monthName}<br /></span> 
                <span className="is-desktop is-inline dim-txt">{tmpDate.yearNum}</span>
            </>
        );
        
    };
    
    const showMood = val => {
        
        switch (val) {
            case 1:
                return <IconMood1 />;
            case 2:
                return <IconMood2 />;
            case 3:
                return <IconMood3 />;
            case 4:
                return <IconMood4 />;
            case 5:
                return <IconMood5 />;
            case 6:
                return <IconMood6 />;
            case 7:
                return <IconMood7 />;
            case 8:
                return <IconMood8 />;
            case 9:
                return <IconMood9 />;
            default:
                return <IconMood9 />;
        }
        
    };
    
    const showTasks = val => {
        
        return (
            <ul className="daily-task">
                
                {/* Milestone */}
                { val.milestoneType === 1 ?
                    <li className="flex-wrapper-col flag"><IconFlagCircled /><span>Milestone!</span></li>
                    : 
                    null
                }
                
                {/* Tasks */}
                { tasks.map((item, idx) => (
                    <li key={idx} className={'flex-wrapper-col' + ( disabledTasks.includes(item.UNID) ? ' disabled' : '' )}>
                        { item.completed === 1 ? 
                            <IconTickCircled /> 
                            : 
                            <span className="icon-unchecked"><IconTickCircled /></span> }
                        <span className="wrapword">{item.title}</span></li>
                ))}
                
            </ul>
        );
        
    };
    
    const getActivityType = val => {
        
        switch (val) {
            case 1:
                return 'none';
            case 2:
                return 'planning';
            case 3:
                return 'learning';
            case 4:
                return 'revision';
            case 5:
                return 'final';
            case 6:
                return 'exam';
            case 7:
                return 'reflection';
            default:
                return 'none';
        }
        
    };
    
    const addServerTask = props => {
        
        // Disble edited task before puting, re-enable if a success. 
        setDisabledTasks(curItems => [...curItems, props.taskUNID]);
            
        // Wrap fetch in a promise so we can get back the 'taskUNID'.
        postDataAsync(props.endPoint, props.requestObj, props.taskUNID)
            .then(response => {
                
                // Enable task if put was a success.
                setDisabledTasks(curItems => [...curItems.filter(item => item !== response.taskUNID)]);
                
                // Update tasks UNID so that we can edit it.
                setUnidValues({
                    oldUNID : response.taskUNID,
                    newUNID : response.newTaskUNID
                })
                
            })
            .catch(error => {
                
                console.log('error', error);
                
            });
 
    };
    
    const updateServerTask = props => {
        
        // Disble edited task before putting, re-enable if a success. 
        setDisabledTasks(curItems => [...curItems, props.taskUNID]);
        
        // Wrap fetch in a promise so we can get back the 'taskUNID'.
        putDataAsync(props.endPoint, props.requestObj, props.taskUNID)
            .then(response => {
                
                // Enable task if put was a success.
                setDisabledTasks(curItems => [...curItems.filter(item => item !== response.taskUNID)]);
                
            })
            .catch(error => {
                
                console.log('error', error);
                
            });
        
    };
    

    
    
    
    
    /*
     * Component event handlers.
     */
    
    const onClickAddMoodModal = () => {
        
        // System - define modal content.
        const txtTitleA     = 'Studying for';
        const txtTitleB     = `${examData.name}<br />${itemDate}`;
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalChooseMood moodArr={moodArr} 
                                     itemData={itemData} />
                </div>
            );
        };
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={''}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };
    
    const onClickAddTaskModal = () => {
        
        // System - define modal content.
        const txtTitleA     = 'Studying for';
        const txtTitleB     = `${examData.name}<br />${itemDate}`;
        const htmlBody      = () => {
            return (
                <div className="modal-body">
                    <ModalChooseTask itemData={itemData}
                                     addServerTask={addServerTask}
                                     updateServerTask={updateServerTask}
                                     disabledTasks={disabledTasks} />
                </div>
            );
        };
        
        // System - initialize modal.
        const props = {
            status  : true,
            props   : {
                type                : 0,
                size                : 'small',
                closeOnClickModal   : false,
                content             : <TmplSysModal txtTitleA={txtTitleA} 
                                                    txtTitleB={txtTitleB} 
                                                    txtFooter={''}>{htmlBody()}</TmplSysModal>
            }
        };
        
        // System - show modal.
        showModal(props);
        
    };
    
    
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        // Reference the associated exams data.
        setExamData(examsData.find(item => item.UNID === examUNID));
        
        // Store this items date.
        const tmpDate = formatDate(itemData.date);
        setItemDate(`${tmpDate.dayName} ${tmpDate.dayNum}${getDateSuffix(tmpDate.dayNum)} ${tmpDate.monthName} ${tmpDate.yearNum}`);
        
        // Populate the mood array.
        setMoodArr([{ icon: <IconMood9 />, id: 9},
                    { icon: <IconMood8 />, id: 8},
                    { icon: <IconMood7 />, id: 7},
                    { icon: <IconMood6 />, id: 6},
                    { icon: <IconMood5 />, id: 5},
                    { icon: <IconMood4 />, id: 4},
                    { icon: <IconMood3 />, id: 3},
                    { icon: <IconMood2 />, id: 2},
                    { icon: <IconMood1 />, id: 1}]);
        
    // eslint-disable-next-line 
    }, []);
    
    useEffect(() => {
        
        // Swap UNID values for a task.
        if ( unidValues !== null ) {
            
            updateTaskUNID(unidValues);
            setUnidValues(null);
            
        }

    // eslint-disable-next-line 
    }, [unidValues]);
    

     
     
    /*
     * Component view.
     */
    
    return (
        <li className="item-daily flex-wrapper-col">
            <div className="item-lft flex-wrapper-col flex-space-between is-btn"
                 onClick={onClickAddMoodModal}>
                <div className="daily-date">
                    
                    {/* Date */}
                    { itemData.date !== '' ? htmlDate(itemData.date) : null }
                    
                </div>
                <div className={ 'daily-mood flex-wrapper-row' + ( itemData.moodType === 0 ? ' pad-xtra' : '' ) }>
                    
                    {/* Mood reflection icons */}
                    { itemData.moodType !== 0 ? showMood(itemData.moodType) 
                    : 
                    (<div className="icon-planner-plus"><IconPlusCircled /></div>) }
                    
                </div>
            </div>
            <div className={ 'item-mid stage-' + getActivityType(itemData.studyType) }></div>
            <div className="item-rgt is-btn"
                 onClick={onClickAddTaskModal}>
                
                {/* Milestone flag & task items */}
                { itemData.milestoneType === 1 || tasks.length > 0 ? showTasks(itemData)
                :
                (<div className="flex-wrapper-center height-100pc"><div className="icon-planner-plus"><IconPlusCircled /></div></div>) }

            </div>
        </li>
    );
    
    
};

export default StudyPlanItem;


/*** Scripts end... */
import React, { useContext, useState, useEffect} from 'react';

import AuthenticationContext from '../../context/authentication/AuthenticationContext';


/*
 * This ModalContentEPSMEvent component displays the HTML for the EPSM select exam modal.
 * The user can set the exam as Complete for the selected month.
 */
const ModalContentEPSMEvent = ({ sessionUNID, ctaBtnTxtA, ctaBtnTxtB, ctaBtnFncA, ctaBtnFncB }) => {
    
    
    // Reference Context api's.
    const authenticationContext                 = useContext(AuthenticationContext);
    
    // Destructure Context api's.
    const { curFetching }                       = authenticationContext;
    
    // Component state.
    const [btnDisabled, setBtnDisabled]         = useState(true);
    
    
    /*
     * Component methods.
     */
    
    //...
    
     
     
    /*
     * Component hooks.
     */
    
    useEffect(() => {
        
        setBtnDisabled(curFetching === '' ? false : true);
        
    // eslint-disable-next-line 
    }, [curFetching]);

    
     
     
    /*
     * Component view.
     */
    
    return (
        <>
            <div className="modal-btns-wrapper cntr pad-top-25">
                <button className={'btn-pill border-4 btn-red shadow-lgt' + ( curFetching === 'userexam/session' ? ' fetching' : '' )}
                        onClick={() => ctaBtnFncA(sessionUNID)}
                        disabled={btnDisabled}>
                    <span className="spinner"><span></span></span>{ctaBtnTxtA}
                </button>
            </div>
            <div className="modal-btns-wrapper cntr pad-top-30">
                <button className="btn-pill border-4 btn-gry shadow-lgt" 
                        onClick={ctaBtnFncB}
                        disabled={btnDisabled}>
                    {ctaBtnTxtB}
                </button>
            </div>
        </>
    );
    
    
};

export default ModalContentEPSMEvent;


/*** Scripts end... */
